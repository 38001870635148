import { styled } from "../../../theme";
import { space } from "../../../tokens";
const StyledLayout = styled("div", {
    display:'block',
    borderRadius: "$16",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$8",
    position: "relative",
});

const StyledQuestion = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$24",
    lineHeight: "$32",
    margin:0,
    padding: 0,
});

const StyledInput = styled("input", {
    width: '100%',
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    height: "$40",
    gap:'$10',
    padding: '$8 $16',
    borderWidth: space[2],
    borderStyle: "solid",
    borderColor: '$primary',
    borderRadius: '$8',
    margin:0,
});

export const SurveySingleLine = ({
    question,
    assets,
    onChange,
    placeholder = '',
}) => {
    return (
        <StyledLayout>
           <StyledQuestion>{question}</StyledQuestion>
           {assets}
           <StyledInput onChange={onChange} placeholder={placeholder}  />
        </StyledLayout>
    );
};

SurveySingleLine.displayName = "SurveySingleLine";