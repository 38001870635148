import { Layout, Radio, RadioGroup, Typography } from "../../../components";
import { styled } from "../../../theme";
import { space } from "../../../tokens";

//Full Card Layout
const StyledTextContent = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "$16",
    gap: "$24",
    justifyContent: "start",
    width: "100%",
    backgroundColor: "$neutral",
    borderRadius: "$16",
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    variants: {
        variant: {
            enabled: {
                backgroundColor: '$tulip10',
                borderColor: '$primary',
            },
        },
    }
});

//Post Content Container
const StylesPercentageLayout = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    padding: "0 $8",
    borderRadius: "$4",
    height: "$32",
    textAlign: 'center',
    fontWeight: '$600',
    fontSize: '$16',
    lineHeight: '$32',
    variants: {
        variant: {
            enabled: {
                backgroundColor: '$primary',
                color: '$neutral'
            },
            disabled: {
                backgroundColor: '$low',
                color: 'high'
            },
        },
    }
});


export const TextPoll = ({
    inputValue,
    defaultValue,
    onInputChange,
    readOnly,
    textQuestion,
    votePercentage,
    ...props
}) => {
    return (
        <StyledTextContent {...props}>
            <Layout css={{
                width: '100%',
                alignItems: "center",
            }}>
                <RadioGroup value={defaultValue} onValueChange={onInputChange}>
                    <Radio value={inputValue}></Radio>
                </RadioGroup>
                <Layout css={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    paddingLeft: '$8',
                }}>
                    <Typography variant='body' fontWeight='bold'>
                        {textQuestion}
                    </Typography>
                    <StylesPercentageLayout {...props}>
                        {votePercentage}
                    </StylesPercentageLayout>
                </Layout>

            </Layout>
        </StyledTextContent>
    );
};

TextPoll.DisplayName = "TextPoll";