import * as SliderBase from "@radix-ui/react-slider";

import { shadows, styled } from "../../theme";
import { space } from "../../tokens";

const StyledSliderRoot = styled(SliderBase.Root, {
  position: "relative",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  touchAction: "none",
  width: "100%",
  height: "$8",
});

const StyledSliderTrack = styled(SliderBase.Track, {
  backgroundColor: "$neutral",
  position: "relative",
  flexGrow: 1,
  borderRadius: "9999px",
  height: "$8",
});

const StyledSliderRange = styled(SliderBase.Range, {
  position: "absolute",
  backgroundColor: "$primary",
  borderRadius: "$96",
  height: "100%",
});

const StyledSliderThumb = styled(SliderBase.Thumb, {
  backgroundColor: "$neutral",
  boxShadow: shadows.subtle,
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  borderRadius: "$96",
  display: "block",
  width: "$24",
  height: "$24",
  "&:focus": {
    outline: "none",
    boxShadow: shadows.focus,
  },
});

/**
 * This component accepts all the image props extended by the @radix-ui/react-slider.
 *
 * @param value - The slider value.
 * @param min - The min value that the slider can reach.
 * @param max - The max value that the slider can reach.
 * @param step - Normally the slider will change 1 by 1 as a value, but you can increase to change 5 by 5 or more.
 * @param ariaLabel - The text that represents the slider.
 * @param onChange - The onChange function receives the value when the value change.
 *
 * @example
 *
 * return (
 *   <Slider value={50} min={0} max={10} step={1} ariaLabel="Volume" onChange={...} />
 * )
 */
export const Slider = ({
  value = 50,
  onChange,
  max = 100,
  min = 0,
  step = 1,
  ariaLabel = "Slide to change the value",
}) => {
  return (
    <StyledSliderRoot
      value={[value]}
      max={max}
      min={min}
      step={step}
      onValueChange={(newValue) => {
        onChange(newValue[0]);
      }}
    >
      <StyledSliderTrack>
        <StyledSliderRange />
      </StyledSliderTrack>
      <StyledSliderThumb aria-label={ariaLabel} />
    </StyledSliderRoot>
  );
};

Slider.displayName = "Slider";
