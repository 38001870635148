import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";

const StyledPromotedCard = styled("div", {
  fill: "$neutral",
  backgroundColor: "$neutral",
  position: "relative",
  width: '100%',
  height: 210,
  "> img": {
    borderRadius: "$8",
    objectFit:'cover',
  },
});

const StyledIconRight = styled("div", {
  fill: "$neutral",
  position: "absolute",
  top: "$24",
  right: "$24",
  backgroundColor: '#000',
    borderRadius: '$8',
    padding: '$8',
});

const StyledIconCenter = styled("div", {
  fill: "$neutral",
  "> svg": {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: '#000',
    borderRadius: '$8',
    padding: '$8',
  },
});

const typeMap = {
  reel: "FilmReel",
  vlog: "FilmSlate",
  blog: "FilmScript",
};

/**
 * This component accepts only internal props
 *
 * @param type - reel | vlog | blog
 * @param src - The image source url.
 * @param alt - The image alt text.
 *
 * @example
 *
 * return (
 *   <PromotedCard
 *       src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"
 *       alt="A man..."
 *       type="reel"
 *   />
 * )
 */
export const PromotedCard = ({ type, src, alt, onItemClick }) => {
  return (
    <StyledPromotedCard onClick={onItemClick}>
      <StyledIconRight>
        <SystemIcon
          icon={typeMap[type]}
          size="large"
          weight="duotone"
          color="inherit"
        />
      </StyledIconRight>
      {type !== 'blog' ? (
        <StyledIconCenter>
          <SystemIcon
            icon="Play"
            size="extraLarge"
            weight="duotone"
            color="inherit"
          />
        </StyledIconCenter>
      ) : null}
      <img loading="eager" src={src} alt={alt} width="100%" height="100%" />
    </StyledPromotedCard>
  );
};

PromotedCard.displayName = "PromotedCard";
