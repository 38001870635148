import { styled } from "../../theme";
import { space } from "../../tokens";
import no_media from "../../../assets/images/no_media_image.png"
import { IconLabelButton, Layout, Button } from "../../components";
import { priceWithCommas } from "../../../utilities/GenericFunction";
import { Image } from "react-bootstrap";

const StyledCard = styled("div", {
    display: "flex",
    marginTop: '$8',
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    width:'100%',


    "> img": {
        borderRadius: '8px 8px 0 0'
    },
});

const StyledLayout = styled("div", {
    display: "flex",
    borderRadius: "$16",
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$4",
    padding: "$16",
    paddingTop: '$10',
    backgroundColor: '$neutral',
    width: "100%",
});

const StyledTitle = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    color: "$secondary",
    margin: 0,
    padding: 0,
});

const StyledName = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$20",
    lineHeight: "$24",
    color: "$primary",
    margin: 0,
    padding: 0,
});


const StyledShare = styled("div", {
    position: "absolute",
    top: "$24",
    right: "$24",
    cursor: "pointer",
});

const StyledShareLayout = styled("div", {
    background: "#000",
    width: "$40",
    height: "$40",
    borderRadius: "$96",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const StyledTagLayout = styled("div", {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textAlign: 'center',
    gap: '$8'
})

const StyledTagListLayout = styled("div", {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textAlign: 'center',
    gap: '$8',
    justifyContent: 'flex-start',
    alignItems: 'center'
})

const StyleTagsNameLayout = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
})

export const VoucherCard = ({
    src,
    alt = '',
    title,
    amount,
    tags,
    onShareContent,
    onClickAction,
}) => {
    return (
        <StyledCard mode={{
            "@initial": "mobile",
            "@bp3": "desktop",
        }}>
            <StyledShare>
                {onShareContent &&
                    <StyledShareLayout>
                        {onShareContent}
                    </StyledShareLayout>
                }
            </StyledShare>
            <Image loading='eager' style={{ objectFit: src ? 'contain' : 'contain'}} src={src ? src : no_media} alt={alt} width='100%' height={'100%'} />
            <StyledLayout>
                <StyledTitle>{title}</StyledTitle>
                <StyledName><b>{priceWithCommas(amount ? amount : 0)}</b> Points</StyledName>

                <StyledTagLayout>
                    <StyledTagListLayout>
                        {
                            tags.length > 0 &&
                            tags.map((item, index) => {
                                return (
                                    <StyleTagsNameLayout>
                                        <Button variant={'neutral'} size={'extraSmall'}>{item.tag}</Button>
                                    </StyleTagsNameLayout>
                                )
                            })
                        }
                    </StyledTagListLayout>
                </StyledTagLayout>

                <Layout css={{ width: '100%', display: 'block', textAlign: 'center', gap: '$8' }}>
                    <IconLabelButton
                        style={{ width: '100%', marginTop: '10px' }}
                        iconColor={'#000'}
                        variant="primary"
                        label={`Redeem`}
                        onClick={onClickAction}></IconLabelButton>
                </Layout>

            </StyledLayout>
        </StyledCard>
    );
};

VoucherCard.displayName = "VoucherCard";
