import { styled } from "../../theme";
import { space } from "../../tokens";

const StyledMobileHeader = styled("header", {
  borderBottomWidth: space["1"],
  borderBottomStyle: "solid",
  borderBottomColor: "$low",
  padding: "$32",
  display: "flex",
  flexDirection: "column",
  gap: "$24",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$150",
  margin: 0,
  padding: 0,
  "@bp1": {
    fontSize: "$32",
  },
});

const StyledDefaultAdornment = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$32",
});

const StyledLeftAdornment = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "$16",
});

const StyledRightAdornment = styled("div", {
  display: "none",
  "@bp1": {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "$8",
  },
});

/**
 * This component accepts all the header props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param title - The section title.
 * @param titleAdornment - A React element positioned in the top of the header with the title, prefer use Button component.
 * @param leftAdornment - A React element positioned in the left of the header, prefer use TabItem component.
 * @param rightAdornment - A React element positioned in the right of the header, prefer use Chip component.
 *
 * @example
 *
 * return (
 *   <SectionHeader
 *      title="Title"
 *      titleAdornment={
 *        <Button variant="primary" size="default">
 *           Label
 *        </Button>
 *      }
 *      leftAdornment={
 *        <>
 *          <TabItem active variant="horizontal">Label</TabItem>
 *          <TabItem variant="horizontal">Label</TabItem>
 *          <TabItem variant="horizontal">Label</TabItem>
 *          <TabItem variant="horizontal">Label</TabItem>
 *        </>
 *      }
 *      rightAdornment={
 *        <>
 *          <Chip variant="primary" size="default">Label</Chip>
 *          <Chip variant="primary" size="default">Label</Chip>
 *        </>
 *      }
 *   />
 * )
 */
export const SectionHeader = ({
  title,
  titleAdornment,
  leftAdornment,
  rightAdornment,
}) => {
  return (
    <StyledMobileHeader>
      <StyledDefaultAdornment>
        <StyledTitle>{title}</StyledTitle>
        {titleAdornment}
      </StyledDefaultAdornment>
      <StyledDefaultAdornment>
        <StyledLeftAdornment>{leftAdornment}</StyledLeftAdornment>
        <StyledRightAdornment>{rightAdornment}</StyledRightAdornment>
      </StyledDefaultAdornment>
    </StyledMobileHeader>
  );
};

SectionHeader.displayName = "SectionHeader";
