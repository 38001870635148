import { FormControl, FormGroup, FormLabel, Image } from "react-bootstrap";
import { Checkbox } from "../../../components";
import { styled } from "../../../theme";
import { space } from "../../../tokens";
import NoMedia from "src/assets/images/no_media_image.png";
import { store } from "../../../../store";

const StyledChoiceLayout = styled("div", {
  borderRadius: "$8",
  borderStyle: "solid",
  borderWidth: space[2],
  borderColor: "$low",
  padding: "$10",
  marginTop: "$8",
});

export const Option = ({
  option,
  value = [],
  onChange = () => {},
  onChangeFreeText = () => {},
  withAsset = false,
}) => {
  const isCurrentAnswer = value.includes(option.id);

  const isOtherOption = option.optionType === "OTHER";

  const renderTextField = isCurrentAnswer && isOtherOption;

  return withAsset ? (
    <OptionImage
      isOtherOption={isOtherOption}
      onChange={onChange}
      onChangeFreeText={onChangeFreeText}
      option={option}
      renderTextField={renderTextField}
      isCurrentAnswer={isCurrentAnswer}
    />
  ) : (
    <OptionText
      isOtherOption={isOtherOption}
      onChange={onChange}
      onChangeFreeText={onChangeFreeText}
      option={option}
      renderTextField={renderTextField}
      isCurrentAnswer={isCurrentAnswer}
    />
  );
};

const OptionText = ({
  option,
  onChange,
  isOtherOption,
  onChangeFreeText,
  renderTextField,
  isCurrentAnswer,
}) => {
  const { theme } = store.getState();

  return (
    <StyledChoiceLayout
      css={{
        borderColor: isCurrentAnswer ? theme.primary : theme.low,
        backgroundColor: theme.interactive,
      }}
    >
      <div>
        <Checkbox
          value={option.id}
          name={option.text}
          onCheckedChange={() => {
            onChange(option.id, isOtherOption);
          }}
        />

        {renderTextField && (
          <FormGroup style={{ marginTop: 12 }}>
            <FormLabel title="">Enter your answer here:</FormLabel>
            <FormControl onChange={(e) => onChangeFreeText(e)} placeholder="" />
          </FormGroup>
        )}
      </div>
    </StyledChoiceLayout>
  );
};

const OptionImage = ({
  option,
  onChange,
  isOtherOption,
  onChangeFreeText,
  renderTextField,
  isCurrentAnswer,
}) => {
  const { theme } = store.getState();

  return (
    <StyledChoiceLayout
      css={{
        borderColor: isCurrentAnswer ? theme.primary : theme.low,
        backgroundColor: theme.interactive,
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 12 }}
      >
        <Image
          src={option.imageAsset?.url ?? NoMedia}
          height={130}
          width="100%"
          style={{ objectFit: "cover", borderRadius: 8 }}
        />
      </div>
      <div>
        <Checkbox
          value={option.id}
          name={option.text}
          onCheckedChange={() => {
            onChange(option.id, isOtherOption);
          }}
        />

        {renderTextField && (
          <FormGroup style={{ marginTop: 12 }}>
            <FormLabel title="">Enter your answer here:</FormLabel>
            <FormControl onChange={(e) => onChangeFreeText(e)} placeholder="" />
          </FormGroup>
        )}
      </div>
    </StyledChoiceLayout>
  );
};
