import moment from "moment";
import { SystemIcon } from "../../foundations";
import { shadows, styled } from "../../theme";
import { Avatar, EmptyState, Layout, Typography } from "../../components";

//Card Header Styles
const StyledPostHeader = styled("div", {
    display: "flex",
    alignItems: 'center',
    height: 'min-content',
    gap: "$16",
    width: "100%",
    justifyContent: "space-between",
});

const HeaderColumn = styled("div", {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "$16",
});

const AvatarAction = styled("div", {
    display: "flex",
    cursor: 'pointer'
});

const StyledUserButton = styled("button", {
    all: "unset",
    fill: "$primary",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "$4",
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$12",
    lineHeight: "$120",
    "&:focus": {
        boxShadow: shadows.focus,
    },
});

const StyledInfoHeader = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    Width: "100%",
    justifyContent: "flex-end",
});

const StyleCommentHeader = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$16",
    lineHeight: "$24",
    color: "$secondary",
    margin: 0,
    padding: 0,
});
const StyledComments = styled("div", {
    display: "flex",
    flexDirection: "column",
    Width: "100%",
});

const StyledCommentsHeader = styled("div", {
    display: "grid",
    alignItems: 'flex-start',
    Width: "100%",
    padding: '$8',
    paddingLeft: 0,
    borderRadius: '$8',
    gap: '$8',
});

const HeaderCommentColumn = styled("div", {
    display: "grid",
    Width: "100%",
    backgroundColor: '$low',
    padding: '$8',
    borderRadius: '$8',
    width: 'auto',
});

const StyledName = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$14",
    lineHeight: "$16",
    color: "$secondary",
    margin: 0,
    padding: 0,
});

const StyledCommentLabel = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$14",
    lineHeight: "$16",
    color: "$secondary",
    margin: 0,
    padding: 0,
});

const ColumnListHeader = styled("div", {
    display: "flex",
    alignSelf: "stretch",
    gap: "$16",
});

const CommentsFooter = styled("div", {
    display: "flex",
    padding: '0 0 0 $48',
    gap: "$16",
});

const StyledFooterLabel = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$14",
    lineHeight: "$16",
    cursor: "pointer",
    color: "$secondary",
    margin: 0,
    padding: 0,
});

const StyledFooterDate = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$14",
    lineHeight: "$16",
    color: "$secondary",
    margin: 0,
    padding: 0,
});

export const CommentCard = ({
    userAvatarSrc,
    userAvatarName,
    postDate,
    userOnFollowClick,
    followLabel,
    isNotLoggedInUserPost,
    avatarClickAction = null,
    isFollowed
}) => {
    return (
        <StyledPostHeader>
            <HeaderColumn>
                <AvatarAction onClick={avatarClickAction}>
                    <Avatar src={userAvatarSrc} />
                </AvatarAction>
                <StyledInfoHeader>
                    <Typography fontWeight='bold'>{userAvatarName}</Typography>
                    <Typography color='medium'>{postDate}</Typography>
                </StyledInfoHeader>
            </HeaderColumn>
            <HeaderColumn>
                {isNotLoggedInUserPost ? (
                    <>
                        <StyledUserButton onClick={userOnFollowClick}>
                            <SystemIcon icon={isFollowed ? "Minus" : "Plus"} size="small" color="inherit" />
                            {followLabel}
                        </StyledUserButton>
                    </>
                ) : null}
            </HeaderColumn>
        </StyledPostHeader>
    );
};

export const Comments = ({
    postComments = [],
    comments,
}) => {
    return (
        <Layout css={{
            display: 'grid',
            width: '100%',
            paddingTop: '$16',
        }}>

            <StyleCommentHeader>Comments {postComments.length}</StyleCommentHeader>

            {postComments.length > 0 ?
                <StyledComments>
                    {comments}
                </StyledComments>
                :

                <EmptyState
                    icon={'ChatText'}
                    title={'No comments added yet'}
                />

            }


        </Layout>
    );
};

export const CommentItemList = ({
    comment,
    onClickLikeAction = null,
    onClickReplyAction = null,
    likeIcon,
    likeTemplate,
    mediacommentView,
    mediacommentReplyView,
    avatarClickAction = null
}) => {
    return (
        <StyledCommentsHeader>
            <Layout>
                <ColumnListHeader>
                    <AvatarAction onClick={avatarClickAction}>
                        <Avatar src={comment.userInfo.photoUrl} />
                    </AvatarAction>
                </ColumnListHeader>
                <HeaderCommentColumn>
                    <StyledName>{comment.userInfo.displayName}</StyledName>
                    {comment.text && <StyledCommentLabel>{comment.asset ? '' : comment.text}</StyledCommentLabel>}
                    {comment.asset && <>{mediacommentView}</>}
                </HeaderCommentColumn>
            </Layout>
            <CommentsFooter>
                <StyledFooterLabel onClick={onClickLikeAction}>{likeIcon}</StyledFooterLabel>
                {likeTemplate}
                <StyledFooterLabel onClick={onClickReplyAction}>{'Reply'}</StyledFooterLabel>
                <StyledFooterDate>{moment(new Date(comment.createdAt)).fromNow()}</StyledFooterDate>
            </CommentsFooter>

            {
                comment.replyComment &&
                <>
                    <Layout css={{ paddingLeft: '5%' }}>
                        <ColumnListHeader>
                            <AvatarAction onClick={avatarClickAction}>
                                <Avatar src={comment?.replyComment?.userInfo?.photoUrl} />
                            </AvatarAction>
                        </ColumnListHeader>
                        <HeaderCommentColumn>
                            <StyledName>{comment?.replyComment?.userInfo?.displayName}</StyledName>
                            {comment?.replyComment?.text && <StyledCommentLabel>{comment?.replyComment?.asset ? '' : comment?.replyComment?.text}</StyledCommentLabel>}
                            {comment?.replyComment?.asset && <>{mediacommentReplyView}</>}
                        </HeaderCommentColumn>
                    </Layout>
                    <CommentsFooter>
                        <StyledFooterDate>{moment(new Date(comment?.replyComment?.createdAt)).fromNow()}</StyledFooterDate>
                    </CommentsFooter>
                </>
            }
        </StyledCommentsHeader>
    );
};

CommentCard.CommentItemList = CommentItemList;
CommentCard.Comments = Comments;
CommentCard.displayName = "CommentCard";