// import { BsFillCircleFill } from "react-icons/bs";
import { Typography } from "../../components";
import { styled } from "../../theme";
import { space } from "../../tokens";
const no_media = require("../../../assets/images/no_media.png");


const StyledProductCard = styled("div", {
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: "$16",
  position: "relative",
  height: "fit-content",
  "> img": {
    borderRadius: "$8",
    objectFit: 'cover',
    width: "100%",
    height: 150,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  "> button": {
    width: "100%",
  },
  variants: {
    mode: {
      mobile: {
        width: "100%",
        marginLeft: 0,
      },
      desktop: {
        width: '23%',
        marginLeft: '$8',
      }
    },
  },
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$18",
  color: "$secondary",
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '16vw',
  whiteSpace: 'nowrap',
  variants: {
    mode: {
      mobile: {
        lineHeight: "$24",
      },
      desktop: {
        // lineHeight: '$',
      }
    },
  },
});

const StyledBottom = styled("p", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$8",
  padding: "$16",
  paddingTop: 0,
  width: "100%",
});

const StyledCookLayout = styled("div", {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%'
});

const StyledServingLayout = styled("div", {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

// const StyledVegorNonvegLayout = styled("div", {
//   display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: "$8",
//   borderWidth: space["1"],
//   borderColor: "$low",
//   borderStyle: "solid",
//   paddingInline: 8,
//   paddingBlock: 4
// });



/**
 * This component accepts only internal props
 *
 * @param children - A React element that can be string or anything.
 * @param checked - true | false
 * @param src - The image source url.
 * @param alt - The image alt text.
 * @param title - The empty state title.
 * @param description - The empty state title.
 *
 * @example
 *
 * return (
 *   <ProductCard
 *       src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"
 *       alt="A man..."
 *       title="Title"
 *       description="Description"
 *       checked={false}
 *     >
 *       <RatingGroup totalText="(26)">
 *         <RatingStar filled="full" />
 *         <RatingStar filled="full" />
 *         <RatingStar filled="full" />
 *         <RatingStar filled="full" />
 *         <RatingStar filled="half" />
 *       </RatingGroup>
 *       <Button>Buy now</Button>
 *   </ProductCard>
 * )
 */
export const CookingCard = ({
  children,
  src,
  title,
  servingSize,
  cookImage,
  cookName,
  isVeg,
  dishRating,
  isBottomSection,
  isCuisineCard,
  id
}) => {
  return (
    <StyledProductCard id={`shopbuzz_${id}`} mode={{
      "@initial": "mobile",
      "@bp3": "desktop",
    }} css={{ marginLeft: isCuisineCard && '$2'}}>
      <img loading='eager' src={src ? src : no_media} alt={''} style={{ objectFit: 'cover', minHeight: '150px' }} />
      <StyledBottom>
        <StyledTitle mode={{
          "@initial": "mobile",
          "@bp3": "desktop",
        }}>{title}</StyledTitle>
        {
          isBottomSection &&
          <>
            { dishRating && <>{dishRating}</> }
            <StyledServingLayout>
              {
                <Typography variant={'bodySmall'} style={{ marginRight: '2.5%' }}>{`Serving size -`} <b>{`${servingSize ? servingSize : '- - -'}`}</b></Typography>
              }
              {/* {
                isVeg === "vegetarian" && <StyledVegorNonvegLayout>
                  <BsFillCircleFill size={12} color={isVeg ? 'green' : '#bd2626'} className="me-2" />
                  <Typography variant={'bodyExtraSmall'} style={{ lineHeight: '1.25rem' }}>{isVeg ? "Veg" : 'Non-veg'}</Typography>
                </StyledVegorNonvegLayout>
              } */}
            </StyledServingLayout>
            <StyledCookLayout>
              <img alt="" src={cookImage} style={{ height: '35px', width: '35px', borderRadius: '6px', resize: 'contain' }} />
              <Typography variant={'bodySmall'} style={{ marginLeft: '2.5%' }}>{`by `} <b>{`${cookName}`}</b> </Typography>
            </StyledCookLayout>
            {/* <StyledCookLayout>
              <SystemIcon icon='MapPin' color='#F2A73B' />
              <Typography variant={'bodySmall'} style={{ marginLeft: '2.5%' }}>{`${location}`}</Typography>
            </StyledCookLayout> */}
          </>
        }
        {children}
      </StyledBottom>
    </StyledProductCard>
  );
};

CookingCard.displayName = "CookingCard";
