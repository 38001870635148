import { styled } from "../../theme";

const StyledRatingGroup = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  //gap: "$8",
});

const StyledText = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$120",
  color: "$secondary",
  textAlign: "center",
  margin: 0,
  padding: 0,
});

/**
 * This component accepts all the div props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param children - A React element that can be string or anything.
 * @param totalText - The total text to show.
 *
 * @example
 *
 * return (
 *   <RatingGroup totalText="(26)">
 *    <RatingStar filled="full" />
 *    <RatingStar filled="full" />
 *    <RatingStar filled="full" />
 *    <RatingStar filled="full" />
 *    <RatingStar filled="half" />
 *   </RatingGroup>
 * )
 */
export const RatingGroup = ({ children, totalText, style }) => {
  return (
    <StyledRatingGroup style={style}>
      {children}
      <StyledText>{totalText}</StyledText>
    </StyledRatingGroup>
  );
};

RatingGroup.displayName = "RatingGroup";
