import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";

const StyledTagFeatured = styled("div", {
  fill: "$primary",
  backgroundColor: "transparent",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$150",
  color: "$secondary",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
  gap: "$8",
});

/**
 * This component accepts only internal props
 *
 * @param children - A React element that can be string or anything.
 * @param icon - All the possible icons available on https://phosphoricons.com
 *
 * @example
 *
 * return (
 *   <TagFeatured icon="Users">2 participants</TagFeatured>
 * )
 */
export const TagFeatured = ({ children, icon }) => {
  return (
    <StyledTagFeatured>
      <SystemIcon icon={icon} size="small" color="inherit" />
      {children}
    </StyledTagFeatured>
  );
};

TagFeatured.displayName = "TagFeatured";
