import { Typography } from "../../components";
import { styled } from "../../theme";

const HeaderLayout = styled("div", {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    background: '$tulip10',
    padding: '$24',
    borderRadius: '$16',
    gap: '$16',
    textAlign: 'center',
    variants: {
        mode: {
            mobile: {
                "> img ": {
                    width: '100%',
                },
            },
            desktop: {
                "> img ": {
                    width: 400,
                },
            }
        },
    },
})

export const ShopbuzzMyVoucher = ({
    label,
    voucher,
    children,
}) => {
    return (
        <HeaderLayout mode={{
            "@initial": "mobile",
            "@bp3": "desktop",
        }}>
            <Typography>{label}</Typography>
            <img src={voucher?.image?.url} alt={''} />
            {children}
        </HeaderLayout>
    );
};

ShopbuzzMyVoucher.displayName = "ShopbuzzMyVoucher";