/** React library */
import React from "react";
/** React bootstrap */
import { Form, Modal } from "react-bootstrap";
/** Services */
import { group, fileupload } from "../../utilities/apiconfig";

/** Style */
// import './reelcreatemodal.scss';
import { Divider, FormInput, GroupToggle, IconLabelButton, Layout, ModalHeader, SystemIcon, Typography } from "../../design-system";
import { isMobile } from "react-device-detect";
import CustomToast from "../../components/toast/toast";
import axios from "axios";
import constant, { IMAGE_TYPES } from "../../utilities/constant";
import { update } from "../../api";
import { checkNull } from "../../utilities/generic";

export default class GroupCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.quizId = props?.quizzId
        this.toasterRef = React.createRef();
        this.videoRef = React.createRef();
        this.state = {
            pageLoading: true,
            openModal: false,
            description: '',
            groupnameText: '',
            groupdescriptionText: '',
            groupChatProfileFile: '',
            groupChatAssetId: '',
            isGroupPrivate: false,
            groupnameerror: '',
        }
    }

    openModal = async () => {
        this.access_token = localStorage.getItem(constant.access_token);
        try {
            this.setState({
                openModal: true,
                files: [],
                description: '',
            });
        } catch (error) {
            this.setState({ pageLoading: false });
        }
    }

    closeModal() {
        this.setState({ openModal: false });
    }

    onGroupInputChange = (event) => {
        this.setState({
            groupnameText: event.target.value,
            groupnameerror: ''
        })
    }

    onGroupDescriptionInputChange = (event) => {
        this.setState({
            groupdescriptionText: event.target.value
        })
    }

    changeGroupChatImage = (event) => {
        event.preventDefault();
        let files = event.target.files[0];
        let imageData = {};
        imageData['src'] = URL.createObjectURL(files);
        imageData['file'] = files;
        this.setState({
            groupChatProfileFile: imageData
        }, () => {
            this.uploadcharGroupImage()
        })
        event.target.value = '';

    };

    async uploadcharGroupImage() {
        let { groupChatProfileFile } = this.state
        let access_token = localStorage.getItem(constant.access_token)
        const bodyFormData = new FormData();
        bodyFormData.append('file', groupChatProfileFile.file);
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${access_token}`,
            }
        };
        axios.post(fileupload, bodyFormData, config)
            .then(async (res) => {
                if (res.status === 200) {
                    this.setState({
                        groupChatAssetId: res.data.id
                    });
                }
            }).catch(error => {

            });
    }

    async createGroup() {
        let { groupnameText, groupChatAssetId, groupdescriptionText, isGroupPrivate } = this.state

        let isValid = true
        if(checkNull(groupnameText) === ''){
            this.setState({
                groupnameerror: 'Group name not provided'
            })
        }

        if(checkNull(groupnameText) === ''){
            this.setState({
                groupname: 'Group name not provided'
            })
        }

        if(!isValid){
            return;
        }

        let data = {
            name: groupnameText,
            iconAssetId: groupChatAssetId,
            group: true,
            description: groupdescriptionText,
            publicRoom: !isGroupPrivate,
            pinned: false,
        }
        let chatroom_data = await update(group, data);
        if (chatroom_data.status) {
            this.closeModal();
        }
    }

    render() {
        return (
            <Modal
                show={this.state.openModal}
                onHide={() => {
                    this.closeModal();
                }}
                centered
                size='md'
                ref={this.props.innerRef}
                backdrop={"static"}
                keyboard={false}>

                <ModalHeader
                    backIcon={''}
                    label={'Create Group'}
                    onCloseModal={() => {
                        this.closeModal();
                    }}
                />

                <Divider />

                <Layout css={{
                    padding: isMobile ? '$16' : '$24',
                    width: '100%',
                }}>

                    <Layout css={{
                        display: 'grid',
                        width: '100%',
                        gap: '$16',
                    }}>

                        <Layout css={{ width: '90px', height: '85px', backgroundColor: '#F7F7F7', border: '1px solid lightgray', borderRadius: 4, justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                            this.upload.click()
                        }}>
                            <Form.Control id="image" type="file" ref={(ref) => this.upload = ref} onChange={(event) => { this.changeGroupChatImage(event) }} hidden accept={IMAGE_TYPES} />
                            {
                                this.state?.groupChatProfileFile?.src ? <img src={this.state?.groupChatProfileFile?.src} style={{ width: '90px', height: '85px' }} alt="" /> : <SystemIcon icon='ImageSquare' size='large' />
                            }
                        </Layout>

                        <FormInput
                            label={'Group name'}
                            placeholder="Add your group name"
                            name='Group name'
                            value={this.state.groupnameText}
                            required={true}
                            error={this.state.groupnameerror}
                            onChange={this.onGroupInputChange}
                        />

                        <FormInput
                            label={'Description'}
                            placeholder="Add your group description"
                            name={'Description'}
                            value={this.state.groupdescriptionText}
                            rows={4}
                            as='textarea'
                            maxLength={300}
                            onChange={this.onGroupDescriptionInputChange}
                        />
                        <Layout css={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant={'body'} fontWeight={'bold'}> {'Make it private'} </Typography>
                            <GroupToggle checked={this.state.isGroupPrivate} onClickAction={() => {
                                this.setState({
                                    isGroupPrivate: !this.state.isGroupPrivate
                                })
                            }}>
                            </GroupToggle>
                        </Layout>

                        <Typography variant={'bodySmall'}> {'When a group set to private, only invited group members can view and post to this group'} </Typography>

                        <Layout css={{
                            width: '100%',
                        }}>
                            <IconLabelButton
                                label='Create new group'
                                variant='primary'
                                width={'100%'}
                                size='large'
                                onClick={() => this.createGroup()}
                            />
                        </Layout>
                    </Layout>
                </Layout>
                <CustomToast ref={this.toasterRef} />
            </Modal>
        );
    }
}