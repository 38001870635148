import { Layout, Radio, Typography } from "../../../components";
import { styled } from "../../../theme";
import { space } from "../../../tokens";

//Full Card Layout
const StyledVideoContent = styled("div", {
  display: "grid",
  flexDirection: "column",
  alignItems: "flex-start",
  //padding: "$16",
  paddingTop: 0,
  gap: "$24",
  justifyContent: "start",
  width: "100%",
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  "> video": {
    borderRadius: "$16",
    width: 330,
    height: 160,
  },
  variants: {
    variant: {
      enabled: {
        backgroundColor: "$tulip10",
        borderColor: "$primary",
      },
    },
  },
});

//Post Content Container
const StylesPercentageLayout = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "10px",
  padding: "0 $8",
  borderRadius: "$4",
  height: "$32",
  textAlign: "center",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$32",
  variants: {
    variant: {
      enabled: {
        backgroundColor: "$primary",
        color: "$neutral",
      },
      disabled: {
        backgroundColor: "$low",
        color: "high",
      },
    },
  },
});

export const VideoPoll = ({
  src,
  inputValue,
  defaultChecked,
  disabled,
  textQuestion,
  votePercentage,
}) => {
  return (
    <StyledVideoContent>
      <video controls src={src} controlsList="nodownload" />
      <Layout
        css={{
          width: "100%",
          alignItems: "center",
          padding: "$10",
          justifyContent: "space-between",
        }}
      >
        <Layout
          css={{
            alignItems: "center",
          }}
        >
          <Radio
            defaultChecked={defaultChecked}
            value={inputValue}
            disabled={disabled}
          ></Radio>
          <Typography variant="body" fontWeight="bold">
            {textQuestion}
          </Typography>
        </Layout>
        <StylesPercentageLayout>{votePercentage}</StylesPercentageLayout>
      </Layout>
    </StyledVideoContent>
  );
};

VideoPoll.DisplayName = "VideoPoll";
