import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import "../../reset.scss";
import TakeSurveyModal from "../../features/opinions/surveys/takesurveymodal";
import { posturl, tenant } from "../../utilities/apiconfig";
import { get } from "../../api";
import PlayQuizModal from "../../features/opinions/quizzes/playquizmodal";
import { OpinionsWelcomeScreen } from "./welcome";
import { STORE_THEME_COLORS } from "../../store/actiontypes";
import { store } from "../../store";
import constant from '../../utilities/constant'


export const OpinionsModule = () => {
  const location = useLocation();
  const id = location.pathname.replaceAll("/", "");

  const [tokens, setTokens] = useState({
    accessToken: null,
    refreshToken: null,
  });

  const [post, setPost] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const render = useMemo(() => {
    if (isLoading)
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#1E1E1E"
          }}
        >
          <Spinner />
        </div>
      );

    if (post) {
      if (!tokens.accessToken)
        return <OpinionsWelcomeScreen post={post} setTokens={setTokens} />;

      if (id) {
        switch (post.postType) {
          case "SURVEY":
            return (
              <TakeSurveyModal
                surveyId={id}
                accessToken={tokens.accessToken}
                isModule                
              />
            );
          case "QUIZ":
            return (
              <PlayQuizModal
                quizId={id}
                accessToken={tokens.accessToken}
                isModule
              />
            );
          default:
            return "Only Surveys or Quizzes supported at this time";
        }
      }
    }

    return <div style={{
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#1E1E1E"
    }}>
      No data received</div>;
  }, [id, isLoading, post, tokens.accessToken]);

  useEffect(() => {
    
    window.addEventListener("message", (event) => { 
   // console.log("Received message from parent page:", event.data);
  
    if (event.data.accessToken) {
      setTokens({
        accessToken: event.data.accessToken,
        refreshToken: event.data.refreshToken,
      });
    }

  }, []) 
});

  useLayoutEffect(() => {
    if (id) {
      setIsLoading(true);

      get(`${posturl}/${id}`)
        .then((data) => setPost(data.data))
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  useLayoutEffect(() => {
    async function fetchTenantData() {
      const tenant_data = await get(tenant);
      const data = tenant_data?.data;

      // for  text
      data.secondaryColor = "#FBFBFB";
      // for primary color on action buttons
      data.primaryColor = "#FF0000";
      // hover color
      data.interactiveColor = "#1E1E1E";
      // for backgrounds
      data.neutral = "#1E1E1E";

      if (data) {
        localStorage.setItem(constant.primaryColor, data?.primaryColor)
        localStorage.setItem(constant.interactiveColor, data?.interactiveColor)
        localStorage.setItem(constant.secondaryColor, data?.secondaryColor)
        localStorage.setItem(constant.neutralColor, data?.neutral)
        store.dispatch({ type: STORE_THEME_COLORS, payload: data });
      }
    }

    fetchTenantData();

  }, []);

  if (isLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  return render;
};
