import styled from "styled-components";

const StyledToggle = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const StyledSwitch = styled.div`
  position: relative;
  width: 60px;
  height: 30px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 25px;
    height: 20px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${StyledSwitch} {
    background: #F2A73B;

    &:before {
      transform: translate(25px, -50%);
      right: 4px;
    }
  }
`;

export const GroupToggle = ({
    checked,
    onClickAction,
}) => {
    return (
        <StyledToggle>
            <StyledInput checked={checked} type="checkbox" onChange={onClickAction} />
            <StyledSwitch />
        </StyledToggle>
    );
};

GroupToggle.displayName = "GroupToggle";
