import { Layout } from "../../components";
import { SystemIcon } from "../../foundations";
import { shadows, styled } from "../../theme";

const StyledAnchor = styled("a", {
  fill: "$primary",
  fontFamily: "$roboto",
  fontSize: "$16",
  lineHeight: "$150",
  fontWeight: "$600",
  borderRadius: "$8",
  textAlign: "center",
  boxSizing: "border-box",
  textDecoration: "none",
  display: "inline-flex",
  gap: "$8",
  "&:hover": {
    color: "inherit",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
});

const StyledLayout = styled("div", {
  paddingLeft: "$6",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$32",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
  paddingTop: '$8',
  color: '$neutral300'
});

const StyledCoinTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$16",
  margin: 0,
  padding: 0,
});
const StyledCoinDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$16",
  color: '$secondary',
  margin: 0,
  padding: 0,
});

export const NetworkBadge = ({ icon, ...props }) => {
  return (
    <Layout>
      <StyledAnchor {...props}>
        {icon ? <SystemIcon size={'large'} icon={icon} color="inherit" /> : null}
      </StyledAnchor>
    </Layout>
  );
};

export const TextInfo = ({ title, description }) => {
  return (
    <StyledLayout>
      <StyledTitle>
        {title}
      </StyledTitle>
      <StyledDescription>
        {description}
      </StyledDescription>
    </StyledLayout>
  );
};
export const CoinInfo = ({ title, description, icon }) => {
  return (
    <Layout css={{ alignItems:"center"}}>
      <StyledAnchor>
        {icon ? <SystemIcon size={'large'} icon={icon} color="inherit" /> : null}
      </StyledAnchor>
      <Layout>
        <StyledCoinTitle>
          {title}
        </StyledCoinTitle>
        <StyledCoinDescription>
          {description}
        </StyledCoinDescription>
      </Layout>
    </Layout>
  );
};
NetworkBadge.CoinInfo = CoinInfo;
NetworkBadge.TextInfo = TextInfo;
NetworkBadge.DisplayName = "NetworkBadge";
