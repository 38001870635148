import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";
import { Avatar } from "../../components/avatar/avatar";
import { Button } from "../../components/button/button";
import { Layout } from "../../components/layout/layout";
import { Spinner } from "../../components/spinner/spinner";
import { Typography } from "../../components/typography/typography";
import { colors } from "../../tokens";

const StyledLayout = styled("div", {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "$16",
    padding: "$8 0 $8 $4",
});

const StyledTitle = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    color: "$secondary",
    padding: 0,
    paddingLeft: '$8',
    margin: 0,
});

const HeaderColumn = styled("div", {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "$16",
});

const HeaderPreviewColumn = styled("div", {
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    alignSelf: "stretch",
    padding: "$16",
    position: 'absolute',
    top: 0,
    background: '$secondary',
    backgroundColor: "#00000020"
});

const HeaderPreviewFooter = styled("div", {
    width: '100%',
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    padding: "$16",
    position: 'absolute',
    bottom: 0,
    backgroundColor: "#00000020"

});

const StyledInfoHeader = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    Width: "100%",
    justifyContent: "flex-end",
});


const StyledEmptyState = styled("div", {
    fill: "$secondary",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "$8",
    padding: "$32",
});

const StyledEmptyTitle = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$24",
    lineHeight: "$24",
    color: "$secondary",
    textAlign: "center",
    margin: 0,
    padding: 0,
});

const StyledDescription = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$18",
    lineHeight: "$24",
    color: "$medium",
    textAlign: "center",
    margin: 0,
    padding: 0,
});

const StyledFileLoader = styled("div", {
    display: "flex",
    flexDirection: "column",
    Width: "100%",
    fill: "$secondary",
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
    gap: "$8",
    padding: "$32",
});

const StyledLoaderLabel = styled("p", {
    display: "flex",
    alignItems: "center",
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$24",
    lineHeight: "$24",
    color: "$secondary",
    textAlign: "center",
    margin: 0,
    padding: 0,
});

const StyledLabel = styled("p", {
    display: "flex",
    alignItems: "center",
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$24",
    lineHeight: "$24",
    color: "$secondary",
    margin: 0,
    padding: 0,
    width: 0
});


const PreviewFooterText = styled("p", {
    display: "flex",
    alignItems: "center",
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    color: "$neutral",
    margin: 0,
    padding: 0,
});

const StyledFileLayout = styled("div", {
    display: "flex",
    width: "100%",
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "space-between",
    padding: '$16',
});

const StyledVidLayout = styled("div", {
    display: "flex",
    alignItems: "center",
    gap: '$16',
    width: '70%',
    overflow: 'scroll',
    "> video": {
        width: '$48',
        height: '$48',
        borderRadius: '$8',
    }
});

const StyledBlogLayout = styled("div", {
    display: "flex",
    alignItems: "center",
    gap: '$16',
    width: '100%',
    "> img": {
        width: '100%',
        height: 300,
        borderRadius: '$8',
        objectFit: 'cover',
    }
});

const StyledCloseLayout = styled("div", {
    position: "absolute",
    top: 0,
    padding: '$8',
    borderRadius: '$96',
    background: '$secondary',
    margin: '$16',
    cursor: 'pointer',
});

const StyledPreview = styled("div", {
    margin: 0,
    top: '$16',
    "> video": {
        width: '100%',
        height: '500px',
        borderRadius: '$8',
        background: 'transparent',
    },
    backgroundColor: 'transparent',
    position: 'relative',
});

export const PromotionCreateItem = ({ icon, label, clickAction }) => {
    return (
        <StyledLayout onClick={clickAction}>
            <Layout css={{
                alignItems: 'center',
                width: 150
            }}>
                <SystemIcon icon={icon} color="inherit" />
                <StyledTitle>{label}</StyledTitle>
            </Layout>
            <SystemIcon icon={'CaretRight'} color="inherit" />
        </StyledLayout>
    );
};

export const UserHeader = ({ userAvatar, username, postDate }) => {
    return (
        <HeaderColumn>
            <Avatar src={userAvatar} />
            <StyledInfoHeader>
                <Typography color='medium'>{'Posting as'}</Typography>
                <Typography fontWeight='bold'>{username}</Typography>
            </StyledInfoHeader>
        </HeaderColumn>
    );
};

export const EmptyHeader = ({ icon, title, description, ...props }) => {
    return (
        <StyledEmptyState {...props}>
            <SystemIcon icon={icon} size="large" color="inherit" />
            <StyledEmptyTitle>{title}</StyledEmptyTitle>
            <StyledDescription>{description}</StyledDescription>
        </StyledEmptyState>
    );
};

export const FileLoader = ({ label }) => {
    return (
        <StyledFileLoader>
            <Spinner />
            <StyledLoaderLabel>{label}</StyledLoaderLabel>
        </StyledFileLoader>
    );
};

export const Files = ({ file, changeFileAction }) => {
    return (
        <StyledFileLayout>
            <StyledVidLayout>
                <video src={file.src} alt='' />
                <StyledLabel>{file.filename}</StyledLabel>
            </StyledVidLayout>
            <Button variant={'neutral'} onClick={changeFileAction}>Change file</Button>
        </StyledFileLayout>
    );
};

export const PreviewUserHeader = ({ userAvatar, username, followlabel, followOnClick = null, videoPlaying,
    playClickAction, pauseClickAction,
    muted,
    muteVideoClickAction,
    unMuteVideoClickAction,
    currnetUsersPost, showCloseIcon,
    closeonClick, }) => {
    return (
        <HeaderPreviewColumn>
            <Layout css={{
                alignItems: 'center',
            }}>
                {showCloseIcon &&
                    <SystemIcon onClick={closeonClick} cursor={'pointer'} icon={'X'} size="large" color={colors.neutral} />
                }
                <Avatar src={userAvatar} />
                <Typography color={'inverse'} fontWeight='bold'>{username}</Typography>
                {currnetUsersPost &&
                    <>
                        <Typography color={'inverse'} fontWeight='bold'>{'.'}</Typography>
                        <Typography color={'inverse'} fontWeight='bold' onClick={followOnClick}>{followlabel}</Typography>
                    </>
                }
            </Layout>
            <Layout css={{
                gap: '$16',
            }}>
                {videoPlaying ?
                    <SystemIcon onClick={pauseClickAction} cursor={'pointer'} icon={'PauseCircle'} size="large" color={colors.neutral} />
                    :
                    <SystemIcon onClick={playClickAction} cursor={'pointer'} icon={'Play'} size="large" color={colors.neutral} />
                }
                {muted ?
                    <SystemIcon onClick={unMuteVideoClickAction} cursor={'pointer'} icon={'SpeakerSlash'} size="large" color={colors.neutral} />
                    :
                    <SystemIcon onClick={muteVideoClickAction} cursor={'pointer'} icon={'SpeakerHigh'} size="large" color={colors.neutral} />
                }
                <SystemIcon icon={'DotsThreeCircle'} size="large" cursor={'pointer'} color={colors.neutral} />
            </Layout>
        </HeaderPreviewColumn>
    );
};

export const PreviewUserFooter = ({ children, description }) => {
    return (
        <HeaderPreviewFooter>
            <PreviewFooterText>{description}</PreviewFooterText>
            {children}
        </HeaderPreviewFooter>
    );
};

export const Preview = ({
    file,
    userAvatar,
    username,
    followlabel,
    description,
    videoRef,
    videoPlaying,
    playClickAction,
    pauseClickAction,
    muted,
    muteVideoClickAction,
    unMuteVideoClickAction, }) => {
    return (
        <StyledPreview>
            <Layout css={{
                width: '100%',
                height: 500,
                backgroundColor: '#00000020',
                "> video": {
                    width: '100%',
                    height: '500px',
                    borderRadius: '$8',
                    background: 'transparent',
                },
            }}>
                <video src={file.src} ref={videoRef} alt='' muted={muted} controls />
            </Layout>
            <PreviewUserHeader
                userAvatar={userAvatar}
                username={username}
                followlabel={followlabel}
                videoPlaying={videoPlaying}
                playClickAction={playClickAction}
                pauseClickAction={pauseClickAction}
                muteVideoClickAction={muteVideoClickAction}
                unMuteVideoClickAction={unMuteVideoClickAction}
                muted={muted}
            />
            {description &&
                <PreviewUserFooter
                    description={description}
                />
            }
        </StyledPreview>
    );
};

export const VlogPreview = ({
    file,
    videoRef,
    muted, }) => {
    return (
        <StyledPreview>
            <Layout css={{
                width: '100%',
                height: 500,
                backgroundColor: '#00000020',
                "> video": {
                    width: '100%',
                    height: '500px',
                    borderRadius: '$8',
                    background: 'transparent',
                },
            }}>
                <video src={file.src} ref={videoRef} alt='' muted={muted} controls />
            </Layout>
        </StyledPreview>
    );
};

export const BlogPreview = ({
    postFeaturedMedia, }) => {
    return (
        <StyledPreview>
            <Layout css={{
                width: '100%',
            }}>
                {postFeaturedMedia}
            </Layout>
        </StyledPreview>
    );
};

export const BlogFiles = ({ file, deleteFileAction }) => {
    return (
        <Layout css={{
            position: 'relative',
        }}>
            <StyledBlogLayout>
                <img src={file.src} alt='' />
            </StyledBlogLayout>
            <StyledCloseLayout onClick={deleteFileAction}>
                <SystemIcon icon='X' color={colors.neutral} />
            </StyledCloseLayout>
            {/* <Button variant={'neutral'} onClick={deleteFileAction}>Change file</Button> */}
        </Layout>
    );
};

PromotionCreateItem.BlogFiles = BlogFiles;
PromotionCreateItem.BlogPreview = BlogPreview;
PromotionCreateItem.VlogPreview = VlogPreview;
PromotionCreateItem.Preview = Preview;
PromotionCreateItem.PreviewUserFooter = PreviewUserFooter;
PromotionCreateItem.PreviewUserHeader = PreviewUserHeader;
PromotionCreateItem.Files = Files;
PromotionCreateItem.FileLoader = FileLoader;
PromotionCreateItem.EmptyHeader = EmptyHeader;
PromotionCreateItem.UserHeader = UserHeader;
PromotionCreateItem.displayName = "PromotionCreateItem";