import { IconButton, Layout } from "../../components";
import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";

const StyledProfileEdit = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "$2",
  textAlign: "center",
  width: "100%",
});

const StyledLabel = styled("p", {
  display: 'flex',
  justifyContent: 'end',
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
 // lineHeight: "$32",
  width: '60%',
});

export const ProfileEdit = ({
  label,
}) => {
  return (
    <StyledProfileEdit>
      <StyledLabel>{label}</StyledLabel>
    </StyledProfileEdit>
  );
};
export const ProfileEditHeader = ({
  label,
  onCloseModal
}) => {
  return (
    <StyledProfileEdit>
      <StyledLabel>{label}</StyledLabel>
      <Layout css={{
        width: '40%',
        display: 'flex',
        justifyContent: 'end',
        fontSize: "$24",
      }} onClick={onCloseModal}>
        <IconButton contained={false} icon='X' variant='secondary' />
      </Layout>
    </StyledProfileEdit>
  );
};
export const ProfileEditIcon = ({
  icon,
  onClickCallback
}) => {
  return (
    <Layout css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '$4',
      width: '$24',
      height: '$24',
      backgroundColor: '$secondary',
      borderRadius: '$96',
    }} onClick={onClickCallback}>
      <SystemIcon size={'small'} icon={icon} color='white' />
    </Layout>
  );
};

ProfileEdit.ProfileEditIcon = ProfileEditIcon
ProfileEdit.ProfileEditHeader = ProfileEditHeader
ProfileEdit.displayName = "ProfileEdit";
