import { styled } from "../../theme";

const StyledDropDown = styled("div", {
    gap: "$2",
    width: '100%',
});

const StyledLabel = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    marginBottom: '$8',
});

export const DropDown = ({
    label,
    children,
}) => {
    return (
        <StyledDropDown>
            {label &&
                <StyledLabel>{label}</StyledLabel>
            }
            {children}
        </StyledDropDown>
    );
};

DropDown.displayName = "DropDown";
