import { styled, shadows } from "../../theme";

import { SystemIcon } from "../../foundations";

const StyledLabelIconButton = styled("button", {
  all: "unset",
  fill: "$neutral",
  border: "none",
  borderRadius: "$8",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: shadows.subtle,
  cursor: "pointer",
  width: 'inherit',
  fontWeight: "$600",
  fontSize: "$20",
  lineHeight: "$24",
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: "$primary",
        // "&:hover": {
        //   backgroundColor: "$interactive",
        // },
        "&:disabled": {
          backgroundColor: "$primary",
          opacity: 0.3,
        },
      },
      secondary: {
        backgroundColor: "$secondary",
        // "&:hover": {
        //   backgroundColor: "$high",
        // },
        "&:disabled": {
          backgroundColor: "$low",
          color: '$neutral',
        },
      },
    },
    size: {
      small: {
        padding: "$4 $8",
      },
      default: {
        padding: "$8 $16",
      },
      large: {
        padding: "$16 $24",
        fontSize: "$20",
      },
    },
  },
  compoundVariants: [
    {
      variant: "primary",
      contained: false,
      css: {
        fill: "$primary",
        backgroundColor: "transparent",
        padding: 0,
        // "&:hover": {
        //   fill: "$interactive",
        //   backgroundColor: "transparent",
        // },
        "&:disabled": {
          fill: "$primary",
          backgroundColor: "transparent",
          opacity: 0.3,
        },
      },
    },
    {
      variant: "secondary",
      contained: false,
      css: {
        fill: "$secondary",
        backgroundColor: "transparent",
        padding: 0,
        // "&:hover": {
        //   fill: "$neutral",
        //   backgroundColor: "transparent",
        // },
        "&:disabled": {
          fill: "$secondary",
          backgroundColor: "transparent",
          opacity: 0.3,
        },
      },
    },
    {
      variant: "neutral",
      contained: false,
      css: {
        fill: "#FFFFFF",
        backgroundColor: "transparent",
        padding: 0,
        // "&:hover": {
        //   fill: "$interactive",
        //   backgroundColor: "transparent",
        // },
        "&:disabled": {
          fill: "#FFFFFF",
          backgroundColor: "transparent",
          opacity: 0.3,
        },
      },
    },
  ],
  defaultVariants: {
    variant: "primary",
    size: "default",
    contained: true,
  },
});


const StyledLabelIconButtonNeutral = styled("button", {
  all: "unset",
  fill: "$neutral",
  padding: "$8 $16",
  border: "none",
  borderRadius: "$8",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxShadow: shadows.subtle,
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
});

const StyledLabel = styled("p", {
  fontFamily: "$roboto",
  color: "#ffff",
  textAlign: "center",
  margin: 0,
  padding: 0,
  paddingLeft: "$10",
  fontSize: "$16",
  lineHeight: "$24",
  cursor: "pointer",
});

const StyledNeutralLabel = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$14",
  lineHeight: "$150",
  color: "#000",
  textAlign: "center",
  margin: 0,
  padding: 0,
  paddingLeft: "$10",
});

const StyledMobileNeutralNeutralLabel = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  color: "#000",
  textAlign: "center",
  margin: 0,
  padding: 0,
  paddingLeft: "$10",
});

const StyledModalLabel = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$20",
  lineHeight: "$24",
  textAlign: "center",
  margin: 0,
  padding: 0,
  variants: {
    variant: {
      primary: {
        color: "$neutral",
      },
      secondary: {
        color: "$neutral300",
      },
    }
  }
});

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param variant - primary | secondary | neutral
 * @param size - default | large
 * @param contained - true | false
 * @param icon - All the possible icons available on https://phosphoricons.com
 * @param label - Button name
 *
 * @example
 *
 * return (
 *   <IconLabelButton variant="primary" size="default" icon="Heart" />
 * )
 */
export const IconLabelButton = ({
  icon,
  size = "default",
  label = '',
  contained = true,
  iconColor = "inherit",
  labelColor = "#ffff",
  ...props
}) => {
  return (
    <StyledLabelIconButton {...props} size={size} contained={contained}>
      {icon &&
        <SystemIcon icon={icon} size={size} color={iconColor} />
      }
      <StyledLabel color={labelColor}>{label}</StyledLabel>
    </StyledLabelIconButton>
  );
};
export const Neutral = ({
  icon,
  size = "default",
  label = '',
  contained = false,
  iconColor = "inherit",
  ...props
}) => {
  return (
    <StyledLabelIconButtonNeutral {...props} size={size} contained={contained}>
      {icon &&
        <SystemIcon icon={icon} size={size} color={iconColor} />
      }
      <StyledNeutralLabel>{label}</StyledNeutralLabel>
    </StyledLabelIconButtonNeutral>
  );
};

export const MobileNeutral = ({
  icon,
  size = "default",
  label = '',
  contained = false,
  iconColor = "inherit",
  ...props
}) => {
  return (
    <StyledLabelIconButtonNeutral {...props} size={size} contained={contained}>
      {icon &&
        <SystemIcon icon={icon} size={size} color={iconColor} />
      }
      <StyledMobileNeutralNeutralLabel>{label}</StyledMobileNeutralNeutralLabel>
    </StyledLabelIconButtonNeutral>
  );
};

export const IconModalLabelButton = ({
  icon,
  size = "default",
  label,
  contained = false,
  iconColor = "inherit",
  onButtonClick,
  ...props
}) => {
  return (
    <StyledLabelIconButton onClick={onButtonClick} {...props} size={size} contained={contained}>
      {icon &&
        <SystemIcon icon={icon} size={size} color={iconColor} />
      }
      <StyledModalLabel {...props}>{label}</StyledModalLabel>
    </StyledLabelIconButton>
  );
};

IconLabelButton.IconModalLabelButton = IconModalLabelButton;
IconLabelButton.Neutral = Neutral;
IconLabelButton.MobileNeutral = MobileNeutral;
IconLabelButton.displayName = "IconLabelButton";