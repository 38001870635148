import { styled } from "../../theme";

const TextVariants = {
  display: {
    fontSize: "3.5rem",
    lineHeight: "4.5rem",
  },
  headingExtraLarge: {
    fontSize: "2.5rem",
    lineHeight: "3rem",
  },
  headingLarge: {
    fontSize: "2rem",
    lineHeight: "2.5rem",
  },
  headingMedium: {
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  headingSmall: {
    fontSize: "1.25rem",
    lineHeight: "2rem",
  },
  bodyLarge: {
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
  },
  body: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  bodySmall: {
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
  },
  bodyExtraSmall: {
    fontSize: "0.75rem",
    lineHeight: "1.5rem",
  },
  microcopy: {
    fontSize: "0.625rem",
    lineHeight: "1.5rem",
  },
};

export const Typography = styled("text", {
  fontFamily: "$roboto",
  variants: {
    variant: TextVariants,
    color: {
      primary: { color: "$primary" },
      secondary: { color: "$secondary" },
      inverse: { color: "$neutral" },
      high: { color: "$high" },
      medium: { color: "$medium" },
      low: { color: "$low" },
    },
    fontWeight: {
      normal: { fontWeight: "$400" },
      bold: { fontWeight: "$600" },
    },
    lineHeight: {
      normal: { lineHeight: "1rem" },
    },
  },
  defaultVariants: {
    variant: "body",
    color: "secondary",
    fontWeight: "normal",
  },
});

Typography.displayName = "Typography";
