import * as AvatarBase from "@radix-ui/react-avatar";

import { styled } from "../../theme";
import { colors } from "../../tokens";
import { ReactNode } from "react";

const StyledAvatarRoot = styled(AvatarBase.Root, {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  verticalAlign: "middle",
  overflow: "hidden",
  userSelect: "none",
  borderRadius: "100%",
  backgroundColor: "$neutral",
  variants: {
    size: {
      extraSmall: {
        width: "$24",
        height: "$24",
      },
      small: {
        width: "$32",
        height: "$32",
      },
      default: {
        width: "$40",
        height: "$40",
      },
      large: {
        width: "$48",
        height: "$48",
      },
      extraLarge: {
        width: "$88",
        height: "$88",
      },
    },
  },
  defaultVariants: {
    size: "default",
  },
});

const StyledBorderAvatarRoot = styled(AvatarBase.Root, {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  verticalAlign: "middle",
  overflow: "hidden",
  userSelect: "none",
  borderRadius: "100%",
  backgroundColor: "$neutral",
  variants: {
    size: {
      extraSmall: {
        width: "$24",
        height: "$24",
      },
      small: {
        width: "$32",
        height: "$32",
      },
      default: {
        width: "$40",
        height: "$40",
      },
      large: {
        width: "$48",
        height: "$48",
      },
      extraLarge: {
        width: "$88",
        height: "$88",
      },
    },
    pointer: {
      true: {
        cursor: "pointer",
      },
    },
  },
  defaultVariants: {
    size: "default",
  },
});
const StyledBorderAvatarImage = styled(AvatarBase.Image, {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "inherit",
});
const StyledAvatarImage = styled(AvatarBase.Image, {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "inherit",
});

const StyledAvatarFallback = styled(AvatarBase.Fallback, {
  backgroundColor: "$low",
  color: "$secondary",
  fontFamily: "$default",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$150",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

/**
 * This component accepts all the image props extended by the @radix-ui/react-avatar.
 * Also it has some internal style props:
 *
 * @param size - extraSmall | small | default | large | extraLarge | doubleExtraLarge
 * @param src - The image source url.
 * @param alt - The image alt text.
 * @param fallback - The image fallback text.
 *
 * @example
 *
 * return (
 *   <Avatar size="default" src="https://www.google.com/imgres?..." alt="A man..." />
 * )
 */
export const Avatar = ({
  size = "default",
  src,
  alt,
  fallback,
}: {
  src?: string;
  size?: string;
  alt?: string;
  fallback?: ReactNode;
}) => {
  return (
    <StyledAvatarRoot size={size}>
      <StyledAvatarImage src={src} alt={alt} style={{ objectFit: "contain" }} />
      {fallback ? (
        <StyledAvatarFallback delayMs={600}>{fallback}</StyledAvatarFallback>
      ) : null}
    </StyledAvatarRoot>
  );
};

export const BorderAvatar = ({
  size = "default",
  src,
  alt,
  fallback,
  onClick,
}) => {
  return (
    <StyledBorderAvatarRoot
      size={size}
      onClick={onClick}
      pointer={!!onClick ? "true" : "false"}
    >
      <StyledBorderAvatarImage
        style={{ border: `4px solid ${colors.primary}` }}
        src={src}
        alt={alt}
      />
      {fallback ? (
        <StyledAvatarFallback delayMs={600}>{fallback}</StyledAvatarFallback>
      ) : null}
    </StyledBorderAvatarRoot>
  );
};

Avatar.BorderAvatar = BorderAvatar;
Avatar.displayName = "Avatar";