import { Image } from "react-bootstrap";
import { Avatar, Bookmark, IconLabelButton, Layout, RatingGroup, RatingStar, Typography } from "../../components";
import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";
import { colors, space } from "../../tokens";

const StyledCookingReviewCard = styled("div", {
    backgroundColor: "$neutral",
    borderRadius: "$16",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$16",
    padding: "$16",
    position: "relative",
});

const StyledImgHeader = styled("div", {

    gap: '$24',
    width: "100%",
    variants: {
        mode: {
            mobile: {
                display: "grid",
            },
            desktop: {
                display: "flex",
            }
        },
    },
});

const StyledImgLayout = styled("div", {
    position: 'relative',
    width: 350,
    height: 380,
    "> img": {
        borderRadius: "$8",
        objectFit: 'cover',

    },
    "> button": {
        width: "100%",
    },
});

const ProductDetailsLayout = styled("div", {
    display: 'block',
    width: '100%'
})

const StyledTitle = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$20",
    lineHeight: "$24",
    color: "$secondary",
    padding: 0,
    margin: 0,
    variants: {
        // mode: {
        //     mobile: {
        //         lineHeight: "$24",
        //     },
        //     desktop: {
        //         lineHeight: "$120",
        //     }
        // },
    },
});

const StyledPrice = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$24",
    lineHeight: "$32",
    color: "$primary",
    marginTop: '$6',
    padding: 0,
});

const StyledBookmark = styled("div", {
    position: "absolute",
    top: "$24",
    right: "$24",
    background: '#00000050',
    borderRadius: '$96',
    padding: '$8',
});

const ReviewLayout = styled("div", {
    display: 'grid',
    borderWidth: space[1],
    borderStyle: 'solid',
    borderColor: '$primary',
    borderRadius: '$8',
    backgroundColor: colors.messages_card_bg_color,
    padding: '$12 $16',
    marginTop: '$6',
    gap: '$2',
})

const ReviewLabel = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$21",
    color: "$secondary",
})

const AverageRating = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$12",
    lineHeight: "$16",
    color: "$primary",
})

const ServiceHeaderLayout = styled("div", {
    display: 'grid',
    marginTop: '$16',
    gap: '$8',
})

const ServiceReviewHeaderLayout = styled("div", {
    display: 'grid',
    marginTop: '$16',
    gap: '$16',
})

const ServiceLayout = styled("div", {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})

const StyledQuestion = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$14",
    lineHeight: "$24",
})

const StyledValue = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$14",
    lineHeight: "$24",
})

const StyledEmptyLayout = styled("div", {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '$16',
    padding: '$16',
    //gap: '$16',
    backgroundColor: colors.messages_card_bg_color,
})

const StyledEmptyHeaderLayout = styled("div", {
    width: '50%',
})
const StyledEmptyState = styled("div", {
    fill: "$secondary",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "$16",
    padding: "$16",
});

const StyledEmptyTitle = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$24",
    lineHeight: "$150",
    color: "$secondary",
    textAlign: "center",
    margin: 0,
    padding: 0,
});

const StyledDescription = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    color: "$medium",
    textAlign: "center",
    margin: 0,
    padding: 0,
});

const StyledReviewLayout = styled("div", {
    width: "100%",
    borderRadius: '$16',
    padding: '$16',
    gap: '$16',
    backgroundColor: colors.messages_card_bg_color,
})

const StyledPostHeader = styled("div", {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "$16",
    width: "100%",
    justifyContent: "space-between",
});

const HeaderColumn = styled("div", {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "$16",
});

const AvatarAction = styled("div", {
    display: "flex",
    cursor: 'pointer'
});

const StyledInfoHeader = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    Width: "100%",
    justifyContent: "flex-end",
});

const Service = ({
    question,
    value
}) => {
    return (
        <ServiceLayout>
            <StyledQuestion>{question}</StyledQuestion>
            <StyledValue>
                <RatingGroup totalText={`${value}`}>
                    {Array.from({ length: 5 }).map((_, index) => {
                        const position = index + 1;
                        let star = value >= position ? "full" : 'empty';
                        if (star === 'empty' && value > position - 1) {
                            star = 'half'
                        }
                        return (
                            <RatingStar
                                size={'small'}
                                filled={star}
                            />
                        );
                    })}
                </RatingGroup>
            </StyledValue>
        </ServiceLayout>
    )
}

export const CookingReviewCard = ({
    checked,
    bookmarkClickAction,
    src,
    alt = '',
    title,
    price,
    rating,
    totalReviews,
    productAverageRating,
    ratingQuestion1,
    ratingQuestion1Value,
    ratingQuestion2,
    ratingQuestion2Value,
    ratingQuestion3,
    ratingQuestion3Value,
    ratingQuestion4,
    ratingQuestion4Value,
    actionButton,
}) => {
    return (
        <StyledCookingReviewCard>
            <StyledImgHeader mode={{
                "@initial": "mobile",
                "@bp3": "desktop",
            }}>
                <StyledImgLayout mode={{
                    "@initial": "mobile",
                    "@bp3": "desktop",
                }}>
                    <StyledBookmark>
                        <Bookmark checked={checked} onClick={bookmarkClickAction} />
                    </StyledBookmark>
                    <Image loading='eager' src={src} alt={alt} style={{ objectFit: 'contain'}} fluid />
                </StyledImgLayout>
                <ProductDetailsLayout>
                    <StyledTitle mode={{
                        "@initial": "mobile",
                        "@bp3": "desktop",
                    }}>{title}</StyledTitle>
                    <StyledPrice>{price}</StyledPrice>

                    <ReviewLayout>
                        {rating}
                        <ReviewLabel>{totalReviews} Customer Reviews</ReviewLabel>
                        <AverageRating>{productAverageRating} out of 5</AverageRating>
                    </ReviewLayout>

                    <ServiceHeaderLayout>
                        <Service
                            question={ratingQuestion1}
                            value={ratingQuestion1Value}
                        />
                        <Service
                            question={ratingQuestion2}
                            value={ratingQuestion2Value}
                        />
                        <Service
                            question={ratingQuestion3}
                            value={ratingQuestion3Value}
                        />
                        <Service
                            question={ratingQuestion4}
                            value={ratingQuestion4Value}
                        />
                    </ServiceHeaderLayout>
                    {actionButton}
                </ProductDetailsLayout>
            </StyledImgHeader>
        </StyledCookingReviewCard>
    );
};

export const EmptyReviewCard = ({
    reviewAddClickAction,
}) => {
    return (
        <StyledEmptyLayout>
            <StyledEmptyHeaderLayout>
                <StyledEmptyState>
                    <SystemIcon icon={'Wallet'} size="large" color={colors.primary} />
                    <StyledEmptyTitle>{'Share your opinion with the community'}</StyledEmptyTitle>
                    <StyledDescription>{`If you have recently purchased this dish, we would like to know your experience.`}</StyledDescription>
                </StyledEmptyState>
                <Layout css={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <IconLabelButton
                        variant={'secondary'}
                        onClick={reviewAddClickAction}
                        label={'Add a Review'}
                    />
                </Layout>
            </StyledEmptyHeaderLayout>
        </StyledEmptyLayout>

    );
};

export const RatingReviewCard = ({
    userInfo,
    reviewDate,
    comments,
    ratingQuestions,
}) => {
    return (
        <StyledReviewLayout>
            <StyledPostHeader>
                <HeaderColumn>
                    <AvatarAction>
                        <Avatar src={userInfo?.photoUrl} />
                    </AvatarAction>
                    <StyledInfoHeader>
                        <Typography fontWeight='bold'>{userInfo?.displayName}</Typography>
                        <Typography color='medium'>{reviewDate}</Typography>
                    </StyledInfoHeader>
                </HeaderColumn>
            </StyledPostHeader>
            {comments}

            <ServiceReviewHeaderLayout>
                {ratingQuestions.map((ratingQuestion) => {
                    let question = ratingQuestion?.ratingQuestion?.question
                    let value = (ratingQuestion?.numberAnswer).toFixed(1);
                    return (
                        <Service
                            question={question}
                            value={value}
                        />
                    )
                })}

            </ServiceReviewHeaderLayout>
        </StyledReviewLayout>
    )
}

CookingReviewCard.RatingReviewCard = RatingReviewCard;
CookingReviewCard.EmptyReviewCard = EmptyReviewCard;
CookingReviewCard.displayName = "CookingReviewCard";