import { styled, shadows } from "../../theme";
import { SystemIcon } from "../../foundations";
import { colors, space } from "../../tokens";
import { Button, Divider, Layout } from "../../components";
import { NetworkBadge } from "../profile/network-badge";

const WalletHeaderStyledLayout = styled("div", {
  display: "flex",
  justifyContent: "end",
  height: 'fit-content',
})

const PointsHeaderLayout = styled("div", {
  display: "block",
  justifyContent: "start",
  alignItems: "start",
  //height: 'fit-content',
  gap: '$16',
  borderRadius: '$16',
  borderWidth: space[1],
  borderStyle: "solid",
  borderColor: "$low",
  variants: {
    size: {
      desktop: {
        width: "23.5%",
        padding: '$24',
        minWidth: 'auto',
      },
      mobile: {
        minWidth: '81%',
        width: "100%",
        padding: '$16',
      },
    },
  },
  compoundVariants: [
    {
      active: true,
      css: {
        fill: "$primary",
        backgroundColor: "$tulip10",
        fontWeight: "$600",
        borderWidth: 0,
      },
    },
  ],
  defaultVariants: {
    active: false,
  },
})

const PointsTransHeaderLayout = styled("div", {
  display: "block",
  justifyContent: "start",
  alignItems: "start",
  width: "49%",
  height: 'fit-content',
  gap: '$16',
  padding: '$16',
  borderRadius: '$8',
  borderWidth: space[1],
  borderStyle: "solid",
  borderColor: "$low",
  compoundVariants: [
    {
      active: true,
      css: {
        fill: "$primary",
        backgroundColor: "$tulip10",
        fontWeight: "$600",
        borderWidth: 0,
      },
    },
  ],
  defaultVariants: {
    active: false,
  },
})

const StyledTransaction = styled("div", {
  justifyContent: "start",
  alignItems: "start",
  width: "100%",
  height: 'fit-content',
  gap: '$26',
  padding: '$16',
  marginTop: '$16',
  borderRadius: '$8',
  borderWidth: space[1],
  borderStyle: "solid",
  borderColor: "$low",
  variants: {
    size: {
      mobile: {
        display: 'block'
      },
      desktop: {
        display: "flex",
      }
    },
  },
})


const StyledAffiliateTransaction = styled("div", {
  alignItems: "start",
  width: "100%",
  height: 'fit-content',
  padding: '$16',
  borderRadius: '$8',
  borderWidth: space[1],
  borderStyle: "solid",
  borderColor: "$low",
  variants: {
    size: {
      mobile: {
        display: 'grid',
        gap: '$4',
      },
      desktop: {
        display: "flex",
        justifyContent: 'space-between',
      }
    },
  },
})

const StyledMobileTransaction = styled("div", {
  display: 'flex',
  width: '100%',
  variants: {
    size: {
      mobile: {
        paddingTop: '$8',
      },
      desktop: {
        paddingTop: 0,
      }
    },
  },
})

const StyledLabelIconButton = styled("button", {
  all: "unset",
  fill: "$neutral",
  border: "none",
  borderRadius: "$8",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: shadows.subtle,
  cursor: "pointer",
  fontWeight: "$600",
  fontSize: "$20",
  lineHeight: "$24",
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: "$primary",
        "&:hover": {
          backgroundColor: "$interactive",
        },
        "&:disabled": {
          backgroundColor: "$primary",
          opacity: 0.3,
        },
      },
      secondary: {
        backgroundColor: "$secondary",
        color: '$neutral',
        "&:hover": {
          backgroundColor: "$high",
        },
        "&:disabled": {
          backgroundColor: "$low",
        },
      },
    },
    size: {
      small: {
        padding: "$4 $8",
      },
      default: {
        padding: "$8 $16",
      },
      large: {
        padding: "$16 $24",
        fontSize: "$20",
      },
    },
    mode: {
      mobile: {
        width: '100%',
        marginTop: '$8',
      },
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "default",
    contained: true,
  },
});


const StyledNeutralLabel = styled("p", {
  fontFamily: "$roboto",
  textAlign: "center",
  fontWeight: "$600",
  margin: 0,
  padding: 0,
  fontSize: "$16",
  lineHeight: "$24",
  variants: {
    variant: {
      neutral: {
        color: "$secondary",
      },
      secondary: {
        color: "$neutral",
      },
    },
  },
});

const StyledTitle = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$32",
  lineHeight: "$24",
});

const PointsLabel = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  margin: 0,
  padding: 0,
  fontSize: "$18",
  lineHeight: "$24",
});

const PointsValue = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  margin: 0,
  padding: 0,
  fontSize: 'xxx-large',
  color: "$primary",
  variants: {
    size: {
      mobile: {
        marginTop: 0,
        fontSize: '$32',
        lineHeight: "$32",
      },
      desktop: {
        marginTop: '$16',
        fontSize: 'xxx-large',
        lineHeight: "$56",
      },
    },
  },
});

const AffiliatePointsValue = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  margin: 0,
  padding: 0,
  fontSize: 'xxx-large',
  variants: {
    size: {
      mobile: {
        marginTop: 0,
        fontSize: '$32',
        lineHeight: "$32",
      },
      desktop: {
        fontSize: '$24',
        lineHeight: "$32",
      },
    },
  },
});

const TransPointsHeader = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  margin: 0,
  padding: 0,
  fontSize: '$24',
  lineHeight: "$32",
  marginTop: '$8',
  color: "$green",
  variants: {
    variant: {
      negative: {
        color: "$red",
      },
    },
  },
});

const PointsInfo = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  margin: 0,
  padding: 0,
  fontSize: "$16",
  lineHeight: "$24",
  color: "$medium",
  variants: {
    size: {
      mobile: {
        marginTop: 0,
        marginBottom: 0,
      },
      desktop: {
        marginTop: '$16',
      },
    },
  },
});

const TransPointsInfo = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  margin: 0,
  padding: 0,
  fontSize: "$16",
  lineHeight: "$24",
  marginTop: '$8',
  color: "$medium",
});

const PointsDate = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  margin: 0,
  padding: 0,
  fontSize: "$16",
  lineHeight: "$24",
  color: "$medium",
  width: '7rem'
});

const StyledLabel = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  margin: 0,
  padding: 0,
  fontSize: "$16",
  lineHeight: "$24",
  color: "$medium",
})

const PointsDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  margin: 0,

  padding: 0,
  fontSize: "$16",
  paddingLeft: '$16',
  lineHeight: "$24",
  color: "$medium",
  variants: {
    mode: {
      mobile: {
        width: "60%",
      },
      desktop: {
        width: "75%",
      }
    },
  },
});

const TransPoints = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",

  margin: 0,
  padding: 0,
  fontSize: "$16",
  lineHeight: "$24",
  color: "$green",
  variants: {
    variant: {
      negative: {
        color: "$red",
      },
    },
    mode: {
      mobile: {
        width: "20%",
        textAlign: 'right',
      },
      desktop: {
        width: "10%",
        textAlign: 'right',
      }
    },
  },
});

const StyledEmptyHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$12",
  width: "100%",
  paddingTop: '$32',
  justifyContent: "center",
});

const StyledLoyaltyHeader = styled("div", {
  display: "block",
  width: "100%",
});

const StyledEmptyTitle = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$32",
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
  color: '$medium',
});

const StyledInfoHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "$16",
  gap: "$24",
  justifyContent: "center",
  backgroundColor: "$neutral",
  borderRadius: "$16",
});

const MyPromotionsLayout = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "start",
});

const AvailablePromotionsLayout = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "$16",
  gap: "$24",
  justifyContent: "start",
  backgroundColor: "$neutral",
});

const PromotionTitleLayout = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$32",
});

const StyledProgramHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$24",
  paddingRight: 0,
});

const ProgramTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$20",
  lineHeight: "$32",
});

const ProgramDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$24",
});

const StyledLoyaltyTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$24",
  color: '$primary',
  paddingTop: '$16',
});

const StyledPaymentCard = styled("div", {
  display: 'grid',
  width: '100%',
  height: 350,
  padding: '$16',
  borderRadius: '$8',
  borderWidth: space[1],
  borderStyle: "solid",
  borderColor: "$low",
  gap: '$12',
});

const StyledPaymentAddCard = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 350,
  padding: '$16',
  borderRadius: '$8',
  borderWidth: space[3],
  borderStyle: 'dashed',
  borderColor: "$low",
  gap: '$12',
  textAlign: 'center',
  cursor: 'pointer',
})

const StyledAccount = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const StyledAccountNumber = styled('p', {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$20",
  lineHeight: "$24",
  margin: 0,
})

const StyledAccountName = styled('p', {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
})

const StyledStatus = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 6,
})

const StyledStatusLabel = styled('p', {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
  color: '$primary',
  variants: {
    type: {
      Approved: {
        color: '$green',
      },
    },
  },
})

const StyledLabelSection = styled('div', {

})

const StyledLabelSectionTitle = styled('p', {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
})

const StyledLabelSectionValue = styled('p', {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
})

export const WalletHeader = ({
  title,
  size = "default",
  label = '',
  variant = 'neutral',
  contained = false,
  iconColor = "inherit",
  buttonClickAction,
  icon,
  ...props
}) => {
  return (
    <WalletHeaderStyledLayout>
      {title &&
        <StyledTitle>
          {title}
        </StyledTitle>
      }
      <StyledLabelIconButton mode={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }} onClick={buttonClickAction} variant={variant} size={size} contained={contained}>
        {icon &&
          <SystemIcon icon={icon} size={size} color={iconColor} />
        }
        <StyledNeutralLabel {...props}>{label}</StyledNeutralLabel>
      </StyledLabelIconButton>
    </WalletHeaderStyledLayout>
  );
};

export const Points = ({
  active,
  label,
  points,
  info,
  icon,
  iconColor,
  ...props
}) => {
  return (
    <PointsHeaderLayout active={active} {...props}
      size={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}>

      <PointsLabel>{label} {icon && <SystemIcon color={iconColor} icon={icon} />}</PointsLabel>
      <PointsValue size={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}>{points}</PointsValue>
      {info &&
        <PointsInfo>{info}</PointsInfo>
      }

    </PointsHeaderLayout>
  )
};

export const TransactionHeader = ({
  label,
  points,
  info,
  icon,
  iconColor,
  varient,
}) => {
  return (
    <PointsTransHeaderLayout>
      <PointsLabel>{label} {icon && <SystemIcon color={iconColor} icon={icon} />}</PointsLabel>
      <TransPointsHeader variant={varient}>{points}</TransPointsHeader>
      <TransPointsInfo>{info}</TransPointsInfo>
    </PointsTransHeaderLayout>
  )
};

export const Transaction = ({
  date,
  icon,
  description,
  iconColor,
  points,
  varient,
}) => {
  return (
    <StyledTransaction size={{
      "@initial": "mobile",
      "@bp3": "desktop",
    }} >
      <StyledMobileTransaction size={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}>
        <PointsDate>{date}</PointsDate>
        {icon &&
          <SystemIcon color={iconColor} icon={icon} />
        }
        <PointsDescription mode={{
          "@initial": "mobile",
          "@bp3": "desktop",
        }}>{description}</PointsDescription>
        <TransPoints variant={varient}>{points}</TransPoints>
      </StyledMobileTransaction>
    </StyledTransaction>
  )
};



export const AffiliateTransaction = ({
  date,
  name,
  registercount,
  visitorcount,
  socialmedia,
  points,
  varient,
}) => {
  return (
    <StyledAffiliateTransaction size={{
      "@initial": "mobile",
      "@bp3": "desktop",
    }}>
      <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
        <StyledLabel>{date}</StyledLabel>
        <SystemIcon icon={'Share'} />
        <StyledLabel style={{ fontWeight: 'bold' }}>{name}</StyledLabel>
      </div>
      <StyledLabel style={{ fontWeight: 'bold' }}>{registercount} registration</StyledLabel>
      <StyledLabel>{visitorcount} visitors</StyledLabel>
      <StyledLabel>{socialmedia}</StyledLabel>
      <TransPoints variant={varient}>{points}</TransPoints>
    </StyledAffiliateTransaction>
  )
};

export const AffiliateTransactionMobile = ({
  date,
  name,
  registercount,
  visitorcount,
  socialmedia,
  points,
  varient,
}) => {
  return (
    <StyledAffiliateTransaction size={{
      "@initial": "mobile",
      "@bp3": "desktop",
    }}>
      <StyledLabel>{date}</StyledLabel>
      <Layout css={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <SystemIcon icon={'Share'} />
        <StyledLabel style={{ fontWeight: 'bold' }}>{name}</StyledLabel>
        <TransPoints variant={varient}>{points}</TransPoints>
      </Layout>
      <StyledLabel style={{ fontWeight: 'bold' }}>Registration: {registercount}</StyledLabel>
      <StyledLabel style={{ fontWeight: 'bold' }}>Visitors: {visitorcount}</StyledLabel>
      <StyledLabel style={{ fontWeight: 'bold' }}>Source: {socialmedia}</StyledLabel>
    </StyledAffiliateTransaction>
  )
};

export const AffiliatePoints = ({
  active,
  label,
  points,
  info,
  icon,
  iconColor,
  ...props
}) => {
  return (
    <PointsHeaderLayout active={active} {...props}
      size={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}>

      <PointsLabel>{label} {icon && <SystemIcon color={iconColor} icon={icon} />}</PointsLabel>
      <AffiliatePointsValue size={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}>{points}</AffiliatePointsValue>
      {info &&
        <PointsInfo>{info}</PointsInfo>
      }

    </PointsHeaderLayout>
  )
};

export const EmptyTransaction = ({
  onClick = null
}) => {
  return (
    <StyledEmptyHeader>
      <StyledInfoHeader>
        <SystemIcon icon={'Wallet'} size="large" color="inherit" />
        <StyledEmptyTitle>{"You haven't earned any points yet"}</StyledEmptyTitle>
        <StyledDescription>{"Start earning points and convert them into amazing purchases"}</StyledDescription>
        <Button onClick={onClick}>
          {'Start earning points'}
        </Button>
      </StyledInfoHeader>
    </StyledEmptyHeader>
  )
};

export const PromotionsTitle = ({
  title,
}) => {
  return (
    <PromotionTitleLayout>
      {title}
    </PromotionTitleLayout>
  )
};

export const MyPromotions = ({
  promotionImage,
  alt = '',
}) => {
  return (
    <MyPromotionsLayout>
      <img loading="eager" src={promotionImage} alt={alt} width={'100%'} style={{ borderRadius: '16px' }} />
    </MyPromotionsLayout>
  )
};

export const AvailablePromotions = ({
  onClick = null
}) => {
  return (
    <AvailablePromotionsLayout>

    </AvailablePromotionsLayout>
  )
};

export const PromotionEmpty = ({ title, description }) => {
  return (
    <StyledEmptyHeader>
      <StyledInfoHeader>
        <SystemIcon icon={'Cards'} size="large" color="inherit" />
        <StyledEmptyTitle>{title}</StyledEmptyTitle>
        <StyledDescription>{description}</StyledDescription>
      </StyledInfoHeader>
    </StyledEmptyHeader>
  );
};

export const LoyaltyProgram = ({ icon, title, programlist, iconColor }) => {
  return (
    <StyledLoyaltyHeader>
      <SystemIcon icon={icon} size="large" color={iconColor} />
      {title &&
        <StyledLoyaltyTitle>{title}</StyledLoyaltyTitle>
      }
      {programlist.length > 0 &&
        <StyledProgramHeader>
          {programlist.map((program) => {
            return (
              <Layout css={{
                padding: '$16',
                paddingRight: 0,
                background: '$tulip10',
                display: 'block',
                borderRadius: '$16',
              }}>
                <ProgramTitle>{program.title}</ProgramTitle>
                <ProgramDescription>{program.description}</ProgramDescription>
              </Layout>
            )
          })}
        </StyledProgramHeader>
      }
    </StyledLoyaltyHeader>
  );
};

export const PaymentInfo = ({
  status,
}) => {
  return (
    <StyledPaymentCard>
      <StyledAccount>
        <NetworkBadge css={{
          width: '$64',
          height: '$64',
          padding: '$16',
          gap: '$10',
          backgroundColor: '$tulip10',
          borderRadius: '$96',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '8px'
        }} icon="Bank">
        </NetworkBadge>
        <div>
          <StyledAccountNumber>8310262100</StyledAccountNumber>
          <StyledAccountName>Sormy</StyledAccountName>
        </div>
      </StyledAccount>

      <StyledStatus>
        <SystemIcon icon={status === 'Approved' ? 'CheckCircle' : 'Clock'} color={status === 'Approved' ? colors.green : colors.primary} />
        <StyledStatusLabel type={status}>{status === 'Approved' ? 'Approved' : 'Pending approval'}</StyledStatusLabel>
      </StyledStatus>
      <StyledLabelSection>
        <StyledLabelSectionTitle>SWIFT/BIC</StyledLabelSectionTitle>
        <StyledLabelSectionValue>CMF3672D</StyledLabelSectionValue>
      </StyledLabelSection>
      <StyledLabelSection>
        <StyledLabelSectionTitle>Routing Number</StyledLabelSectionTitle>
        <StyledLabelSectionValue>238792380434</StyledLabelSectionValue>
      </StyledLabelSection>
      <Divider />
      <Button disabled={status !== 'Approved'} variant={'neutral'}>Edit information</Button>
    </StyledPaymentCard>
  )
};
export const PaymentInfoAdd = (addBankAccount) => {
  return (
    <StyledPaymentAddCard onClick={addBankAccount}>
      <div>
        <SystemIcon size={'extraLarge'} icon={'PlusCircle'} color={colors.low} />
        <StyledAccountNumber>Add new bank account</StyledAccountNumber>
        <StyledAccountName style={{ color: colors.medium }}>Click here to register a new bank account</StyledAccountName>
      </div>
    </StyledPaymentAddCard>
  )
};

WalletHeader.PaymentInfoAdd = PaymentInfoAdd;
WalletHeader.PaymentInfo = PaymentInfo;
WalletHeader.AffiliateTransactionMobile = AffiliateTransactionMobile;
WalletHeader.AffiliateTransaction = AffiliateTransaction;
WalletHeader.AffiliatePoints = AffiliatePoints;
WalletHeader.LoyaltyProgram = LoyaltyProgram;
WalletHeader.PromotionEmpty = PromotionEmpty;
WalletHeader.PromotionsTitle = PromotionsTitle;
WalletHeader.MyPromotions = MyPromotions;
WalletHeader.AvailablePromotions = AvailablePromotions;
WalletHeader.EmptyTransaction = EmptyTransaction;
WalletHeader.Transaction = Transaction;
WalletHeader.TransactionHeader = TransactionHeader;
WalletHeader.Points = Points;
WalletHeader.displayName = "WalletHeader";