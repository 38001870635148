import { styled, shadows } from "../../theme";
import { space } from "../../tokens";

import { SystemIcon } from "../../foundations";

const StyledSearchField = styled("input", {
  color: "$secondary",
  backgroundColor: "$neutral",
  borderRadius: "$8",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  boxSizing: "border-box",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$150",
  paddingLeft: "$16",
  paddingRight: "$16",
  paddingTop: "$8",
  paddingBottom: "$8",
  width: "100%",
  minWidth: "100%",
  textAlign: "center",
  "&:hover": {
    borderColor: "$primary",
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
    "+ div": {
      display: "none",
    },
  },
  "&::placeholder": {
    color: "$secondary",
  },
});

const StyledContainer = styled("div", {
  position: "relative",
  width: "100%",
  minWidth: "100%",
});

const StyledPlaceholderContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$10",
  position: "absolute",
  inset: 0,
  pointerEvents: "none",
  userSelect: "none",
  fill: "$primary",
});

const StyledPlaceholderText = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$24",
  margin: 0,
  padding: 0,
  color:'$neutral600'
});

/**
 * This component accepts all the input props extended by the HTML5.
 *
 * @example
 *
 * return (
 *   <SearchField value="Any value..." />
 * )
 */
export const SearchField = (props) => {
  return (
    <StyledContainer>
      <StyledSearchField placeholder="" {...props} />
      {!props.value ? (
        <StyledPlaceholderContainer>
          <SystemIcon
            icon="MagnifyingGlass"
            size="small"
            color="inherit"
            weight="bold"
          />
          <StyledPlaceholderText>Search for anything...</StyledPlaceholderText>
        </StyledPlaceholderContainer>
      ) : null}
    </StyledContainer>
  );
};

SearchField.displayName = "SearchField";
