import { isMobile } from "react-device-detect";
import { styled } from "../../theme";
import { space } from "../../tokens";
import { Box, Layout } from "../../components";

const StyledLayout = styled("div", {
  paddingLeft: "$6",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
  padding: 0,
  color: '$neutral300'
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
  paddingTop: '$6',
  color: '$neutral300'

});

export const ProfileSettingsInfo = ({ title = null, description = null }) => {
  return (
    <StyledLayout>
      <StyledTitle>
        {title}
      </StyledTitle>
      <StyledDescription>
        {description}
      </StyledDescription>
    </StyledLayout>
  );
};

export const TermsAndConditions = ({ title, onClickTerms }) => {
  return (
    <Layout css={{
      width : isMobile ? '100%':'24%'
    }}>
      <Box css={{
        width: '100%',
        padding: "$24",
        gap: '$16',
        borderWidth: space[1],
        borderRadius: '$16',
        borderStyle: "solid",
        borderColor: "$low",
        cursor: "pointer",
        marginLeft: isMobile ? 0: '$8',
        marginTop: isMobile ? '$16': 0,
      }} onClick={onClickTerms}>
        <StyledTitle>
          {title}
        </StyledTitle>
      </Box>
    </Layout>
  );
};


ProfileSettingsInfo.TermsAndConditions = TermsAndConditions
ProfileSettingsInfo.DisplayName = "ProfileSettingsInfo";
