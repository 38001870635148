import { SystemIcon } from "../../../foundations";
import { styled, shadows } from "../../../theme";

const StyledTabItem = styled("button", {
  all: "unset",
  fill: "$medium",
  color: "$secondary",
  border: "none",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$150",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  paddingLeft: "$16",
  paddingRight: "$16",
  paddingTop: "$8",
  paddingBottom: "$8",
  minWidth: 'fit-content',
  cursor: "pointer",
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  variants: {
    variant: {
      horizontal: {
        gap: "$8",
        borderRadius: "$96",
      },
      vertical: {
        gap: "$16",
        borderRadius: 0,
        width: "100%",
      },
    },
  },
  compoundVariants: [
    {
      variant: "horizontal",
      active: true,
      css: {
        fill: "$primary",
        backgroundColor: "$tulip10",
        fontWeight: "$600",
      },
    },
    {
      variant: "vertical",
      active: true,
      css: {
        fill: "$primary",
        fontWeight: "$600",
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  ],
  defaultVariants: {
    variant: "horizontal",
    active: false,
  },
});

const StyledBorder = styled("div", {
  backgroundColor: "$primary",
  borderTopRightRadius: "$16",
  borderBottomRightRadius: "$16",
  width: "$4",
  height: "$24",
});

const StyledEmptyBorder = styled("div", {
  width: "$4",
});

const StyledLeftAdornment = styled("div", {
  display: "flex",
  alignItems: "center",
});

const StyledRightAdornment = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: 'end',
  width: "100%",
  gap: "$12",
  paddingRight:'$16',
});

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param variant - horizontal | vertical
 * @param active - true | false
 * @param icon - All the possible icons available on https://phosphoricons.com
 *
 * @example
 *
 * return (
 *   <TabItem active variant="horizontal" icon="Heart">Label</TabItem>
 * )
 */
export const PollTabItem = ({ children, icon, active = false, isLeftBar = true, rightIcon, ...props }) => {
  const isActive = active && props.variant === "vertical";

  return (
    <StyledTabItem {...props} active={active}>
      {isActive || icon ? (
        <StyledLeftAdornment>
          {(isActive && isLeftBar) ? <StyledBorder /> : <StyledEmptyBorder />}
          {icon ? (
            <SystemIcon icon={icon} size='default' color='inherit' />
          ) : null}
        </StyledLeftAdornment>
      ) : null}
      {children}
      {rightIcon && (
        <StyledRightAdornment>
          <SystemIcon icon={rightIcon} size='default' color={'#666666'} />
        </StyledRightAdornment>
      )
      }
    </StyledTabItem>
  );
};

PollTabItem.displayName = "PollTabItem";