import { styled } from "../../theme";

/**
 * This component accepts all the div props extended by the HTML5.
 * Prefer using with the TabItem component.
 *
 * @example
 *
 * return (
 *   <SideMenu>
 *    <TabItem variant="horizontal" icon="Question">Label 1</TabItem>
 *    <TabItem variant="horizontal" icon="Heart">Label 2</TabItem>
 *   </SideMenu>
 * )
 */
export const SideMenu = styled("nav", {
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "flex-start",
  gap: "$24",
  backgroundColor: "$neutral",
});

SideMenu.displayName = "SideMenu";
