import { GiChiliPepper } from "react-icons/gi";
import { Layout, Typography } from "../../components";
import { styled } from "../../theme";
import { BsFillCircleFill } from "react-icons/bs";
import { checkNull } from "../../../utilities/generic";

const HeaderLayout = styled("div", {
    display: "flex",
    width: "100%",
    marginRight: '$10',
    gap: '$16',
    alignItems: 'center',
})


const StyledTitle = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: '$20',
    lineHeight: '55px',
    margin: 0,
    padding: 0,
})

const StyledDescription = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: '$24',
    margin: 0,
    padding: 0,
    height: 50,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})
const StyledPrice = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$24",
    lineHeight: "$32",
    color: "$primary",
    marginTop: '$6',
    padding: 0,
})

const StyledType = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    gap: '$16'
})

const StyledIsVeg = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
})
export const ShopbuzzHeadView = ({
    ratings,
    project_name,
    description,
    price,
    spicylevel,
    isVeg,
    servingsize,
    isdishView,
    children,
}) => {
    if (checkNull(spicylevel) !== '') {
        spicylevel = parseInt(spicylevel)
    } else {
        spicylevel = 0
    }
    return (
        <Layout css={{
            display: 'grid',
            gap: '$2',
        }}>
            <HeaderLayout>
                <StyledTitle>{project_name}</StyledTitle>
                {ratings}
            </HeaderLayout>
            {
                isdishView &&
                <StyledType>
                    {
                        isVeg === "vegetarian" && <StyledIsVeg>
                            <BsFillCircleFill size={12} color={isVeg ? 'green' : '#bd2626'} className="me-2" />
                            <Typography variant={'bodyExtraSmall'} style={{ lineHeight: '1.25rem' }}>{isVeg ? "Veg" : 'Non-veg'}</Typography>
                        </StyledIsVeg>
                    }
                    <Typography>Spicy Level: 
                        {Array.from([1,2,3], (x, _index) => {
                            _index += 1
                            return (
                                <GiChiliPepper className="ms-1" color={spicylevel >= _index ? 'red' : 'grey'} />
                            )
                        })}
                    </Typography>
                    <Typography>{`Serving Size: ${servingsize ? servingsize : '- - -'}`}</Typography>
                </StyledType>
            }
            <Layout css={{
                display:'flex',
                alignItems:'end',
                gap: '$16',
            }}>
                <StyledPrice>{price}</StyledPrice>
                {children}
            </Layout>
            {
                description &&
                <StyledDescription dangerouslySetInnerHTML={{
                    __html: `${description}`,
                }} />
            }
        </Layout >
    );
};

ShopbuzzHeadView.displayName = "ShopbuzzHeadView";