import { FormControl, FormGroup, FormLabel, Image } from "react-bootstrap";
import { Radio } from "../../../components";
import { styled } from "../../../theme";
import { space } from "../../../tokens";
import NoMedia from "src/assets/images/no_media_image.png";
import { store } from "../../../../store";

const StyledChoiceLayout = styled("div", {
  borderRadius: "$8",
  borderStyle: "solid",
  borderWidth: space[2],
  borderColor: "$low",
  padding: "$10",
  marginTop: "$8",
});

const StyledChoice = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$32",
  margin: 0,
});

export const Option = ({
  option,
  value = [],
  onChangeFreeText = () => {},
  withAsset = false,
}) => {
  const isCurrentAnswer = value.includes(option.id);

  const isOtherOption = option.optionType === "OTHER";

  const renderTextField = isCurrentAnswer && isOtherOption;

  return withAsset ? (
    <OptionImage
      isOtherOption={isOtherOption}
      onChangeFreeText={onChangeFreeText}
      option={option}
      renderTextField={renderTextField}
      isCurrentAnswer={isCurrentAnswer}
    />
  ) : (
    <OptionText
      isOtherOption={isOtherOption}
      onChangeFreeText={onChangeFreeText}
      option={option}
      renderTextField={renderTextField}
      isCurrentAnswer={isCurrentAnswer}
    />
  );
};

const OptionText = ({
  option,
  onChangeFreeText,
  renderTextField,
  isCurrentAnswer,
}) => {
  const { theme } = store.getState();

  return (
    <StyledChoiceLayout
      css={{
        borderColor: isCurrentAnswer ? theme.primary : theme.low,
        backgroundColor: theme.interactive,
      }}
    >
      <div>
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Radio value={option.id} indicatorColor={theme.primary}/>{" "}
          <StyledChoice
            css={{ color: theme.secondary, backgroundColor: theme.interactive }}
          >
            {option.text}
          </StyledChoice>
        </div>
        {renderTextField && (
          <FormGroup style={{ marginTop: 12 }}>
            <FormLabel title="">Enter your answer here:</FormLabel>
            <FormControl onChange={(e) => onChangeFreeText(e)} placeholder="" />
          </FormGroup>
        )}
      </div>
    </StyledChoiceLayout>
  );
};

const OptionImage = ({
  option,
  onChangeFreeText,
  renderTextField,
  isCurrentAnswer,
}) => {
  const { theme } = store.getState();
  return (
    <StyledChoiceLayout
      css={{
        borderColor: isCurrentAnswer ? theme.primary : theme.low,
        backgroundColor: theme.interactive,
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 12 }}
      >
        <Image
          src={option.imageAsset?.url ?? NoMedia}
          height={130}
          width="100%"
          style={{ objectFit: "cover", borderRadius: 8 }}
        />
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Radio value={option.id} indicatorColor={theme.primary} />{" "}
          <StyledChoice
            css={{ color: theme.secondary, backgroundColor: theme.interactive }}
          >
            {option.text}
          </StyledChoice>
        </div>

        {renderTextField && (
          <FormGroup style={{ marginTop: 12 }}>
            <FormLabel title="">Enter your answer here:</FormLabel>
            <FormControl onChange={(e) => onChangeFreeText(e)} placeholder="" />
          </FormGroup>
        )}
      </div>
    </StyledChoiceLayout>
  );
};
