import { styled } from "../../theme";

/**
 * This component accepts all the div props extended by the HTML5.
 * Prefer using with the BottomMenuItem and IconButton component.
 *
 * @example
 *
 * return (
 *   <BottomMenu>
 *    <BottomMenuItem icon='Cube' />
 *    <BottomMenuItem icon='Video' />
 *    <IconButton icon='PlusCircle' />
 *    <BottomMenuItem icon='MagnifyingGlass' />
 *    <BottomMenuItem icon='List' />
 *   </BottomMenu>
 * )
 */
export const BottomMenu = styled("nav", {
  backgroundColor: "$neutral",
  borderTopLeftRadius: "$16",
  borderTopRightRadius: "$16",
  boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.04)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$2",
  position: "fixed",
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 999,
});

BottomMenu.displayName = "BottomMenu";
