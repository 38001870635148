import { styled } from "../../theme";
import { space } from "../../tokens";

const StyledMobileHeader = styled("header", {
  backgroundColor: "$neutral",
  borderBottomWidth: space["1"],
  borderBottomStyle: "solid",
  borderBottomColor: "$low",
  paddingLeft: "$16",
  paddingRight: "$16",
  paddingTop: "$12",
  paddingBottom: "$12",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  //position: "fixed",
  width: "100%",
  top: "0",
  left: "0",
  right: "0",
  zIndex: "9999",
});

const StyledRightAdornment = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$16",
});

/**
 * This component accepts all the header props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param leftAdornment - A React element positioned in the left of the header, prefer use LogoPill component.
 * @param rightAdornment - A React element positioned in the right of the header, prefer use Notification button and Avatar component.
 *
 * @example
 *
 * return (
 *   <MobileHeader
 *      leftAdornment={
 *        <LogoPill src="https://iili.io/HPVInCF.png" alt="test" />
 *      }
 *      rightAdornment={
 *        <>
 *          <NotificationButton variant="message" />
 *          <NotificationButton variant="notification" />
 *          <Avatar size="default" src="https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?&w=128&h=128&dpr=2&q=80" />
 *        </>
 *      }
 *   />
 * )
 */
export const MobileHeader = ({ leftAdornment, rightAdornment }) => {
  return (
    <StyledMobileHeader>
      <div>{leftAdornment}</div>
      <StyledRightAdornment>{rightAdornment}</StyledRightAdornment>
    </StyledMobileHeader>
  );
};

MobileHeader.displayName = "MobileHeader";
