import { store } from "../../../store";
import { Checkbox, Layout } from "../../components";

export const SkeletonOpinionQuestionRow = ({ index = 0 }) => {
  const { theme } = store.getState();
  return (
    <Layout
      css={{
        display: "flex",
        alignItems: "center",
        gap: 7,

        backgroundColor: theme.secondary,
        border: `1px solid ${theme.low}`,
        padding: "$10",
        borderRadius: "$8",

        width: "100%",
        minHeight: "40px",

        fontSize: 16,
      }}
    >
      <Checkbox />
      <div>Question {index}</div>
    </Layout>
  );
};
