import { styled } from "../../theme";
import { Avatar, Layout, Typography } from "../../components";
import { SystemIcon } from "../../foundations";
import { Image } from "react-bootstrap";

const StyledProfileContact = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "end",
  gap: "$2",
  position: "relative",
  width: "fit-content",
  cursor: "pointer",
  textAlign: "end",
  "@mw1024": {
    textAlign: "start",
  },
});

const StyledUserLayout = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  overflow: "hidden",
  gap: "32px",
});

const StyledLabel = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  color: "$secondary",
  margin: 0,
  padding: 0,
});

const StyledStatus = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$14",
  lineHeight: "$24",
  color: "#12D460",
  margin: 0,
  padding: 0,
  width: "100%",
});

const StyledContactHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
  width: "100%",
});

const StyledInfoHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$6",
  marginTop: "$6",
  paddingBlock: "$4",
  paddingInline: "$8",
  width: "fit-content",
  borderRadius: "$16",
});

const StyledFollower = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
  width: "100%",
  marginTop: "$6",
  color: "red",
});

const HeaderColumn = styled("div", {
  display: "grid",
  alignItems: "center",
  gap: "$8",
  width: "100%",
});

const StyledTropyLayout = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "stretch",
  padding: "$6",
  borderRadius: "$24",
  backgroundColor: "#6A6E751A",
});

const StyledName = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$20",
  lineHeight: "$120",
  color: "$secondary",
  margin: 0,
  padding: 0,
});

const StyledFollowerName = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
  padding: 0,
  color: "$neutral400",
});

const TrophyLabel = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$14",
  lineHeight: "$24",
  margin: 0,
  padding: 0,
  color: "$neutral300",
});

const StyledBioTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$20",
  lineHeight: "$24",
  margin: 0,
  padding: 0,
});

// const StyledBio = styled("p", {
//   fontFamily: "$roboto",
//   fontWeight: "$400",
//   fontSize: "$16",
//   lineHeight: "$24",
//   margin: 0,
//   paddingTop: "$16",
//   paddingBottom: "$24",
// });

const StyledProfileInformation = styled("div", {
  display: "flex",
  flexDirection: "column",
  paddingBlock: "$24",
  gap: "$12",
});

const StyledInfos = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "unset",
});

export const ContactInfo = ({
  name,
  label,
  labelColor,
  followers,
  isInfluencer,
  followersLength,
  onLabelClick,
  userAvatar = '',
  avatarSize = 'default',
}) => {
  let follow_label = "";
  try {
    if (followersLength === 0) {
      follow_label = "You have no followers yet";
    } else {
      follow_label = followersLength + " Followers";
    }
  } catch (e) {}
  return (
    <StyledContactHeader>
      <Layout css={{}}>
        <Avatar.BorderAvatar size={avatarSize} src={userAvatar} />
      </Layout>
      <HeaderColumn>
        <StyledName fontWeight="bold">{name}</StyledName>
        {label && (
          <StyledInfoHeader
            css={{ backgroundColor: labelColor, cursor: onLabelClick ? 'pointer' : 'default' }}
            onClick={() =>
              isInfluencer && onLabelClick ? onLabelClick() : null
            }
          >
            <StyledTropyLayout>
              <SystemIcon size="small" icon="Trophy" />
            </StyledTropyLayout>
            {isInfluencer ? (
              <TrophyLabel>{`${label} Influencer`}</TrophyLabel>
            ) : (
              <TrophyLabel>{`${label}`}</TrophyLabel>
            )}
            {/* <SystemIcon icon="Info" /> */}
          </StyledInfoHeader>
        )}
        {followers && (
          <StyledFollower>
            {followers}
            <StyledFollowerName>{follow_label}</StyledFollowerName>
          </StyledFollower>
        )}
      </HeaderColumn>
    </StyledContactHeader>
  );
};

/**
 * This component accepts only internal props
 *
 * @param label - The empty state title.
 * @param status - The empty state title.
 *
 * @example
 *
 * return (
 *   <ProfileContact>
 *       <div>cohora@gmail.com</div>
 *       <div>Verify</div>
 *   </ProductCard>
 * )
 */
export const ProfileContact = ({ label, status, onClickAction }) => {
  return (
    <StyledProfileContact>
      <StyledLabel>{label}</StyledLabel>
      <StyledStatus onClick={onClickAction}>{status}</StyledStatus>
    </StyledProfileContact>
  );
};

export const ProfileBio = ({ title, information }) => {
  return (
    <Layout
      css={{
        display: "block",
        padding: 0,
        margin: 0,
      }}
    >
      {title && <StyledBioTitle>{title}</StyledBioTitle>}
      <StyledProfileInformation>
        <StyledInfos
          css={{ display: "flex", flexDirection: "column", gap: "unset" }}
        >
          <Typography variant={"body"} fontWeight={"bold"}>
            {"EXPLORE"}
          </Typography>
          <Typography>
            {
              "Browse the latest trends, join groups of like-minded customer, discover new recipes"
            }
          </Typography>
        </StyledInfos>
        <StyledInfos css={{ display: "flex", flexDirection: "column" }}>
          <Typography variant={"body"} fontWeight={"bold"}>
            {"EARN"}
          </Typography>
          <Typography>
            {
              "Share posts, enter contests and polls, post reviews & ratings, and refer your friends"
            }
          </Typography>
        </StyledInfos>
        <StyledInfos css={{}}>
          <Typography variant={"body"} fontWeight={"bold"}>
            {"REDEEM"}
          </Typography>
          <Typography>
            {
              "Shop for product with points or redeem for promotions, rewards or cash"
            }
          </Typography>
        </StyledInfos>
      </StyledProfileInformation>
      {/* <StyledBio>{information}</StyledBio> */}
    </Layout>
  );
};

export const ProfileBioItem = ({ title = "", src = "", description = "" }) => {
  return (
    <div
      style={{
        position: "relative",
        borderRadius: 8,
        height: 160,
        width: "100%",
      }}
    >
      <Image
        src={src}
        alt="title"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          filter: "blur(1px) brightness(0.9)",
          borderRadius: 8,
        }}
      />
      <div
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          zIndex: 1,
          height: "100%",
          top: 0,
        }}
      >
        <div
          style={{
            padding: "8px 12px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            margin: "0 24px",
            borderRadius: 8,
          }}
        >
          <div>
            <Typography fontWeight="bold">{title}</Typography>
          </div>
          <div>
            <Typography>{description}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserInfo = ({ children }) => {
  return <StyledUserLayout>{children}</StyledUserLayout>;
};

ProfileContact.UserInfo = UserInfo;
ProfileContact.ProfileBio = ProfileBio;
ProfileContact.ContactInfo = ContactInfo;
ProfileContact.displayName = "ProfileContact";
