export const space = {
  1: "0.0625rem",
  2: "0.125rem",
  4: "0.25rem",
  6: "0.375rem",
  8: "0.5rem",
  10: "0.625rem",
  12: "0.75rem",
  16: "1rem",
  24: "1.5rem",
  32: "2rem",
  36: "2.25rem",
  40: "2.5rem",
  44: "2.75rem",
  48: "3rem",
  88: "5.5rem",
};
