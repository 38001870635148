import { RadioGroup } from "../../..";
import { styled } from "../../../theme";
import { ChoiceImages } from "./choice-section-images";
import { ChoiceText } from "./choice-section-text";
import { useMemo } from "react";
import { store } from "../../../../store";

const StyledLayout = styled("div", {
  display: "block",
  borderRadius: "$16",
  marginTop: "$8",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$8",
  position: "relative",
});

const StyledQuestion = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$32",
  margin: 0,
  padding: 0,
});

export const SingleChoice = ({
  question,
  assets,
  onChange,
  changeOtherOption,
  value,
}) => {
  const isQuestionWithAssets = useMemo(
    () => question.quizQuestionOptions.some((opt) => !!opt.imageAsset),
    [question]
  );

  const {theme} = store.getState() 

  return (
    <StyledLayout>
      <StyledQuestion css={{color: theme.secondary}}>{question.text}</StyledQuestion>
      {assets}
      <RadioGroup defaultValue="val" onValueChange={onChange}>
        {isQuestionWithAssets ? (
          <ChoiceImages
            value={value}
            options={question.quizQuestionOptions}
            onChangeFreeText={changeOtherOption}
            single
          />
        ) : (
          <ChoiceText
            value={value}
            options={question.quizQuestionOptions}
            onChangeFreeText={changeOtherOption}
            single
          />
        )}
      </RadioGroup>
    </StyledLayout>
  );
};

SingleChoice.displayName = "SingleChoice";
