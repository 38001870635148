import { store } from "../../store";
import constant from "../../utilities/constant";
export const colors = {
  /* Globals */
  primary: localStorage.getItem(constant.primaryColor),
  interactive: localStorage.getItem(constant.interactiveColor),
  secondary: localStorage.getItem(constant.secondaryColor),
  neutral: localStorage.getItem(constant.neutralColor),
  high: store.getState().theme.high,
  medium: store.getState().theme.medium,
  low: "#BDC3C7", // Should be changed

  /* System */
  red: "#F23B3B",
  green: "#11A87A",
  media_bg_color: "black",
  messages_card_bg_color: "#FEF8EC",

  /* Neutral */
  neutral10: "#F7F7F7",
  neutral50: store.getState().theme.low,
  neutral100: "#C8C8C8",
  neutral200: "#A4A4A4",
  neutral300: "#818181",
  neutral400: store.getState().theme.medium,
  neutral500: "#515151",
  neutral600: store.getState().theme.high,
  neutral700: "#383838",
  neutral800: "#313131",
  neutral900: localStorage.getItem(constant.secondaryColor),

  /* Tulip */
  tulip10: localStorage.getItem(constant.interactiveColor),
  tulip50: "#FBEBCA",
  tulip100: "#F8D58F",
  tulip200: "#F4BA55",
  tulip300: "#F2A73B",
  tulip400: localStorage.getItem(constant.interactiveColor),
  tulip500: "#CF5D10",
  tulip600: "#AC4011",
  tulip700: "#8C3114",
  tulip800: "#732A14",
  tulip900: "#421306",

  gold: "#C9B037",
  silver: "#B4B4B4",
  bronze: "#CD7F32",
};
