import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { default as styledComp } from "styled-components";
import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";
import { colors, space } from "../../tokens";
import { Avatar } from "../avatar/avatar";
import { FavoritesButton } from "../favourites-button";
import { IconLabelButton } from "../icon-label-button/icon-label-button";
import { Layout } from "../layout/layout";
import { RatingGroup } from "../rating-group/rating-group";
import { RatingStar } from "../rating-star/rating-star";
import { Typography } from "../typography/typography";
import { Button } from "../button/button";
import { ProductBadge } from "../product-badge";

const StyledProductReviewCard = styled("div", {
  backgroundColor: "$neutral",
  borderRadius: "$16",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$16",
  padding: "$16",
  position: "relative",
});

const StyledImgHeader = styled("div", {
  gap: "$24",
  width: "100%",
  variants: {
    mode: {
      mobile: {
        display: "grid",
      },
      desktop: {
        display: "flex",
      },
    },
  },
});

const StyledImgLayout = styled("div", {
  display: "flex",
  alignItems: "center",
  position: "relative",
  width: 300,
  height: 380,
  "> img": {
    borderRadius: "$8",
    objectFit: "cover",
    width: "100%",
  },
  "> button": {
    width: "100%",
  },
});

const ProductDetailsLayout = styled("div", {
  display: "block",
  width: "100%",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$20",
  lineHeight: "$24",
  color: "$secondary",
  padding: 0,
  margin: 0,
  variants: {
    // mode: {
    //     mobile: {
    //         lineHeight: "$24",
    //     },
    //     desktop: {
    //         lineHeight: "$120",
    //     }
    // },
  },
});

const StyledPrice = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$20",
  // lineHeight: "$22",
  color: "$primary",
  marginTop: "$6",
  padding: 0,
});

const ReviewLayout = styled("div", {
  display: "grid",
  borderWidth: space[1],
  borderStyle: "solid",
  borderColor: "$primary",
  borderRadius: "$8",
  backgroundColor: colors.messages_card_bg_color,
  padding: "$8 $16",
  marginTop: "$6",
  gap: "$2",
});

const ReviewLabel = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$21",
  color: "$secondary",
});

const AverageRating = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$12",
  lineHeight: "$16",
  color: "$primary",
});

const ServiceHeaderLayout = styled("div", {
  display: "grid",
  marginTop: "$16",
  gap: "$4",
  height: "127px",
});

const ServiceReviewHeaderLayout = styled("div", {
  display: "grid",
  marginTop: "$16",
  gap: "$16",
});

const ServiceLayout = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledQuestion = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$14",
  lineHeight: "$24",
});

const StyledValue = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$24",
});

const StyledEmptyLayout = styled("div", {
  display: "flex",
  justifyContent: "center",
  borderRadius: "$16",
  padding: "$16",
  //gap: '$16',
  backgroundColor: colors.messages_card_bg_color,
});

const StyledEmptyHeaderLayout = styled("div", {
  width: "75%",
});
const StyledEmptyState = styled("div", {
  fill: "$secondary",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "$16",
  padding: "$16",
});

const StyledEmptyTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$150",
  color: "$secondary",
  textAlign: "center",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$120",
  color: "$medium",
  textAlign: "center",
  margin: 0,
  padding: 0,
});

const StyledReviewLayout = styled("div", {
  width: "100%",
  borderRadius: "$16",
  padding: "$16",
  gap: "$16",
  backgroundColor: colors.messages_card_bg_color,
});

const StyledPostHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
  width: "100%",
  justifyContent: "space-between",
});

const HeaderColumn = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
});

const AvatarAction = styled("div", {
  display: "flex",
  cursor: "pointer",
});


const StyledInfoHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  Width: "100%",
  justifyContent: "flex-end",
});

const Service = ({ question, value }) => {
  return (
    <ServiceLayout>
      <StyledQuestion>{question}</StyledQuestion>
      <StyledValue>
        <RatingGroup totalText={`${value}`}>
          {Array.from({ length: 5 }).map((_, index) => {
            const position = index + 1;
            let star = value >= position ? "full" : "empty";
            if (star === "empty" && value > position - 1) {
              star = "half";
            }
            return <RatingStar size={"small"} filled={star} />;
          })}
        </RatingGroup>
      </StyledValue>
    </ServiceLayout>
  );
};

export const ProductReviewCard = ({
  checked,
  bookmarkClickAction,
  src,
  alt = "",
  title,
  price,
  rating,
  totalReviews,
  productAverageRating,
  ratingQuestion1,
  ratingQuestion1Value,
  ratingQuestion2,
  ratingQuestion2Value,
  ratingQuestion3,
  ratingQuestion3Value,
  ratingQuestion4,
  ratingQuestion4Value,
  actionButton,
  sticker,
  stickerColor,
  stickerTextColor,
  id,
  style = {},
}) => {
  return (
    <StyledProductReviewCard id={`shopbuzz_${id}`} style={style}>
      <StyledImgHeader
        mode={{
          "@initial": "mobile",
          "@bp3": "desktop",
        }}
      >
        <StyledImgLayout
          mode={{
            "@initial": "mobile",
            "@bp3": "desktop",
          }}
        >
          {/* <StyledBookmark>
                        <Bookmark checked={checked} onClick={bookmarkClickAction} />
                    </StyledBookmark> */}

          <div
            style={{
              position: "relative",
              overflow: "hidden",
              borderRadius: 8,
            }}
          >
            <ProductBadge
              sticker={sticker}
              stickerColor={stickerColor}
              stickerTextColor={stickerTextColor}
            />
            <img
              loading="eager"
              src={src}
              alt={alt}
              style={{ objectFit: "contain", height: "100%", width: "100%" }}
            />
          </div>
        </StyledImgLayout>
        <ProductDetailsLayout>
          <div style={{ display: "flex", gap: 24 }}>
            <StyledTitle
              mode={{
                "@initial": "mobile",
                "@bp3": "desktop",
              }}
            >
              {title}
            </StyledTitle>
            <FavoritesButton checked={checked} onClick={bookmarkClickAction} />
          </div>
          <StyledPrice>{price}</StyledPrice>

          <ReviewLayout>
            {rating}
            <ReviewLabel>{totalReviews} Customer Reviews</ReviewLabel>
            <AverageRating>{productAverageRating} out of 5</AverageRating>
          </ReviewLayout>

          <ServiceHeaderLayout>
            {ratingQuestion1 && ratingQuestion1Value && (
              <Service
                question={ratingQuestion1}
                value={ratingQuestion1Value}
              />
            )}

            {ratingQuestion2 && ratingQuestion1Value && (
              <Service
                question={ratingQuestion2}
                value={ratingQuestion2Value}
              />
            )}

            {ratingQuestion3 && ratingQuestion3Value && (
              <Service
                question={ratingQuestion3}
                value={ratingQuestion3Value}
              />
            )}

            {ratingQuestion4 && ratingQuestion4Value && (
              <Service
                question={ratingQuestion4}
                value={ratingQuestion4Value}
              />
            )}
          </ServiceHeaderLayout>
          {actionButton}
        </ProductDetailsLayout>
      </StyledImgHeader>
    </StyledProductReviewCard>
  );
};

export const EmptyReview = ({
    reviewAddClickAction,
}) => {
    return (
        <StyledEmptyLayout>
            <StyledEmptyHeaderLayout>
                <StyledEmptyState>
                    <SystemIcon icon={'Wallet'} size="large" color={colors.primary} />
                    <StyledEmptyTitle>{'Share your opinion with the community'}</StyledEmptyTitle>
                    <StyledDescription>{`If you have recently purchased this dish, we would like to know your experience. `}</StyledDescription>
                </StyledEmptyState>
                <Layout css={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <IconLabelButton
                        variant={'secondary'}
                        onClick={reviewAddClickAction}
                        label={'Add a Review'}
                    />
                </Layout>
            </StyledEmptyHeaderLayout>
        </StyledEmptyLayout>

    );
};

export const RatingReview = ({
  userInfo,
  reviewDate,
  comments,
  ratingQuestions,
  avatarActionClick,
}) => {
  return (
    <StyledReviewLayout>
      <StyledPostHeader>
        <HeaderColumn>
          <AvatarAction onClick={avatarActionClick}>
            <Avatar src={userInfo?.photoUrl} />
          </AvatarAction>
          <StyledInfoHeader>
            <Typography fontWeight="bold">{userInfo?.displayName}</Typography>
            <Typography color="medium">{reviewDate}</Typography>
          </StyledInfoHeader>
        </HeaderColumn>
      </StyledPostHeader>
      {comments}

      <ServiceReviewHeaderLayout>
        {ratingQuestions.map((ratingQuestion) => {
          let question = ratingQuestion?.ratingQuestion?.question;
          let value = (ratingQuestion?.numberAnswer).toFixed(1);
          return <Service question={question} value={value} />;
        })}
      </ServiceReviewHeaderLayout>
    </StyledReviewLayout>
  );
};
const ExpandReviewDescription = ({ description }) => {
  if (!description) return <div>No review provided</div>;

  return <div>{description}</div>;
};

export const ReviewSwiper = ({ reviews, ratings }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const reviewsAndRatings = ratings.reduce((acc, rating) => {
    const foundReview = reviews.find(
      (review) => review.userInfo.id === rating.userInfo.id
    );

    if (foundReview) {
      acc.push({ review: foundReview, rating });
    }

    return acc;
  }, []);

  const userInfo = useMemo(
    () => reviewsAndRatings[currentIndex].review.userInfo,
    [currentIndex, reviewsAndRatings]
  );

  const { review, rating } = useMemo(
    () => reviewsAndRatings[currentIndex] || { review: null, rating: null },
    [currentIndex, reviewsAndRatings]
  );

  const { isPrevDisabled, isNextDisabled } = useMemo(
    () => ({
      isPrevDisabled: currentIndex === 0,
      isNextDisabled: currentIndex === reviewsAndRatings.length - 1,
    }),
    [currentIndex, reviewsAndRatings.length]
  );

  const addIndex = useCallback(() => setCurrentIndex((prev) => prev + 1), []);
  const reduceIndex = useCallback(
    () => setCurrentIndex((prev) => prev - 1),
    []
  );

  return (
    <StyledReviewLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div style={{ display: "flex", gap: 12 }}>
            <Avatar src={userInfo.photoUrl} alt={userInfo.name || "No name"} />
            <StyledInfoHeader>
              <Typography fontWeight="bold">{userInfo?.displayName}</Typography>
              <Typography color="medium">
                {moment(new Date(review?.createdAt)).fromNow()}
              </Typography>
            </StyledInfoHeader>
          </div>
          <div style={{ marginTop: 12 }}>
            <ExpandReviewDescription description={review?.comment || ""} />
          </div>
          <ServiceReviewHeaderLayout>
            {rating?.ratingQuestions?.map((ratingQuestion) => {
              let question = ratingQuestion?.ratingQuestion?.question;
              let value = (ratingQuestion?.numberAnswer).toFixed(0);
              return (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <StyledQuestion>{question}</StyledQuestion>
                  <div style={{ color: colors.secondary, fontWeight: 600, fontSize: 16 }}>
                    {value}/5 Stars
                  </div>
                </div>
              );
            })}
          </ServiceReviewHeaderLayout>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Button disabled={isPrevDisabled} onClick={reduceIndex}>
            Previous
          </Button>
          <Button disabled={isNextDisabled} onClick={addIndex}>
            Next
          </Button>
        </div>
      </div>
    </StyledReviewLayout>
  );
};

ProductReviewCard.ReviewSwiper = ReviewSwiper;
ProductReviewCard.RatingReview = RatingReview;
ProductReviewCard.EmptyReview = EmptyReview;
ProductReviewCard.displayName = "ProductReviewCard";
