import { styled } from "../../theme";

const StyledPostGrid = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "$8",
  margin: 0,

  variants: {
    size: {
      desktop: {
        width: "65%",
        padding: "0 38px",
      },
      mobile: {
        width: "100%",
      },
    },
  },
});

export const PostGrid = ({ children }) => {
  return (
    <StyledPostGrid
      size={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}
    >
      {children}
    </StyledPostGrid>
  );
};
