import { styled, shadows } from "../../theme";

import { SystemIcon } from "../../foundations";
import { forwardRef } from "react";

const StyledIconButton = styled("button", {
  all: "unset",
  fill: "$neutral",
  border: "none",
  borderRadius: "$96",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  "&::after": {
    display: "none !important",
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: "$primary",
        // "&:hover": {
        //   backgroundColor: "$interactive",
        // },
        "&:disabled": {
          backgroundColor: "$primary",
          opacity: 0.3,
        },
      },
      secondary: {
        backgroundColor: "$secondary",
        // "&:hover": {
        //   backgroundColor: "$high",
        // },
        "&:disabled": {
          backgroundColor: "$secondary",
          opacity: 0.3,
        },
      },
      neutral: {
        fill: "$secondary",
        backgroundColor: "$neutral",
        // "&:hover": {
        //   backgroundColor: "$low",
        // },
        "&:disabled": {
          backgroundColor: "$secondary",
          opacity: 0.3,
        },
      },
    },
    size: {
      small: {
        padding: "$4",
      },
      default: {
        padding: "$8",
      },
      large: {
        padding: "$8",
      },
    },
  },
  compoundVariants: [
    {
      variant: "primary",
      contained: false,
      css: {
        fill: "$primary",
        backgroundColor: "transparent",
        padding: 0,
        // "&:hover": {
        //   fill: "$interactive",
        //   backgroundColor: "transparent",
        // },
        "&:disabled": {
          fill: "$primary",
          backgroundColor: "transparent",
          opacity: 0.3,
        },
      },
    },
    {
      variant: "secondary",
      contained: false,
      css: {
        fill: "$secondary",
        backgroundColor: "transparent",
        padding: 0,
        // "&:hover": {
        //   fill: "$medium",
        //   backgroundColor: "transparent",
        // },
        "&:disabled": {
          fill: "$secondary",
          backgroundColor: "transparent",
          opacity: 0.3,
        },
      },
    },
  ],
  defaultVariants: {
    variant: "primary",
    size: "default",
    contained: true,
  },
});

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param variant - primary | secondary | neutral
 * @param size - default | large
 * @param contained - true | false
 * @param icon - All the possible icons available on https://phosphoricons.com
 *
 * @example
 *
 * return (
 *   <IconButton variant="primary" size="default" icon="Heart" />
 * )
 */
export const IconButton = forwardRef(
  (
    {
      icon = "Question",
      size = "default",
      contained = true,
      ...props
    }: { icon?: string; size?: string; contained?: boolean; variant?: string },
    ref
  ) => {
    return (
      <StyledIconButton {...props} size={size} contained={contained} ref={ref}>
        <SystemIcon icon={icon} size={size} color="inherit" />
      </StyledIconButton>
    );
  }
);

IconButton.displayName = "IconButton";
