import { styled } from "../../theme";
import { SystemIcon } from "../../foundations";

const StyledTitleLayout = styled("div", {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
})


const StyledTitle = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$32",
    lineHeight: "$24",
    margin: 0,
    variants: {
        size: {
            mobile: {
                paddingLeft: '$16',
            },
            desktop: {
                paddingLeft: 0,
            },
        },
    },
});


export const PageTitle = ({ icon, title, iconColor, iconClick }) => {
    return (
        <StyledTitleLayout>
            {icon &&
                <SystemIcon onClick={iconClick} icon={icon} size="large" color={iconColor} />
            }
            <StyledTitle size={{
                "@initial": "mobile",
                "@bp3": "desktop",
            }}>{title}</StyledTitle>
        </StyledTitleLayout>
    );
};

PageTitle.displayName = "PageTitle";