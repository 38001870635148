import { styled } from "../../theme";

const StyledLogoPill = styled("div", {
  backgroundColor: 'transparent',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "$10",
  borderRadius: "$24"
});

/**
 * This component accepts all the image props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param src - The image source url.
 * @param alt - The image alt text.
 *
 * @example
 *
 * return (
 *   <LogoPill src="https://www.google.com/imgres?..." alt="A man..." />
 * )
 */
export const LogoPill = ({ src, alt }) => {
  return (
    <StyledLogoPill>
      <img loading="eager" src={src} alt={alt} style={{  width: '10rem', height: '5rem', objectFit: 'contain' }} />
    </StyledLogoPill>
  );
};

LogoPill.displayName = "LogoPill";
