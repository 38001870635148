import ReactPlayer from "react-player";
import constant from "../../../utilities/constant";
import { Layout, ProductBadge } from "../../components";
import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";
import { colors, space } from "../../tokens";
import { Image } from "react-bootstrap";

const HeaderLayout = styled("div", {
    display: "flex",
    height: 300,
    gap: '$16',
})

const StyledDisplayLayout = styled("div", {
    display:'flex',
    justifyContent:'center',
    width: "100%",
    borderRadius: 8,
    "img ": {
        borderRadius: '$8',
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    variants: {
        mode: {
            mobile: {
                "> img ": {
                    width: '100%',
                },
            },
            desktop: {
                "> img ": {
                    width: '100%',
                },
            }
        },
    },
})

const StyledVideoLayout = styled("div", {
    position: 'relative',
    "> video ": {
        width: 535,
        height: 264,
        borderRadius: '$8',
    },
})

const StyledLayout = styled("div", {
    display: 'flex',
    borderRadius: "$16",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "$8",
});

const StyledLeftFileLayout = styled("div", {
    gap: '$8',
    height: 200,
    overflow: 'scroll',
})

const StyledVerticalImageLayout = styled("div", {

    cursor: "pointer",
    marginTop: '$10',
    "> img ": {
        width: '120px',
        height: '120px',
        borderRadius: '$8',
        objectFit: 'contain',
    },
    "> video ": {
        width: '120px',
        height: '120px',
        borderRadius: '$8',
        objectFit: 'contain',
    },
    variants: {
        active: {
            active: {
                "> img ": {
                    borderWidth: space[2],
                    borderColor: '$primary',
                    borderStyle: 'solid',
                    objectFit: 'contain',
                }
            },
        },
    },
})


export const ShopbuzzImage = ({
    file_type,
    file_url,
    onImageClick,
    active,
}) => {
    return (
        <StyledVerticalImageLayout active={active} onClick={onImageClick}>
            {
                file_type === constant.IMAGE ?
                    <img src={file_url} alt="" />
                    :

                    file_type === constant.ASSET_YOUTUBE ?
                        <ReactPlayer width={40} height={40} url={file_url} light={true} />
                        :
                        <>
                            <video src={file_url} disablePictureInPicture controlsList="nodownload"
                                webkit-playsinline="true" playsInline id="reels-video-player" />
                            <Layout style={{ position: 'absolute', zIndex: '' }}>
                                <i className="far fa-play-circle fa-2x text-white clickable"></i>
                            </Layout>
                        </>
            }
        </StyledVerticalImageLayout>
    );
};

export const ShopbuzzViewImage = ({
    selectedImage,
    onImageIcon,
    upArrowAction,
    downArrowAction,
    sticker,
    stickerColor,
    stickerTextColor,
}) => {
    return (
        <HeaderLayout>
            <StyledLayout>
                <SystemIcon icon={'CaretUp'} color={colors.primary} cursor='pointer' onClick={upArrowAction} />
                <StyledLeftFileLayout>
                    {onImageIcon}
                </StyledLeftFileLayout>
                <SystemIcon icon={'CaretDown'} color={colors.primary} cursor='pointer' onClick={downArrowAction} />
            </StyledLayout>
            <StyledDisplayLayout mode={{
                "@initial": "mobile",
                "@bp3": "desktop",
                height: 264,
            }}>
            <div style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: 8,
            }}>
                <ProductBadge sticker={sticker} stickerColor={stickerColor} stickerTextColor={stickerTextColor} />
                {
                    selectedImage.type === constant.IMAGE ?
                        <Image src={selectedImage.url} alt="" style={{ objectFit: 'contain'}}/>
                        :

                        selectedImage.type === constant.ASSET_YOUTUBE ?
                            <ReactPlayer width={264} height={264} url={selectedImage.url} light={true} />
                            :
                            <StyledVideoLayout>
                                <video src={selectedImage.url} disablePictureInPicture controlsList="nodownload"
                                    webkit-playsinline="true" playsInline id="reels-video-player" controls />
                                {/* <Layout style={{ position: 'absolute', zIndex: '' }}>
                                <i className="far fa-play-circle fa-2x text-white clickable"></i>
                            </Layout> */}
                            </StyledVideoLayout>
                }
                </div>
            </StyledDisplayLayout>
        </HeaderLayout>
    );
};


ShopbuzzViewImage.ShopbuzzImage = ShopbuzzImage;
ShopbuzzViewImage.displayName = "ShopbuzzViewImage";