import styled from "styled-components";
import { useEffect, useMemo } from "react";
import { colors } from "../../../tokens";
import { Checkbox } from "../../../components";

const Layout = styled.div`
  padding: 12px;
  min-width: 400px;
  background-color: ${colors.neutral};
  border-radius: 8px;
  border: 1px solid ${colors.neutral100};
  font-size: 18px;
  overflow: auto;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  min-width: 100px;
  height: 50px;
`;

export const SurveyMatrixQuestion = ({
  question,
  assetsSection,
  answers = [],
  surveyStateOptions = [],
  setInitialOptions = () => {},
  onChange = () => {},
}) => {
  const surveyOptions = useMemo(
    () =>
      (question?.surveyOptions || []).map((option, index) => ({
        ...option,
        subOptions: (
          option.subOptions?.sort(
            (sub1, sub2) => sub1.position - sub2.position
          ) || []
        ).map((item) => ({
          ...item,
          selected: (answers[index] || []).includes(item.id),
        })),
      })),
    [answers, question?.surveyOptions]
  );

  const colLabels = useMemo(
    () =>
      surveyOptions.length
        ? ["", ...surveyOptions[0].subOptions.map((option) => option.text)]
        : [],
    [surveyOptions]
  );

  const handleChangeAnswer = (array = [], id, isSingleSelection = false) => {
    if (array.includes(id)) {
      return array.filter((item) => item !== id);
    }

    return isSingleSelection ? [id] : [...array, id];
  };

  const handleChange = (id) => {
    const arrayIndex = surveyOptions.findIndex((opt) =>
      opt.subOptions.map((subOpt) => subOpt.id).includes(id)
    );

    if (~arrayIndex) {
      const answersClone = structuredClone(answers);

      const isMultipleSelection =
        surveyOptions[arrayIndex].selectionType === "MULTIPLE_SELECTION";

      answersClone[arrayIndex] = handleChangeAnswer(
        answersClone[arrayIndex],
        id,
        !isMultipleSelection
      );

      onChange(answersClone);
    }
  };

  useEffect(
    () => !surveyStateOptions.length && setInitialOptions(surveyOptions),
    [setInitialOptions, surveyOptions, surveyStateOptions.length]
  );

  return (
    <Layout>
      {assetsSection}
      <div style={{ display: "flex" }}>
        {colLabels.map((label, index) => (
          <Label key={index}>{label}</Label>
        ))}
      </div>
      {surveyOptions.map((option) => (
        <Option
          key={option.id}
          option={option}
          handleChange={handleChange}
          answers={answers}
        />
      ))}
    </Layout>
  );
};

const Option = ({ option, handleChange = () => {}, answers = [] }) => {
  return (
    <div style={{ display: "flex" }} key={option.id}>
      <Label>{option.text}</Label>
      {option.subOptions.map((subOption) => (
        <Label key={subOption.id}>
          <Checkbox
            checked={subOption.selected}
            onCheckedChange={() => handleChange(subOption.id)}
          />
        </Label>
      ))}
    </div>
  );
};
