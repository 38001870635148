import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import styled, { css } from "styled-components";
import { Image } from "react-bootstrap";
import { colors } from "../../../tokens";
import { useEffect, useRef } from "react";
import { SystemIcon } from "../../../foundations";

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  background-color: ${colors.neutral};
  border-radius: 8px;

  ${({ opacity = 1 }) => css`
    opacity: ${opacity};
  `}
`;

const CircleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${colors.primary};

  user-select: none;

  cursor: pointer;
`;

export const SequenceQuizQuestion = ({
  question,
  answer = [],
  onChange = () => {},
}) => {
  useEffect(() => {
    if (!answer.length && question?.quizQuestionOptions?.length) {

      onChange(question.quizQuestionOptions);
    }
  }, [answer.length, onChange, question.quizQuestionOptions]);

  const moveCard = (dragIndex, hoverIndex) => {
    onChange(
      (() => {
        const draggedCard = answer[dragIndex];
        const newCards = [...answer];

        newCards.splice(dragIndex, 1);

        newCards.splice(hoverIndex, 0, draggedCard);

        return newCards;
      })()
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <div style={{ fontSize: 24, fontWeight: 600 }}>{question.text}</div>
      {!!question.assets.length && (
        <Image
          style={{
            width: "100%",
            height: "200px",
            objectFit: "contain",
            borderRadius: 8,
          }}
          src={question.assets[0].url}
        />
      )}
      <DndItems options={answer} moveCard={moveCard} />
    </div>
  );
};

const DndItems = ({ options, moveCard }) => {
  if (!options.length) return <div>No options</div>;

  return (
    <DndProvider backend={HTML5Backend}>
      {options.map((option, index) => (
        <DndItem
          key={option.id}
          option={option}
          moveCard={moveCard}
          id={option.id}
          index={index}
        />
      ))}
    </DndProvider>
  );
};

const DndItem = ({ option, index, moveCard, id }) => {
  const ref = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    type: "option",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: "option",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const handleUp = () => moveCard(index, index - 1);
  const handleDown = () => moveCard(index, index + 1);

  const opacity = isDragging ? 0 : 1;

  drop(preview(ref));

  return (
    <ItemWrapper opacity={opacity} ref={ref} data-handler-id={handlerId}>
      <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
        <div ref={drag} style={{ cursor: isDragging ? "grabbing" : "grab" }}>
          <SystemIcon icon="DotsSix" />
        </div>
        {!!option.imageAsset && (
          <Image
            style={{
              objectFit: "contain",
              borderRadius: 8,
            }}
            height={48}
            width={48}
            src={option.imageAsset.url}
          />
        )}
        <div style={{ fontSize: 18, fontWeight: 600 }}>{option.text}</div>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
        <CircleButton onClick={handleUp}>
          <SystemIcon icon="CaretUp" size="small" color={colors.neutral} />
        </CircleButton>
        <CircleButton onClick={handleDown}>
          <SystemIcon icon="CaretDown" size="small" color={colors.neutral} />
        </CircleButton>
      </div>
    </ItemWrapper>
  );
};
