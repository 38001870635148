import React, { forwardRef } from "react";
import * as IconBase from "@phosphor-icons/react";

import { colors } from "../../tokens";

const systemIconSizeMap = {
  small: "1rem",
  default: "1.5rem",
  large: "2rem",
  extraLarge: "3.5rem",
};

/**
 * This component accepts only internal props:
 *
 * @param icon - All the possible icons available on https://phosphoricons.com
 * @param size - default | small | large | extraLarge
 * @param weight - regular | bold | fill | duotone
 * @param color - Any color hex but use colors tokens when possible
 *
 * @example
 *
 * return (
 *   <SystemIcon icon="Heart" size="default" />
 * )
 */
export const SystemIcon = forwardRef(
  (
    {
      icon = "Question",
      size = "default",
      weight = "regular",
      color = colors.secondary,
      ...props
    },
    ref
  ) => {
    const Icon = IconBase[icon];

    return (
      <Icon
        ref={ref}
        size={typeof size === "string" ? systemIconSizeMap[size] : size}
        color={color}
        weight={weight}
        overflow="visible"
        {...props}
      />
    );
  }
);
