const eventconfig = {
	CONTEST_LIKED : "CONTEST_LIKED",
	QUIZ_LIKED : "QUIZ_LIKED", 
	REFER_CODE_FROM : "REFER_CODE_FROM", 
	QUIZ_COMPLETED : "QUIZ_COMPLETED",
	ENTRY_COMMENT_ADDED : "ENTRY_COMMENT_ADDED", 
	QUIZ_STARTED : "QUIZ_STARTED", 
	PROFILE_FOLLOW_FROM : "PROFILE_FOLLOW_FROM", 
	BRANDED_POST_COMMENT_ADDED : "BRANDED_POST_COMMENT_ADDED", 
	COMMENT_LIKED : "COMMENT_LIKED", 
	TAG_ADDED : "TAG_ADDED", 
	VOUCHER_PURCHASED : "VOUCHER_PURCHASED", 
	SURVEY_COMPLETED : "SURVEY_COMPLETED",
	PRODUCT_REVIEWED : "PRODUCT_REVIEWED",
	ENTRY_ADDED : "ENTRY_ADDED", 
	REFER_CODE_TO : "REFER_CODE_TO", 
	POST_ADDED : "POST_ADDED",
	PRODUCT_REVIEW_REJECTED : "PRODUCT_REVIEW_REJECTED", 
	PROFILE_FRIEND_FROM : "PROFILE_FRIEND_FROM", 
	POST_LIKED : "POST_LIKED",
	PRODUCT_REVIEW_APPROVED : "PRODUCT_REVIEW_APPROVED",
	CHAT_ROOM_MESSAGE_RECEIVED : "CHAT_ROOM_MESSAGE_RECEIVED", 
	SURVEY_STARTED : "SURVEY_STARTED",
	COMMENTED_SOMETHING : "COMMENTED_SOMETHING", 
	PROFILE_FOLLOW_TO : "PROFILE_FOLLOW_TO", 
	AFFILIATE_COMMISSION_PAYED : "AFFILIATE_COMMISSION_PAYED", 
	CHAT_ROOM_MESSAGE_POSTED : "CHAT_ROOM_MESSAGE_POSTED", 
	CHAT_ROOM_JOINED : "CHAT_ROOM_JOINED",
	QUIZ_COMPLETED_WRONG: "QUIZ_COMPLETED_WRONG",
	QUIZ_ANSWER_SUBMITTED_WRONG: "QUIZ_ANSWER_SUBMITTED_WRONG",
	QUIZ_COMPLETED_CORRECT: "QUIZ_COMPLETED_CORRECT",
	QUIZ_ANSWER_SUBMITTED_CORRECT: "QUIZ_ANSWER_SUBMITTED_CORRECT"
};
export default eventconfig;