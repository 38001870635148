import { fonts } from "../../tokens";

export const ProductBadge = ({ sticker, stickerColor, stickerTextColor }) => {
  return sticker && stickerTextColor && stickerColor ? (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        width: 150,
        height: 120,
        borderRadius: 8,
        transform: "rotate(-45deg)",
        padding: "4px 12px",
        textAlign: "center",
        color: `#${stickerTextColor}`,
        backgroundColor: `#${stickerColor}`,
        fontSize: 16,
        fontWeight: 600,
        top: "-51px",
        left: "-65px",
        fontFamily: fonts.roboto,
        boxShadow: "0 0 3px 1px rgba(0,0,0,0.8)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          width: "min-content",
          height: 51,
        }}
      >
        {sticker}
      </div>
    </div>
  ) : null;
};
