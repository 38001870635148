import * as Separator from "@radix-ui/react-separator";

import { styled } from "../../theme";

/**
 * This component accepts all the image props extended by the @radix-ui/react-separator.
 *
 * @param orientation - horizontal | vertical
 *
 * @example
 *
 * return (
 *   <Divider orientation="horizontal" />
 * )
 */
export const Divider = styled(Separator.Root, {
  backgroundColor: "$low",
  "&[data-orientation=horizontal]": { height: "$1", width: "100%" },
  "&[data-orientation=vertical]": { height: "100%", width: "$1" },
});
