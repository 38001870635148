import { styled } from "../../theme";

export const Layout = styled("div", {
  display: "flex",
  variants: {
    gap: {
      xs: { gap: "0.25rem" },
      sm: { gap: "0.5rem" },
      md: { gap: "1rem" },
      lg: { gap: "1.5rem" },
      xl: { gap: "2rem" },
    },
    borderRadius: {
      none: { borderRadius: "0" },
      xs: { borderRadius: "0.25rem" },
      sm: { borderRadius: "0.5rem" },
      md: { borderRadius: "1rem" },
      lg: { borderRadius: "1.5rem" },
      xl: { borderRadius: "2rem" },
    },
    border: {
      none: { border: "0" },
      subtle: { border: "1px solid $low" },
    },
    backgroundColor: {
      none: { backgroundColor: "transparent" },
      neutral: { backgroundColor: "$neutral" },
      subtle: { backgroundColor: "$tulip10" },
    },
  },

  defaultVariants: {
    gap: "sm",
    borderRadius: "none",
    border: "none",
    backgroundColor: "none",
  },
});
