import { styled, shadows } from "../../theme";
import { space } from "../../tokens";

import { SystemIcon } from "../../foundations";

const StyledInputSearch = styled("input", {
  color: "$secondary",
  backgroundColor: "$neutral",
  borderRadius: "$8",
  borderWidth: space["1"],
  borderColor: "$neutral50",
  borderStyle: "solid",
  boxSizing: "border-box",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$150",
  paddingLeft: "$36",
  paddingRight: "$16",
  paddingTop: "$8",
  paddingBottom: "$8",
  width: "101px",
  minWidth: "101px",
  "+ div": {
    fill: "$medium",
  },
  "&:hover": {
    borderColor: "$primary",
    "+ div": {
      fill: "$primary",
    },
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  "&::placeholder": {
    color: "$secondary",
  },
});

const StyledContainer = styled("div", {
  position: "relative",
  width: "101px",
  minWidth: "101px",
});

const StyledIcon = styled("div", {
  position: "absolute",
  top: "$6",
  left: "$12",
  pointerEvents: "none",
  userSelect: "none",
});

/**
 * This component accepts all the input props extended by the HTML5.
 *
 * @example
 *
 * return (
 *   <InputSearch name="cars" id="cars" value="Any value..." />
 * )
 */
export const InputSearch = (props) => {
  return (
    <StyledContainer>
      <StyledInputSearch {...props} placeholder="Search" />
      <StyledIcon>
        <SystemIcon
          icon="MagnifyingGlass"
          size="small"
          color="inherit"
          weight="bold"
        />
      </StyledIcon>
    </StyledContainer>
  );
};

InputSearch.displayName = "InputSearch";
