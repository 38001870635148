import { withMask } from "use-mask-input";

import { styled, shadows } from "../../theme";
import { space } from "../../tokens";

import { Layout } from "../layout/layout";

const StyledDatePicker = styled("input", {
  color: "$secondary",
  backgroundColor: "$neutral",
  borderRadius: "$8",
  borderWidth: space["1"],
  borderColor: "$neutral50",
  borderStyle: "solid",
  boxSizing: "border-box",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$150",
  paddingLeft: "$16",
  paddingRight: "$8",
  paddingTop: "$8",
  paddingBottom: "$8",
  width: "100%",
  "+ div": {
    fill: "$medium",
  },
  "&:hover": {
    borderColor: "$primary",
    "&::placeholder": {
      color: "$medium",
    },
    "+ div": {
      fill: "$primary",
    },
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  "&::placeholder": {
    color: "$high",
  },
});

const StyledContainer = styled("div", {
  position: "relative",
  width: "100%",
  //minWidth: "250px",
});

const StyledLabel = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$24",
  marginBottom: '$8',
});

/**
 * This component accepts all the select props extended by the HTML5.
 *
 * @example
 *
 * return (
 *   <DatePicker value="04/15/2020" />
 * )
 */
export const DatePicker = ({type='date', ...props}) => {
  return (
    <Layout css={{
      display: 'block',
      width: '100%',
    }}>
      {props.label &&
        <StyledLabel>{props.label}</StyledLabel>
      }
      <StyledContainer>

        <StyledDatePicker
          {...props}
          type={type}
          ref={withMask("99/99/9999", {
            inputType: "number",
            clearMaskOnLostFocus: true,
            clearIncomplete: true,
            positionCaretOnClick: true,
          })}
          placeholder="mm/dd/yyyy"
        />
        {/* <StyledIcon>
          <SystemIcon
            icon="CalendarBlank"
            size="small"
            color="inherit"
            weight="bold"
          />
        </StyledIcon> */}
      </StyledContainer>
    </Layout>
  );
};

DatePicker.displayName = "DatePicker";
