import { styled, shadows } from "../../theme";

import { SystemIcon } from "../../foundations";

const StyledRatingStar = styled("div", {
  fill: "$primary",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "$24",
  height: "$24",
  "&:focus": {
    boxShadow: shadows.focus,
  },
  variants: {
    disabled: {
      true: {
        fill: "$medium",
      },
    },
  },
});

const filledIconMap = {
  half: "StarHalf",
  empty: "Star",
  full: "Star",
};

const weightIconMap = {
  half: "fill",
  empty: "regular",
  full: "fill",
};

/**
 * This component accepts all the div props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param filled - half | full | empty
 * @param disabled - true | false
 *
 * @example
 *
 * return (
 *   <RatingStar filled="half" disabled={false} />
 * )
 */
export const RatingStar = ({ filled, size="default", ...props }) => {
  return (
    <StyledRatingStar {...props} filled={filled}>
      <SystemIcon
        icon={filledIconMap[filled]}
        size={size}
        weight={weightIconMap[filled]}
        color="inherit"
      />
    </StyledRatingStar>
  );
};

RatingStar.displayName = "RatingStar";
