import { RadioGroup } from "../../..";
import { styled } from "../../../theme";
import { ChoiceImages } from "./choice-section-images";
import { ChoiceText } from "./choice-section-text";
import { useMemo } from "react";
import { store } from "../../../../store";

const StyledLayout = styled("div", {
  displayName: "StyledLayout",
  backgroundColor: "$neutral",
  color: "$secondary",
  display: "block",
  borderRadius: "$16",
  marginTop: "$8",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$8",
  position: "relative",
});

const StyledQuestion = styled("p", {
  displayName: "StyledQuestion",
  backgroundColor: "$neutral",
  color: "$secondary",
  fontFamily: "$roboto",
  fontSize: "$20",
  fontWeight: "600",
  lineHeight: "$32",
  margin: 0,
  paddingBottom: 16,
});

export const SurveySingleChoice = ({
  question,
  assets,
  onChange,
  changeOtherOption,
  value,
}) => {
  const isQuestionWithAssets = useMemo(
    () => question.surveyOptions.some((opt) => !!opt.imageAsset),
    [question]
  );

  const {theme} = store.getState() 
 
  return (
    <StyledLayout className="styled-layout-panel">
      <StyledQuestion css={{color: theme.secondary}}>{question.text}</StyledQuestion>
      {assets}
      <RadioGroup defaultValue="val" onValueChange={onChange}>
        {isQuestionWithAssets ? (
          <ChoiceImages
            value={value}
            options={question.surveyOptions}
            onChangeFreeText={changeOtherOption}
            single
          />
        ) : (
          <ChoiceText
            value={value}
            options={question.surveyOptions}
            onChangeFreeText={changeOtherOption}
            single
          />
        )}
      </RadioGroup>
    </StyledLayout>
  );
};

SurveySingleChoice.displayName = "SurveySingleChoice";
