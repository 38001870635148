import * as CheckboxBase from "@radix-ui/react-checkbox";

import { SystemIcon } from "../../foundations";

import { shadows, styled } from "../../theme";
import { space } from "../../tokens";

const StyledCheckbox = styled("div", {
  display: "flex",
  alignItems: "center",
});
const StyledCheckboxRoot = styled(CheckboxBase.Root, {
  fill: "$neutral",
  backgroundColor: "$neutral",
  borderRadius: "$4",
  borderWidth: space["2"],
  borderColor: "$low",
  borderStyle: "solid",
  padding: "$0",
  width: "$24",
  height: "$24",
  overflow: "hidden",
  "&:hover": {
    borderColor: "$primary",
  },
  "&:focus": {
    outline: "none",
    boxShadow: shadows.focus,
  },
  "&[aria-checked=true]": {
    backgroundColor: "$primary",
  },
});

const StyledCheckboxIndicator = styled(CheckboxBase.Indicator, {
  fill: "$neutral",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

const StyledCheckboxLabel = styled("p", {
  color: "Black",
  fill: "$neutral",
  display: "flex",
  alignItems: "center",
  //width: "100%",
  height: "100%",
  paddingLeft: "$6",
  margin: 0,
});

/**
 * This component accepts all the image props extended by the `@radix-ui/react-checkbox`.
 *
 * @param checked - true | false
 * @param name - The checkbox name.
 * @param value - The valur for the checkbox.
 * @param onCheckedChange - The onChange function receives the value when the value change.
 *
 * @example
 *
 * return (
 *   <Checkbox checked={false} name="vendor-1" value="Apple" onCheckedChange={...} />
 * )
 */
export const Checkbox = ({ wrapperStyle = {}, ...restProps }) => {
  return (
    <StyledCheckbox style={wrapperStyle}>
      <StyledCheckboxRoot {...restProps}>
        <StyledCheckboxIndicator>
          <SystemIcon
            icon="Check"
            size="small"
            weight="regular"
            color="inherit"
          />
        </StyledCheckboxIndicator>
      </StyledCheckboxRoot>
      <StyledCheckboxLabel>{restProps.name}</StyledCheckboxLabel>
    </StyledCheckbox>
  );
};

Checkbox.displayName = "Checkbox";
