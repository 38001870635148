import { styled } from "../../theme";
import { space } from "../../tokens";
import { Button, Layout } from "../../components";
import no_media from "../../../assets/images/no_media_image.png";
import { SystemIcon } from "../../foundations";

const StyledCard = styled("div", {
    backgroundColor: "$neutral",
    borderRadius: "$16",
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    display: "flex",
    marginTop: '$8',
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$16",
    position: "relative",
    "> img": {
        borderRadius: "$8",
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },

    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "23%",
            }
        },
    },
});

const StyledLayout = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$4",
    padding: "$16",
    paddingTop: 0,
    width: "100%",
});

const StyledTitle = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$20",
    lineHeight: "$24",
    color: "$secondary",
    margin: 0,
    padding: 0,
});

const StyledName = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$14",
    lineHeight: "$24",
    color: "$secondary",
    margin: 0,
    padding: 0,
});

const StyledParticipateCount = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$16",
    lineHeight: "$24",
    color: 'Gray',
    margin: 0,
    padding: 0,
});

const StyledTopics = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$16",
    lineHeight: "$24",
    margin: 0,
    padding: 0,
    marginLeft: '$4'
});

const StyledAvatarLayout = styled("div", {
    paddingLeft: '25px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '$8',
    "> img": {
        borderRadius: '50%',
        position: 'relative',
        left: "-15px",
        marginLeft: '-10px',
        zIndex: 1
    }
});

const StyledTopicsLayout = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '$8'
});

export const GroupCard = ({
    isFirstCard,
    src,
    alt = '',
    title,
    group,
    onClickAction,
    isDiscover = false,
}) => {
    return (
        <StyledCard mode={{
            "@initial": "mobile",
            "@bp3": "desktop",
        }} css={{ marginLeft: isFirstCard ? 0 : '$8' }}>
            <img loading='eager' style={{ objectFit: src ? 'cover' : 'contain' }} src={src ? src : no_media} alt={alt} width='100%' height={170} />
            <StyledLayout>
                <StyledTitle>{title}</StyledTitle>
                <StyledName>{group?.description}</StyledName>
                <StyledAvatarLayout>
                    {
                        group?.participants?.map((item, index) => {
                            return (
                                <img src={item?.userInfo?.photoUrl} style={{ height: '30px', width: '30px', resize: 'contain', border: '2px solid white' }} alt="" />
                            )

                        })
                    }
                    <StyledParticipateCount>{`${group?.participants?.length} members`}</StyledParticipateCount>
                </StyledAvatarLayout>
                <StyledTopicsLayout>
                    <SystemIcon icon="ChatTeardropDots" weight="regular" />
                    <StyledTopics>{`${group?.totalMessages ? group?.totalMessages : 0} topics`}</StyledTopics>
                </StyledTopicsLayout>
                <Layout css={{
                    width: '100%',
                    marginTop: '$16',
                    gap: '$8',
                }}>
                    <Button style={{ width: '100%' }} variant={isDiscover ? 'primary':'neutral'} onClick={onClickAction}>{`${isDiscover ? 'Join Group': 'View Group'}`}</Button>
                </Layout>
            </StyledLayout>
        </StyledCard>
    );
};

GroupCard.displayName = "GroupCard";
