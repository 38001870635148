import { styled, shadows } from "../../theme";

import { SystemIcon } from "../../foundations";

const StyledBookmark = styled("button", {
  all: "unset",
  fill: "$neutral",
  backgroundColor: "transparent",
  border: "none",
  borderRadius: "$96",
  boxSizing: "border-box",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "$24",
  height: "$24",
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  "&:hover": {
    fill: "$red",
  },
  "&[data-checked=true]": {
    fill: "$red",
    "&:hover": {
      opacity: 0.6,
    },
  },
  defaultVariants: {
    checked: false,
  },
});

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param checked - true | false
 *
 * @example
 *
 * return (
 *   <Bookmark checked={true} />
 * )
 */
export const Bookmark = ({ checked, ...props }) => {
  return (
    <StyledBookmark {...props} checked={checked} data-checked={checked}>
      <SystemIcon
        icon="BookmarkSimple"
        size="default"
        weight={checked ? "fill" : "regular"}
        color="inherit"
      />
    </StyledBookmark>
  );
};

Bookmark.displayName = "Bookmark";
