import { styled } from "../../theme";

const StyledBanner = styled("div", {
  backgroundColor: "$tulip10",
  borderRadius: "$16",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "$16",
  padding: "$24",
  width: "fit-content",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$20",
  lineHeight: "$150",
  color: "$secondary",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$500",
  fontSize: "$14",
  lineHeight: "$120",
  color: "$secondary",
  margin: 0,
  padding: 0,
  width: "100%",
});

/**
 * This component accepts only internal props
 *
 * @param children - A React element that can be string or anything.
 * @param title - The empty state title.
 * @param description - The empty state title.
 *
 * @example
 *
 * return (
 *   <Banner
 *       title="Title"
 *       description="Description"
 *     >
 *       <Button>Click Here</Button>
 *   </Banner>
 * )
 */
export const Banner = ({ children, title, description }) => {
  return (
    <StyledBanner>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      {children}
    </StyledBanner>
  );
};

Banner.displayName = "Banner";
