import { isMobile } from "react-device-detect";
import { styled } from "../../theme";
import { PreviewUserFooter, PreviewUserHeader } from "../../domain-components/promoted-create-item/promoted-create-item";
import { Layout, Typography } from "../../components";

const StyledPreview = styled("div", {
  display: 'flex',
  margin: 0,
  paddingBlock: '$24',
  height: 700,
  backgroundColor: 'transparent',
  position: 'relative',
});

const StyledPostContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
});

export const PromotionViewItem = ({
  id = null,
  showCloseIcon = false,
  closeonClick = null,
  file,
  userAvatar,
  username,
  followlabel,
  followOnClick,
  currnetUsersPost,
  description,
  videoRef,
  videoPlaying,
  playClickAction,
  pauseClickAction,
  muted,
  muteVideoClickAction,
  unMuteVideoClickAction,
  previewAction,
  showAction = false }) => {
  return (
    <StyledPreview id={`promotedcontent_${id}`}>
      <Layout css={{
        width: '100%',
        height: '100%',
        backgroundColor: '#00000020',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        "> video": {
          width: '400px',
          borderRadius: '$8',
          background: 'transparent',
        },
      }}>
        <video src={file.src} ref={videoRef} alt='' muted={muted} />
        <PreviewUserHeader
          showCloseIcon={showCloseIcon}
          closeonClick={closeonClick}
          userAvatar={userAvatar}
          username={username}
          currnetUsersPost={currnetUsersPost}
          followlabel={followlabel}
          followOnClick={followOnClick}
          videoPlaying={videoPlaying}
          playClickAction={playClickAction}
          pauseClickAction={pauseClickAction}
          muteVideoClickAction={muteVideoClickAction}
          unMuteVideoClickAction={unMuteVideoClickAction}
          muted={muted}
        />
        {(description || isMobile) &&
          <PreviewUserFooter
            description={description}
          >{isMobile &&
            <>{previewAction}</>
            }

          </PreviewUserFooter>
        }
      </Layout>
      {showAction && !isMobile &&
        <>
          {previewAction}
        </>
      }
    </StyledPreview>
  );
};

export const BlogCardContent = ({
  postContentTitle,
  postContent,
  postFeaturedMedia,
}) => {
  return (
    <StyledPostContent>
      <Typography fontWeight='bold'>
        {postContentTitle}
      </Typography>
      <Typography variant='body' color='medium'>
        {postContent}
      </Typography>
      {postFeaturedMedia}
    </StyledPostContent>
  );
};

PromotionViewItem.BlogCardContent = BlogCardContent;
PromotionViewItem.displayName = "PromotionViewItem";