import { Layout, Typography } from "../../../components";
import { SystemIcon } from "../../../foundations";
import { styled } from "../../../theme";
import { space } from "../../../tokens";
const StyledLayout = styled("div", {
    display: 'grid',
    borderRadius: "$16",
    marginTop: '$8',
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "$8",
    position: "relative",
});

const StyledInput = styled("input", {
    width: '100%',
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    height: "$40",
    gap: '$10',
    padding: '$8 $16',
    borderWidth: space[2],
    borderStyle: "solid",
    borderColor: '$low',
    borderRadius: '$8',
    margin: 0,
});

export const PollInput = ({
    label,
    onChange,
    inputValue,
    placeholder = '',
    deleteIcon,
    deleteIconAction,
}) => {
    return (
        <StyledLayout>
            {label &&
                <Typography variant={'bodySmall'} fontWeight={'bold'}>{label}</Typography>
            }
            <Layout css={{
                alignItems: 'center',
            }}>
                <StyledInput value={inputValue} onChange={onChange} placeholder={placeholder} />
                {deleteIcon &&
                    <SystemIcon onClick={deleteIconAction} icon={deleteIcon} size='default' color='inherit' />
                }
            </Layout>
        </StyledLayout>
    );
};

PollInput.displayName = "PollInput";