import { styled } from "../../theme";

const StyledWidgetBar = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  background: "$transparent",
  alignItems: "flex-start",
  gap: "24px",
  flexShrink: "0",
  //position: "absolute",
  top: "0",
  right: "0",
  overflow: "auto",
  //zIndex: "99",
  width: "35%",
  variants: {
    mode: {
      mobile: {
        width: "100%",
        padding: "$24",
      },
      desktop: {
        width: "35%",
        maxHeight: "calc(100vh - 7rem)",
        paddingRight: "$24",
      },
    },
  },
});

export const WidgetBar = ({ children }) => {
  return (
    <StyledWidgetBar
      mode={{
        "@initial": "desktop",
        "@bp3": "desktop",
      }}
    >
      {children}
    </StyledWidgetBar>
  );
};
