import { Avatar, Button, Chip, Typography } from "../../components";
import { styled } from "../../theme";
import { space } from "../../tokens";

//Full Card Layout
const StyledPostCard = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "$16",
    gap: "$24",
    justifyContent: "start",
    width: "100%",
    backgroundColor: "$neutral",
    borderRadius: "$16",
    borderWidth: space["1"],
    borderColor: "$low",
    // borderStyle: "solid",
});

//Card Header Styles
const StyledPostHeader = styled("div", {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "$16",
    width: "100%",
    justifyContent: "space-between",
});

const HeaderColumn = styled("div", {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "$16",
});

const StyledInfoHeader = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    Width: "100%",
    justifyContent: "flex-end",
});

//Post Content Container
const StyledPostContent = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
});


//Post Interaction Containers
const StyledSocialHolder = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.5rem",
    alignSelf: "stretch",
});

const ActionBelt = styled("div", {
    display: "flex",
    alignItems: "flex-start",
    gap: "1.5rem",
});

const StyledInteractionCount = styled("div", {
    display: "flex",
    padding: "0.25rem 0rem",
    justifyContent: "space-between",
    gap: "0.625rem",
    alignSelf: "stretch",
});

const TextBelt = styled("div", {
    display: "flex",
    alignItems: "flex-start",
    gap: "0.625rem",
});


const AvatarAction = styled("div", {
    display: "flex",
    cursor: 'pointer'
});

const StyledTimerLayout = styled("div", {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '$8',
    width: '100%',
    padding: '$16 $8',
    borderWidth: space["1"],
    borderColor: "$low",
    // borderStyle: "solid",
    gap: '$16'
});

const StyledTimeLayout = styled("div", {
    display: "flex",
    alignItems: 'center',
    gap: '$16'
});

const StyledTime = styled("div", {
    display: "grid",
});

const TimeTypography = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$18",
    lineHeight: "$32",
    textAlign: 'center',
});

const TimeLabelTypography = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$10",
    lineHeight: "$16",
    textAlign: 'center',
});

const StyledIndicator = styled("div", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$24",
    lineHeight: "$32",
    textAlign: 'center',
});

const SocialHolder = ({ children, likeCount, shareCount, commentCount }) => {
    return (
        <StyledSocialHolder>
            <ActionBelt>{children}</ActionBelt>
            <StyledInteractionCount>
                <Typography variant='body' fontWeight='bold'>
                    {likeCount} likes
                </Typography>
                <TextBelt>
                    <Typography variant='body'>{shareCount} shares</Typography>
                    <Typography variant='body'>{commentCount} comments</Typography>
                </TextBelt>
            </StyledInteractionCount>
        </StyledSocialHolder>
    );
};

//Post Header - User Information
export const PollsHeader = ({
    username,
    postDate,
    userAvatar,
    onClickFollowInteraction,
    followLabel,
    loggedIn,
    dropdownContent,
    isOwner,
    avatarClickAction,
}) => {
    return (
        <StyledPostHeader>
            <HeaderColumn>
                <AvatarAction onClick={avatarClickAction}>
                    <Avatar src={userAvatar} />
                </AvatarAction>
                <StyledInfoHeader>
                    <Typography fontWeight='bold'>{username}</Typography>
                    <Typography color='medium'>{postDate}</Typography>
                </StyledInfoHeader>
            </HeaderColumn>
            <HeaderColumn>
                {loggedIn ? (
                    <>
                        {!isOwner &&
                            <Chip icon='Plus' onClick={onClickFollowInteraction}>
                                {followLabel}
                            </Chip>
                        }
                        {dropdownContent}
                    </>
                ) : null}
            </HeaderColumn>
        </StyledPostHeader>
    );
};

export const PollsCardContent = ({
    postContentTitle,
    postContent,
    postFeaturedMedia,
    textFeature,
    timer,
    voteAction,
    voted = false,
    buttonVisibility = false,
}) => {
    return (
        <StyledPostContent>
            <Typography variant='body' fontWeight='bold'>
                {postContentTitle}
            </Typography>
            {postContent &&
                <Typography variant='body' color='medium'>
                    {postContent}
                </Typography>
            }
            {textFeature}
            {postFeaturedMedia}

            <StyledTimerLayout>
                {voted ? '' : timer}
                <Button size={'small'} variant={'primary'} disabled={buttonVisibility} onClick={voteAction} alignSelf='right'>
                    {voted ? 'Voted' : 'Vote now'}
                </Button>
            </StyledTimerLayout>

        </StyledPostContent>
    );
};

export const Time = ({
    label,
    value,
    indicator,
}) => {
    return (
        <StyledTimeLayout>
            <StyledTime>
                <TimeTypography>{value}</TimeTypography>
                <TimeLabelTypography>{label}</TimeLabelTypography>
            </StyledTime>
            {indicator &&
                <StyledIndicator>:</StyledIndicator>
            }
        </StyledTimeLayout>
    );
};

//Full Post Component

export const PollsCard = ({ id, children }) => {
    return <StyledPostCard id={`poll_${id}`}>{children}</StyledPostCard>;
};

PollsCard.Time = Time;
PollsCard.SocialHolder = SocialHolder;
PollsCard.PollsHeader = PollsHeader;
PollsCard.PollsCardContent = PollsCardContent;
PollsCard.DisplayName = "PollsCard";