import { styled } from "../../theme";
import { colors, space } from "../../tokens";
import { Typography, Button } from "../../components";
import { SystemIcon } from "../../foundations";

const StyledCard = styled("div", {
    backgroundColor: "$neutral",
    display: "flex",
    marginTop: '$8',
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    "> img": {
        borderRadius: "$8"
    },

    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "100%",
                margin: 0,
            }
        },
    },
});

const StyledLeaderList = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '$16',
    gap: '$8',
    borderRadius: '$8',
    marginTop: '$10',
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    backgroundColor: "$neutral",
})

export const GroupChatCard = ({
    chatClickAction
}) => {
    return (
        <>
            <StyledCard mode={{
                "@initial": "mobile",
                "@bp3": "desktop",
            }}>
                <StyledLeaderList>
                    <SystemIcon icon='ChatCircleText' color={colors.primary} size={'large'}></SystemIcon>
                    <Typography variant={'body'} fontWeight={'bold'}>{"Group Chat"}</Typography>
                    <Typography variant={'bodySmall'}>{"Join the members in a live chat"}</Typography>
                    <Button onClick={chatClickAction} variant={'secondary'} style={{ width: '35%'}}>{'Go to chat'}</Button>
                </StyledLeaderList>
            </StyledCard>
        </>

    );
};

GroupChatCard.displayName = "GroupChatCard";
