import { styled, shadows } from "../../theme";
import { colors, space } from "../../tokens";

import { SystemIcon } from "../../foundations";
import { PropsWithChildren, ReactNode, forwardRef } from "react";

const StyledChip = styled("button", {
  all: "unset",
  fill: "$secondary",
  backgroundColor: "$neutral",
  borderRadius: "$8",
  boxShadow: `inset 0 0 0 ${space["1"]} ${colors.low}`,
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$14",
  lineHeight: "$150",
  textAlign: "center",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$10",
  paddingLeft: "$12",
  paddingRight: "$12",
  paddingTop: "$4",
  paddingBottom: "$4",
  cursor: "pointer",
  "&:hover": {
    fill: "$primary",
    boxShadow: `inset 0 0 0 ${space["2"]} ${colors.primary}`,
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  "&::after": {
    display: "none !important",
  },
  variants: {
    selected: {
      true: {
        fill: "$primary",
        backgroundColor: "$tulip10",
        boxShadow: `inset 0 0 0 ${space["1"]} ${colors.primary}`,
      },
    },
  },
});

const StyledBadge = styled("div", {
  color: "$neutral",
  backgroundColor: "$primary",
  borderRadius: "$96",
  fontSize: "$10",
  lineHeight: "$150",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "$16",
  height: "$16",
});

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param children - A React element that can be string or anything.
 * @param icon - All the possible icons available on https://phosphoricons.com
 * @param selected - true | false
 * @param badge - number to show inside the badge
 *
 * @example
 *
 * return (
 *   <Chip variant="primary" size="default">Label</Chip>
 * )
 */
export const Chip = forwardRef(
  (
    {
      children,
      icon = "FunnelSimple",
      badge,
      ...props
    }: PropsWithChildren<{
      icon?: string;
      badge?: ReactNode;
      onClick?: () => void;
    }>,
    ref
  ) => {
    return (
      <StyledChip {...props} ref={ref}>
        <SystemIcon icon={icon} size="small" color="inherit" />
        {children}
        {badge ? <StyledBadge>{badge}</StyledBadge> : null}
      </StyledChip>
    );
  }
);

Chip.displayName = "Chip";
