import { Component } from 'react';
import { toast } from 'react-toastify';
import { store } from '../../store';
import './toast.scss';

const TOAST_CONFIG = {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    pauseOnFocusLoss: false
};

export default class CustomToast extends Component {

    constructor(props) {
        super(props);
        this.state = {
            themeValue: 'light'
        }
    }

    componentDidMount() {
        this.themeColor();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.themeValue !== this.props.themeValue) {
            this.themeColor();
        }
    }

    themeColor = () => {
        this.setState({
            themeValue: store.getState().setting.setting.theme_scheme.value
        });
    }

    /**
     * Custom toaster
     * @param {string | any} msg String or Html element
     * @param {"success" | "info" | "warn" | "error" | "default"} type Toaster type. Default will support theme color.
     * @param {boolean} colored bg color also will change
     * @returns Toaster Id
     */
    toggleToaster = (msg, type, colored = false) => {
        let toasterId = null;
        switch (type) {
            case 'success':
                toasterId = toast.success(msg, { ...TOAST_CONFIG, theme: colored ? 'colored' : 'light' });
                break;
            case 'info':
                toasterId = toast.info(msg, { ...TOAST_CONFIG, theme: colored ? 'colored' : 'light' });
                break;
            case 'warn':
                toasterId = toast.warning(msg, { ...TOAST_CONFIG, theme: colored ? 'colored' : 'light' });
                break;
            case 'error':
                toasterId = toast.error(msg, { ...TOAST_CONFIG, theme: colored ? 'colored' : 'light' });
                break;
            case 'default':
                toasterId = toast(msg, { ...TOAST_CONFIG, theme: this.state.themeValue === 'light' ? 'light' : 'dark' });
                break;
            default:
                break;
        }
        return toasterId;
    }
    render() {
        return (<></>)
    }
};

export const ToasterMessage = (props) => {
    return (
        <div className={`${props.className ? props.className : ''} custom-toaster ${props.type}`}>
            <div className="d-flex align-items-center">
                <div className="icon">
                    {
                        props.type === 'success' ? <i className="far fa-check-circle h5"></i> : ''
                    }
                </div>
                <div className={`message h6 custom-toaster ${props.type}`}>
                    {props.message}
                </div>
            </div>
        </div>
    );
}