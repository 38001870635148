import { Layout, Radio, RadioGroup, Typography } from "../../../components";
import { styled } from "../../../theme";
import { space } from "../../../tokens";

//Full Card Layout
const StyledImageContent = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //padding: "$16",
    paddingTop: 0,
    gap: "$24",
    width: "100%",
    justifyContent: "start",
    backgroundColor: "$neutral",
    borderRadius: "$16",
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    "> img": {
        borderRadius: "$16",
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    variants: {
        variant: {
            enabled: {
                backgroundColor: '$tulip10',
                borderColor: '$primary',
            },
        },
    }

});

//Post Content Container
const StylesPercentageLayout = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    padding: "0 $8",
    borderRadius: "$4",
    height: "$32",
    textAlign: 'center',
    fontWeight: '$600',
    fontSize: '$16',
    lineHeight: '$32',
    variants: {
        variant: {
            enabled: {
                backgroundColor: '$primary',
                color: '$neutral'
            },
            disabled: {
                backgroundColor: '$low',
                color: 'high'
            },
        },
    }
});


export const ImagePoll = ({
    src,
    inputValue,
    defaultValue,
    inputChecked,
    onInputChange,
    readOnly,
    textQuestion,
    votePercentage,
    ...props
}) => {
    return (
        <StyledImageContent {...props}>
            <img loading="eager" src={src} alt={''} />
            <Layout css={{
                width: '100%',
                alignItems: 'center',
                padding: '$10',
                justifyContent: 'space-between',
            }}>
                <Layout css={{
                    alignItems: 'center',
                }}>
                    <RadioGroup value={defaultValue} onValueChange={onInputChange}>
                        <Radio value={inputValue}></Radio>
                    </RadioGroup>
                    <Typography variant='body' fontWeight='bold'>
                        {textQuestion}
                    </Typography>
                </Layout>
                <StylesPercentageLayout {...props}>
                    {votePercentage}
                </StylesPercentageLayout>
            </Layout>

        </StyledImageContent>
    );
};

ImagePoll.DisplayName = "ImagePoll";