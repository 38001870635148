import { styled, shadows } from "../../theme";
import { space } from "../../tokens";

import { SystemIcon } from "../../foundations";

const StyledSelectField = styled("select", {
  color: "$secondary",
  backgroundColor: "$neutral",
  borderRadius: "$8",
  borderWidth: space["1"],
  borderColor: "$neutral50",
  borderStyle: "solid",
  boxSizing: "border-box",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$150",
  paddingLeft: "$16",
  paddingRight: "$36",
  paddingTop: "$8",
  paddingBottom: "$8",
  appearance: "none",
  width: "100%",
  "+ div": {
    fill: "$medium",
  },
  "&:hover": {
    borderColor: "$primary",
    "&::placeholder": {
      color: "$medium",
    },
    "+ div": {
      fill: "$primary",
    },
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  "&::placeholder": {
    color: "$high",
  },
});

const StyledContainer = styled("div", {
  position: "relative",
  width: "273px",
  minWidth: "273px",
});

const StyledIcon = styled("div", {
  position: "absolute",
  top: "$6",
  right: "$12",
  pointerEvents: "none",
  userSelect: "none",
});

/**
 * This component accepts all the select props extended by the HTML5.
 *
 * @example
 *
 * return (
 *   <SelectField name="cars" id="cars" placeholder="Select one..." />
 * )
 */
export const SelectField = (props) => {
  return (
    <StyledContainer>
      <StyledSelectField {...props} />
      <StyledIcon>
        <SystemIcon
          icon="CaretDown"
          size="small"
          color="inherit"
          weight="bold"
        />
      </StyledIcon>
    </StyledContainer>
  );
};

SelectField.displayName = "SelectField";
