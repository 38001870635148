import { styled } from "../../theme";
import { IconButton } from "../icon-button/icon-button";
import { Layout } from "../layout/layout";

const StyledHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "100%",
  padding: "$16",
});

const StyledLabel = styled("div", {
  display: 'flex',
  justifyContent: 'center',
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  width: '60%',
});

export const ModalHeader = ({
  backIcon,
  backIconAction = null,
  label,
  onCloseModal,
  isBlackColor
}) => {
  return (
    <StyledHeader css={{ backgroundColor: isBlackColor && 'Black' }}>
      <Layout css={{
        width: '20%',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'flex-start',
        fontSize: "$24",
      }} onClick={backIconAction}>
        {backIcon &&
          <IconButton contained={false} icon={backIcon} variant='secondary' />
        }
      </Layout>

      <StyledLabel>{label}</StyledLabel>

      <Layout css={{
        width: '20%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'flex-start',
        fontSize: "$24",
      }} onClick={onCloseModal}>
        <IconButton contained={false} icon='X' variant={isBlackColor ? 'primary' : 'secondary'} />
      </Layout>
    </StyledHeader>
  );
};

ModalHeader.displayName = "ModalHeader";
