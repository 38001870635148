/**
 * To be able to use the fonts, the index.html should import Inter and Roboto fonts from https://fonts.google.com
 * as <link rel="stylesheet" .../>
 *
 * 
 * @example
 *  <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;900&family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet">
 */
export const fonts = {
  default: "Inter, sans-serif",
  roboto: "Roboto, sans-serif",
};
