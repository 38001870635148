import { Layout } from "../../components";
import { styled } from "../../theme";

const StyledLayout = styled("div", {
    display: 'grid',
    gap: '$24',
    alignItems: 'end',
    paddingLeft: '$16',
});

export const PromotedViewAction = ({
    children,
}) => {
    return (
        <StyledLayout>
            <Layout css={{
                display: 'grid',
                gap: '$16',
            }}>
                {children}
            </Layout>
        </StyledLayout>
    );
};

PromotedViewAction.displayName = "PromotedViewAction";