import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";



//Card Header Styles
const StyledPostHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$12",
  width: "100%",
  justifyContent: "center",
});

//Full Card Layout
const StyledInfoHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "$16",
  gap: "$24",
  justifyContent: "center",
  backgroundColor: "$neutral",
  borderRadius: "$16",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$18",
  lineHeight: "$32",
  margin: 0,
});
const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$24",
  margin: 0,
});

export const PostCardEmpty = ({title, description}) => {
  return (
    <StyledPostHeader>
      <StyledInfoHeader>
        <SystemIcon icon={'Newspaper'} size="default" color="inherit" />
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </StyledInfoHeader>
    </StyledPostHeader>
  );
};

PostCardEmpty.DisplayName = "PostCardEmpty";
