import { styled } from "../../theme";
import { space } from "../../tokens";
import { Typography, Avatar, Button, Layout } from "../../components";
import default_image from "../../../assets/images/profile/profile.png";

const StyledCard = styled("div", {
  display: "flex",
  marginTop: "$8",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "relative",
  "> img": {
    borderRadius: "$8",
  },

    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "100%",
                marginLeft: "$8",
            },
        },
    },
});

const StyledLeaderList = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "$16",
  gap: "$8",
  borderRadius: "$8",
  marginTop: "$10",
  backgroundColor: "$neutral",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
});

const StyledLeaderListView = styled("div", {
  display: "flex",
  flexDirection: "row",
  padding: "$16",
  width: "100%",
  backgroundColor: "White",
  borderRadius: "$8",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  cursor: "pointer",
});

const StyledLeaderHeaderView = styled("div", {
  display: "flex",
  flexDirection: "row",
  paddingInline: "$16",
  width: "100%",
});

const StyledLeaderRank = styled("div", {
  width: "15%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingInline: "$10",
});

const StyledLeaderAvatar = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const GroupDetailLeft = ({
  participatesList,
  onClickViewAllMembers,
}) => {
  return (
    <StyledCard
      mode={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}
    >
      {participatesList?.length > 0 && (
        <StyledLeaderList>
          <StyledLeaderHeaderView>
            <StyledLeaderRank>
              <Typography variant="body">{`Members(${participatesList.length})`}</Typography>
            </StyledLeaderRank>
            <StyledLeaderAvatar></StyledLeaderAvatar>
          </StyledLeaderHeaderView>
          {participatesList?.length > 0 &&
            participatesList?.slice(0, 3).map((item, index) => {
              return (
                <StyledLeaderListView>
                  <StyledLeaderAvatar>
                    <Avatar
                      src={
                        item?.userInfo?.photoUrl
                          ? item?.userInfo?.photoUrl
                          : default_image
                      }
                      alt={"Guest"}
                      fallback={"G"}
                    />
                    <Layout
                      css={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "5%",
                      }}
                    >
                      <Typography variant="body" fontWeight={"bold"}>
                        {item?.userInfo?.displayName
                          ? item?.userInfo?.displayName
                          : "- - - - "}
                      </Typography>
                      <Typography variant="body">
                        {item?.influencerData?.level}
                      </Typography>
                    </Layout>
                  </StyledLeaderAvatar>
                </StyledLeaderListView>
              );
            })}
          {participatesList?.length > 0 && (
            <Button variant={"neutral"} onClick={onClickViewAllMembers}>
              {"View all members"}
            </Button>
          )}
        </StyledLeaderList>
      )}
    </StyledCard>
  );
};

GroupDetailLeft.displayName = "GroupDetailLeft";
