import { Avatar, } from "../../components";
import { styled } from "../../theme";

//Card Header Styles
const StyledNetworkHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
  justifyContent: "start",
});

const HeaderColumn = styled("div", {
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
});

const StyledName = styled("div", {
  marginTop: "$6",
  fontSize: '$20',
  lineHeight: '$24',
  fontWeight: '$600',
  fontFamily: "$roboto",
});

export const ProfileNetworkCard = ({
  userAvatar,
  name,
  buttonIcon,
  buttonName,
  buttonInteraction,
  ...props
}) => {
  return (
    <StyledNetworkHeader>
      <HeaderColumn>
        <Avatar src={userAvatar} {...props} />
        <StyledName>
          {name}
        </StyledName>
      </HeaderColumn>

    </StyledNetworkHeader>
  );
};

ProfileNetworkCard.DisplayName = "ProfileNetworkCard";
