import { styled, shadows } from "../../theme";

import { SystemIcon } from "../../foundations";
import { Layout } from "../layout/layout";

const StyledAnchor = styled("a", {
  fill: "$primary",
  color: "$secondary",
  fontFamily: "$roboto",
  fontSize: "$16",
  lineHeight: "$150",
  fontWeight: "$600",
  borderRadius: "$8",
  textAlign: "center",
  boxSizing: "border-box",
  textDecoration: "none",
  display: "inline-flex",
  cursor: "pointer",
  gap: "$8",
  "&:hover": {
    color: "inherit",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
});


const LabelStyle = styled("div", {
  fill: "$primary",
  color: "$secondary",
  fontFamily: "$roboto",
  fontSize: "$16",
  lineHeight: "$150",
  fontWeight: "$600",
  borderRadius: "$8",
  textAlign: "center",
  boxSizing: "border-box",
  textDecoration: "none",
  display: "inline-flex",
  gap: "$8",
});

/**
 * This component accepts all the anchor props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param children - A React element that can be string or anything.
 * @param icon - All the possible icons available on https://phosphoricons.com
 *
 * @example
 *
 * return (
 *   <Anchor href="#" icon="Heart">Label</Anchor>
 * )
 */
export const Anchor = ({ children, icon, ...props }) => {
  return (
    <StyledAnchor {...props}>
      {icon ? <SystemIcon icon={icon} color="inherit" /> : null}
      {children}
    </StyledAnchor>
  );
};

export const AnchorWithBg = ({ children, icon, ...props }) => {
  return (
    <Layout>
      <StyledAnchor {...props}>
        {icon ? <SystemIcon icon={icon} color="inherit" /> : null}
      </StyledAnchor>
      <LabelStyle>
      {children}
      </LabelStyle>
    </Layout>
  );
};

Anchor.AnchorWithBg = AnchorWithBg;
Anchor.displayName = "Anchor";
