import { styled, shadows } from "../../theme";
import { space } from "../../tokens";

/**
 * This component accepts all the input props extended by the HTML5.
 *
 * @example
 *
 * return (
 *   <TextField type="text" value="Any value..." placeholder="Write something..." />
 * )
 */
export const TextField = styled("input", {
  color: "$secondary",
  backgroundColor: "$neutral",
  borderRadius: "$8",
  borderWidth: space["1"],
  borderColor: "$neutral50",
  borderStyle: "solid",
  boxSizing: "border-box",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$150",
  paddingLeft: "$16",
  paddingRight: "$16",
  paddingTop: "$8",
  paddingBottom: "$8",
  width: "273px",
  minWidth: "273px",
  "&:hover": {
    borderColor: "$primary",
    "&::placeholder": {
      color: "$medium",
    },
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  "&::placeholder": {
    color: "$high",
  },
});

TextField.displayName = "TextField";
