import { Option } from "./option";

export const ChoiceText = ({
  options = [],
  onChange,
  onChangeFreeText,
  value,
}) =>
  options.length ? (
    options.map((option) => (
      <Option
        key={option.id}
        onChange={onChange}
        option={option}
        onChangeFreeText={onChangeFreeText}
        value={value}
      />
    ))
  ) : (
    <div>No options</div>
  );
