import { styled } from "../../theme";
import { Avatar, EmptyState, IconLabelButton } from "../../components";
import default_image from '../../../assets/images/profile/profile.png';
import { BsFillCircleFill } from "react-icons/bs";
import { colors } from "../../tokens";
const moment = require('moment');

const StyledCard = styled("div", {
    backgroundColor: "$neutral",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    "> img": {
        borderRadius: "$8"
    },

    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "100%"
            }
        },
    },
});

const StyledLeaderList = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingInline: '$10',
    paddingBlock: '$8',
    gap: '$8'
})

const StyledLeaderListView = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'White',
    paddingBlock: 5,
    cursor: 'pointer',
    padding: '$12',
    borderRadius: '$8'
})

const StyledLeaderAvatar = styled('div', {
    width: '15%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
})

const StyleLastMessage = styled('div', {
    fontFamily: "$roboto",
    fontSize: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    marginBottom: 0
})

const StyleLastMessageUpdated = styled('div', {
    fontFamily: "$roboto",
    fontSize: '14px',
    color: '#C5C5C5',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    marginBottom: 0,
    marginLeft: 2
})

const StyledName = styled('div', {
    fontFamily: "$roboto",
    fontSize: '14px',
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    marginBottom: 0
})

const StyledOverLayImage = styled('div', {
    paddingLeft: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    "> img": {
        height: '30px',
        width: '30px',
        resize: 'contain',
        border: '2px solid white',
        borderRadius: '50%',
        position: 'relative',
        left: "-30px",
        marginLeft: '-15px',
        zIndex: 1
    },
})

const StyledNotificationDot = styled('div', {
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
})

const StyledNameLayout = styled('div', {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    gap: '$4'
})

const StyledMessageLayout = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    gap: '$2'
})

const StyledEmptyLayout = styled('div', {
    marginTop: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
})

export const ChatList = ({
    chatItem,
    onClickMessage,
    selectedChatId
}) => {
    return (
        <>
            <StyledCard mode={{
                "@initial": "mobile",
                "@bp3": "desktop",
            }}>
                {
                    chatItem?.length > 0 &&
                    <StyledLeaderList>
                        {
                            (chatItem?.length > 0) &&
                            chatItem?.map((item, index) => {
                                return (
                                    <StyledLeaderListView onClick={() => { onClickMessage(item, index) }} css={{ backgroundColor: item.id === selectedChatId ? colors.tulip50 : 'white' }}>
                                        <StyledLeaderAvatar>
                                            {
                                                !item.directRoom ? <Avatar src={item?.iconUrl ? item?.iconUrl : default_image} alt={"Guest"} fallback={'G'} /> :
                                                    <>
                                                        {
                                                            item.participants.length > 0 &&
                                                            <StyledOverLayImage>
                                                                {
                                                                    item.participants?.map((item, index) => {
                                                                        return (
                                                                            <img src={item?.userInfo?.photoUrl} alt="" />
                                                                        )
                                                                    })
                                                                }
                                                            </StyledOverLayImage>
                                                        }
                                                    </>

                                            }
                                        </StyledLeaderAvatar>

                                        <StyledNameLayout>
                                            {
                                                item.directRoom ? <StyledName>{item?.participants.length > 0 ? `${item?.participants?.[0]?.userInfo.displayName},${item?.participants?.[1]?.userInfo.displayName}` : '- - - - '}</StyledName> :
                                                    <StyledName>{item?.name ? item?.name : '- - - - '}</StyledName>
                                            }
                                            <StyledMessageLayout>
                                                <StyleLastMessage>{item?.lastMessage?.message ? `${item?.lastMessage?.message}.` : ''}</StyleLastMessage>
                                                {
                                                    item?.lastUpdatedAt &&
                                                    <StyleLastMessageUpdated>{item?.lastUpdatedAt ? ` ${moment(new Date(item?.lastUpdatedAt)).fromNow()}` : ''}</StyleLastMessageUpdated>
                                                }
                                            </StyledMessageLayout>
                                        </StyledNameLayout>

                                        <StyledNotificationDot>
                                            {
                                                item.unreadMessages > 0 && <BsFillCircleFill color={'#F2A73B'} size={10} />
                                            }
                                        </StyledNotificationDot>
                                    </StyledLeaderListView>
                                )
                            })
                        }
                    </StyledLeaderList>
                }
                {
                    chatItem?.length === 0 &&
                    <StyledEmptyLayout>
                        <EmptyState
                            icon={'ChatCircleText'}
                            title={`You don't have any chats`}
                            description={'Create a chat with someone or create a new discussion group'}
                        />
                        <IconLabelButton icon="PlusCircle" variant="primary" label="New messages" style={{ width: '50%' }} onClick={() => {
                        }}></IconLabelButton>
                    </StyledEmptyLayout>
                }
            </StyledCard>
        </>

    );
};

ChatList.displayName = "ChatList";