import { styled } from "../../../theme";
import { useMemo } from "react";
import { ChoiceText } from "./choice-section-text";
import { ChoiceImages } from "./choice-section-images";
import { store } from "../../../../store";

const StyledLayout = styled("div", {
  display: "block",
  borderRadius: "$16",
  marginTop: "$8",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$8",
  position: "relative",
});

const StyledQuestion = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$32",
  margin: 0,
  padding: 0,
});

export const SurveyMultiChoice = ({
  assets,
  question = null,
  value = [],
  onChange = () => {},
  onChangeFreeText = () => {},
}) => {
  const isQuestionWithAssets = useMemo(
    () => question.surveyOptions.some((opt) => !!opt.imageAsset),
    [question]
  );

  const {theme} = store.getState() 

  return (
    <StyledLayout>
      {" "}
      <StyledQuestion css={{color: theme.secondary}} >{question.text}</StyledQuestion>
      {assets}
      {isQuestionWithAssets ? (
        <ChoiceImages
          value={value}
          options={question.surveyOptions}
          onChange={onChange}
          onChangeFreeText={onChangeFreeText}
        />
      ) : (
        <ChoiceText
          value={value}
          options={question.surveyOptions}
          onChange={onChange}
          onChangeFreeText={onChangeFreeText}
        />
      )}
    </StyledLayout>
  );
};
SurveyMultiChoice.displayName = "SurveyMultiChoice";
