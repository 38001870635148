import { styled } from "../../theme";
import { IconButton, Layout } from "../../components";
import { space } from "../../tokens";
import { SystemIcon } from "../../foundations";
import { Spinner } from "react-bootstrap";

const InputLayout = styled("div", {
    display: "flex",
    width: "100%",
    justifyContent: 'space-between',
    padding: "$8 $16",
    borderWidth: space[1],
    borderStyle: "solid",
    borderColor: "$low",
    borderRadius: '$8',
    gap: "$10",
});

const StyledInput = styled("input", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    borderWidth: 0,
    width: '90%',
});

export const CommentsBottomInput = ({
    placeholder = "Write a message",
    sendIcon,
    onClickSendComment,
    inputValue,
    onMediaClick,
    onGifClick,
    onEmojiClick,
    onSendClick,
    sendLoading,
    ref,
    ...props
}) => {
    return (
        <Layout
            css={{
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
                cursor: "pointer",
                padding: '$1'
            }}>
            <Layout css={{ alignItems: "center", alignSelf: "stretch" }}>
                <IconButton contained={false} icon='Camera' variant='secondary' onClick={onMediaClick} />
                <IconButton contained={false} icon='Gif' variant='secondary' onClick={onGifClick} />
                <IconButton contained={false} icon='Smiley' variant='secondary' onClick={onEmojiClick} />
                <InputLayout>
                    <StyledInput {...props} value={inputValue} placeholder={placeholder} />
                    <SystemIcon icon={sendIcon} onClick={onSendClick} />
                    {
                        sendLoading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ margin: '0 8px' }}
                        />
                    }
                </InputLayout>
            </Layout>
        </Layout>
    );
};
