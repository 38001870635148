import { styled } from "../../theme";

const StyledTag = styled("div", {
  backgroundColor: "$low",
  borderRadius: "$8",
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$14",
  lineHeight: "$150",
  color: "$secondary",
  paddingLeft: "$8",
  paddingRight: "$8",
  paddingTop: "$4",
  paddingBottom: "$4",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
});

/**
 * This component accepts only internal props
 *
 * @param children - A React element that can be string or anything.
 *
 * @example
 *
 * return (
 *   <Tag>Label</Tag>
 * )
 */
export const Tag = ({ children }) => {
  return <StyledTag>{children}</StyledTag>;
};

Tag.displayName = "Tag";
