import { styled, shadows } from "../../theme";

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param variant - primary | secondary | neutral
 * @param size - default | large
 *
 * @example
 *
 * return (
 *   <Button variant="primary" size="default">Label</Button>
 * )
 */
export const Button = styled("button", {
  all: "unset",
  border: "none",
  fontFamily: "$default",
  fontWeight: "$600",
  borderRadius: "$8",
  textAlign: "center",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxShadow: shadows.subtle,
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  variants: {
    variant: {
      primary: {
        color: "$neutral",
        backgroundColor: "$primary",
        // "&:hover": {
        //   backgroundColor: "$interactive",
        // },
        "&:disabled": {
          color: "$neutral300",
          backgroundColor: "$low",
        },
      },
      secondary: {
        color: "$neutral",
        backgroundColor: "$secondary",
        // "&:hover": {
        //   backgroundColor: "$high",
        // },
        "&:disabled": {
          color: "$neutral300",
          backgroundColor: "$low",
        },
      },
      neutral: {
        color: "$secondary",
        backgroundColor: "$neutral",
        // "&:hover": {
        //   color: "$interactive",
        //   backgroundColor: "$tulip10",
        // },
        "&:disabled": {
          color: "$neutral300",
          backgroundColor: "$low",
        },
      },
    },
    size: {
      default: {
        fontSize: "$16",
        lineHeight: "$150",
        paddingLeft: "$16",
        paddingRight: "$16",
        paddingTop: "$8",
        paddingBottom: "$8",
      },
      large: {
        fontSize: "$20",
        lineHeight: "$120",
        paddingLeft: "$24",
        paddingRight: "$24",
        paddingTop: "$16",
        paddingBottom: "$16",
      },
      small: {
        fontSize: "$14",
        lineHeight: "$150",
        paddingLeft: "$16",
        paddingRight: "$16",
        paddingTop: "$8",
        paddingBottom: "$8",
      },
      extraSmall: {
        fontSize: '$12',
        lineHeight: "$150",
        paddingLeft: "$16",
        paddingRight: "$16",
        paddingTop: "$8",
        paddingBottom: "$8",
      },
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "default",
  },
});

Button.displayName = "Button";
