import { styled } from "../../theme";
import { space } from "../../tokens";
import { Typography, Button, Layout } from "../../components";

const StyledCard = styled("div", {
    backgroundColor: "$neutral",
    display: "flex",
    marginTop: '$8',
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    "> img": {
        borderRadius: "$8"
    },

    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "100%",
                marginLeft: '$8',
            }
        },
    },
});

const StyledLeaderList = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '$16',
    gap: '$8',
    borderRadius: '$8',
    marginTop: '$10',
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid"
})

const StyledLeaderListView = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    width: '30%',
    backgroundColor: 'White',
    borderRadius: '$4',
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    cursor: 'pointer',
    justifyContent: 'center',
})

const StyledLeaderHeaderView = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    paddingInline: '$16',
    width: '100%'
})

const StyledLeaderRank = styled('div', {
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: '$10'
})

const StyledLeaderAvatar = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
})

export const GroupDetailGallery = ({
    galleryList
}) => {
    return (
        <>
            <StyledCard mode={{
                "@initial": "mobile",
                "@bp3": "desktop",
            }}>
                {
                    galleryList?.length > 0 &&
                    <StyledLeaderList>
                        <StyledLeaderHeaderView>
                            <StyledLeaderRank>
                                <Typography variant='body'>{`Gallery(${galleryList.length})`}</Typography>
                            </StyledLeaderRank>
                            <StyledLeaderAvatar>
                            </StyledLeaderAvatar>
                        </StyledLeaderHeaderView>
                        {
                            (galleryList?.length > 0) &&
                            <Layout css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}> 
                            {
                                galleryList?.slice(0, 6).map((item, index) => {
                                    return (
                                        <StyledLeaderListView>
                                            <img src={item?.url} style={{ height: '80px', width: '80px', resize: 'contain' }} alt="" />
                                        </StyledLeaderListView>
                                    )
                                })

                            }
                            </Layout>

                        }
                        {
                            (galleryList?.length > 0) &&
                            <Button variant={'neutral'}>{"View all gallery"}</Button>
                        }
                    </StyledLeaderList>
                }
            </StyledCard>
        </>

    );
};

GroupDetailGallery.displayName = "GroupDetailGallery";
