import { Button, SkeletonOpinionQuestionRow } from "../../design-system";
import { useState } from "react";
import { RegisterSteps } from "./register-step";
import { store } from "../../store";

export const OpinionsWelcomeScreen = ({ post, setTokens = () => {} }) => {
  return (
    <div style={{ position: "relative" }}>
      <Skeleton />
      <AbsoluteContainer setTokens={setTokens} post={post} />
    </div>
  );
};

const AbsoluteContainer = ({ setTokens = () => {}, post }) => {
  const [showRegistration, setShowRegistration] = useState(false);

  const getCurrentSection = () =>
    showRegistration ? (
      <RegisterSteps setTokens={setTokens} />
    ) : (
      <ShowWelcome setShowRegistration={setShowRegistration} post={post} />
    );

  return (
    <div
      style={{
        position: "absolute",

        height: "fit-content",
        width: "fit-content",

        inset: 0,
        margin: "auto",
      }}
    >
      {getCurrentSection()}
    </div>
  );
};

export const ShowWelcome = ({ setShowRegistration = () => {}, post }) => {
  const { theme } = store.getState();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      {" "}
      <div style={{ fontSize: 24, fontWeight: 600, textAlign: "center" }}>
        {post.name}
      </div>
      <div style={{ textAlign: "center" }}>
        Please register to complete this survey
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => setShowRegistration(true)}
          style={{ backgroundColor: theme.primary, color: theme.secondary }}
        >
          Register now
        </Button>
      </div>
    </div>
  );
};

const Skeleton = () => {
  const { theme } = store.getState();

  return (
    <div
      style={{
        backgroundColor: theme.secondary,
        borderRadius: 16,
        padding: 24,
        width: "100%",

        pointerEvents: "none",

        filter: "blur(4px) brightness(0.8)",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
        {Array.from({ length: 5 }).map((_, index) => (
          <SkeletonOpinionQuestionRow key={index} index={index + 1} />
        ))}
      </div>

      <Button
        style={{ width: "100%", marginTop: 24, backgroundColor: theme.primary, color: theme.neutral }}
      >
        Submit Answer
      </Button>
    </div>
  );
};
