import { Layout, Typography } from "../../components";
import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";
import { colors } from "../../tokens";

const HeaderLayout = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledTitle = styled("p", {
  textAlign: "center",
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$32",
  margin: 0,
  paddingInline: "13%",
});

const RewardsLayout = styled("div", {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  background: "$tulip10",
  padding: "$24",
  borderRadius: "$16",
  gap: "$16",
  textAlign: "center",
});

export const ShopbuzzEarnRewards = ({ rewards, affiliateComission = 0 }) => {
  const renderRewards = (items) =>
    items.map((reward) => {
      return (
        <Typography
          style={{ width: "100%" }}
          variant={"body"}
          fontWeight={"bold"}
        >
          <SystemIcon icon={"CheckCircle"} color={colors.primary} /> {reward}
        </Typography>
      );
    });

  return (
    <HeaderLayout>
      <StyledTitle>
        Earn affiliate comission and loyality points everytime you promote a
        product!
      </StyledTitle>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr", gap: 12 }}>
        <RewardsLayout>
          <div>
            <Typography fontWeight={"bold"}>
              Earn affiliate commission
            </Typography>
          </div>
          <div>
            <Typography fontWeight={"bold"}>
              on every sale triggered by your promotion
            </Typography>
          </div>
          <div style={{ fontWeight: 600, fontSize: 28 }}>
            {affiliateComission} %
          </div>
        </RewardsLayout>
        <RewardsLayout>
          {/* <Typography>{'When promoting this product you can earn:'}</Typography> */}
          <Layout
            css={{
              display: "grid",
            }}
          >
            {renderRewards(rewards)}
          </Layout>
        </RewardsLayout>
      </div>
    </HeaderLayout>
  );
};

ShopbuzzEarnRewards.displayName = "ShopbuzzEarnRewards";
