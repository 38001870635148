import * as RadioGroupBase from "@radix-ui/react-radio-group";
import styled from "styled-components";

import { shadows, styled as styledComp } from "../../theme";
import { ComponentProps } from "react";
import { colors, space } from "../../tokens";

const StyledRadioGroupItem = styledComp(RadioGroupBase.Item, {
  fill: "$neutral",
  backgroundColor: "$neutral",
  borderWidth: space["1"],
  borderColor: "$low",
  borderRadius: "$96",
  padding: "$0",
  width: "$24",
  height: "$24",
  overflow: "hidden",
  "&:hover": {
    borderColor: "$primary",
  },
  "&:focus": {
    outline: "none",
    boxShadow: shadows.focus,
  },
});

const StyledRadioGroupIndicator = styled(RadioGroupBase.Indicator)<{
  indicatorColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 96px;
    background-color: ${({ indicatorColor }) =>
      indicatorColor || colors.primary || "#000"};
  }
`;

/**
 * This component accepts all the image props extended by the `@radix-ui/react-checkbox`.
 *
 * @param value - The radio group value.
 * @param ariaLabel - The text that represents the radio group.
 * @param onValueChange - The onValueChange function receives the value when the value change.
 *
 * @example
 *
 * return (
 *    <RadioGroup value="value-1" aria-label="View density" onValueChange={...}>
 *      <Radio value="value-1" id="v1" />
 *      <Radio value="value-2" id="v2" />
 *      <Radio value="value-3" id="v3" />
 *    </RadioGroup>
 * )
 */
export const RadioGroupRoot = styledComp(RadioGroupBase.Root, {
  display: "flex",
  flexDirection: "column",
  gap: "$16",
});

export const RadioGroup = (
  props: ComponentProps<typeof RadioGroupBase.Root>
) => <RadioGroupRoot {...props} />;

type RadioGroupItemProps = ComponentProps<typeof RadioGroupBase.Item>;

/**
 * This component accepts all the image props extended by the @radix-ui/react-radio-group.
 *
 * @param value - The radio value.
 *
 * @example
 *
 * return (
 *   <Radio value="value-1" id="v1" />
 * )
 */
export const Radio = ({
  indicatorColor,
  ...props
}: RadioGroupItemProps & { indicatorColor?: string }) => {
  return (
    <StyledRadioGroupItem {...props}>
      <StyledRadioGroupIndicator indicatorColor={indicatorColor} />
    </StyledRadioGroupItem>
  );
};

Radio.displayName = "Radio";
