import { FormControl, FormGroup, FormLabel, Image } from "react-bootstrap";
import { Radio } from "../../../components";
import { styled } from "../../../theme";
import NoMedia from "src/assets/images/no_media_image.png";
import { store } from "../../../../store";

const StyledChoiceLayout = styled("label", {
  borderRadius: "$4",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#3D3E41",
  padding: "$10",
});

const StyledChoice = styled("label", {
  fontFamily: "$roboto",
  fontSize: "$14",
  fontWeight: "400",
  lineHeight: "$32",
  margin: 0,
});

export const Option = ({
  option,
  value = [],
  onChangeFreeText = () => {},
  withAsset = false,
}) => {
  const isCurrentAnswer = value.includes(option.id);

  const isOtherOption = option.optionType === "OTHER";

  const renderTextField = isCurrentAnswer && isOtherOption;

  return withAsset ? (
    <OptionImage
      isOtherOption={isOtherOption}
      onChangeFreeText={onChangeFreeText}
      option={option}
      renderTextField={renderTextField}
      isCurrentAnswer={isCurrentAnswer}
    />
  ) : (
    <OptionText
      isOtherOption={isOtherOption}
      onChangeFreeText={onChangeFreeText}
      option={option}
      renderTextField={renderTextField}
      isCurrentAnswer={isCurrentAnswer}
    />
  );
};

const OptionText = ({
  option,
  onChangeFreeText,
  renderTextField,
  isCurrentAnswer,
}) => {
  const { theme } = store.getState();

  return (
    <StyledChoiceLayout className="survey-choice-layout-panel"
      css={{
        borderColor: isCurrentAnswer ? theme.primary : theme.low,
        backgroundColor: theme.choiceBg,
        background: theme.choiceBg,
      }}
      for={option.id}
    >
      <div className="survey-choice-panel">
        <div className="survey-choice-radio-text"
          style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
          }}
        >
          
          <StyledChoice
            css={{ color: theme.secondary}}            
          >
            <Radio css={{ width:"16px", height:"16px" }} id={option.id} value={option.id} indicatorColor={theme.primary}/>{" "}
            {option.text}
          </StyledChoice>
        </div>
        {renderTextField && (
          <FormGroup style={{ marginTop: 12 }}>
            <FormLabel
              title=""
              css={{
                color: theme.secondary,
              }}
            >              
            </FormLabel>
            <FormControl onChange={(e) => onChangeFreeText(e)} placeholder="Enter your answer here" />
          </FormGroup>
        )}
      </div>
    </StyledChoiceLayout>
  );
};

const OptionImage = ({
  option,
  onChangeFreeText,
  renderTextField,
  isCurrentAnswer,
}) => {
  const { theme } = store.getState();

  return (
    <StyledChoiceLayout
      css={{
        borderColor: isCurrentAnswer ? theme.primary : theme.low,
        backgroundColor: theme.neutral,
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 12 }}
      >
        <Image
          src={option.imageAsset?.url ?? NoMedia}
          height={130}
          width="100%"
          style={{ objectFit: "cover", borderRadius: 8 }}
        />
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Radio value={option.id} indicatorColor={theme.primary}/>{" "}
          <StyledChoice
            css={{ color: theme.secondary, backgroundColor: theme.neutral }}
          >
            {option.text}
          </StyledChoice>
        </div>

        {renderTextField && (
          <FormGroup style={{ marginTop: 12 }}>
            <FormLabel
              title=""
              css={{
                backgroundColor: theme.neutral,
                color: theme.secondary,
              }}
            >
              Enter your answer here:
            </FormLabel>
            <FormControl onChange={(e) => onChangeFreeText(e)} placeholder="" />
          </FormGroup>
        )}
      </div>
    </StyledChoiceLayout>
  );
};
