import { Layout } from "../../components";
import { styled } from "../../theme";
import { space } from "../../tokens";


//Full Card Layout
const StyledLayout = styled("div", {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "start",
    backgroundColor: "$neutral",
    borderRadius: "$16",
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    maxHeight: 370,
    position: "relative",
    "> img": {
        borderRadius: "$8",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: '100%',
        height: 160,
        objectFit: 'cover',
    },
    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "23%",
                marginLeft: '$8',
                marginTop: '$6',
                padding: '$0'
            }
        },
    },

});

const StyledSectionLayout = styled("div", {
    padding: '$16',
})

const StyledDescription = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$18",
    color: "$secondary",
    height: 60,
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
})

export const MessagesCard = ({
    src,
    title,
    description,
    button1,
    button2,
    ...props
}) => {
    return (
        <StyledLayout mode={{
            "@initial": "mobile",
            "@bp3": "desktop",
        }}>
            <img src={src} alt="" />
            <StyledSectionLayout>
                {title}
                <StyledDescription>{description}</StyledDescription>
                <Layout css={{
                    width: '100%',
                    paddingTop: '$10',
                }}>
                    {button1} {button2}
                </Layout>
            </StyledSectionLayout>


        </StyledLayout>
    );
};

MessagesCard.DisplayName = "MessagesCard";