import { isMobile } from "react-device-detect";
import { Avatar, Button, Chip, Typography } from "../../components";
import { styled } from "../../theme";
import { space } from "../../tokens";

//Full Card Layout
const StyledPostCard = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "$16",
  gap: "$24",
  justifyContent: "start",
  width: "100%",
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
});

//Card Header Styles
const StyledPostHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
  width: "100%",
  justifyContent: "space-between",
});

const HeaderColumn = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
});

const StyledInfoHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  Width: "100%",
  justifyContent: "flex-end",
});

//Post Content Container
const StyledPostContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
});

/* const FeaturedMedia = styled("img", {
  width: "100%",
  height: "300px",
  borderRadius: "0.5rem",
  objectFit: "cover",
}); */

//Post Interaction Containers
const StyledSocialHolder = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
});

const ActionBelt = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  gap: "5rem",
  width:'100%'
});

const StyledInteractionCount = styled("div", {
  display: "flex",
  padding: "0.25rem 0rem",
  justifyContent: "space-between",
  gap: "0.625rem",
  width: "100%",
  alignSelf: "stretch",
  alignItems: "center",
});

const TextBelt = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "0.625rem",
});

const StyledTagHolder = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.5rem",
  alignSelf: "stretch",
});

//Card Action Styles
const StyledPostAction = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
  gap: "$16",
  width: "100%",
});

const AvatarAction = styled("div", {
  display: "flex",
  cursor: "pointer",
});

const SocialFooter = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "0.5rem",
  width: "100%",
  alignSelf: "stretch",
});

const StyledMobileButtons = styled("div", {
  display: "flex",
  justifyContent: "end",
  alignItems: "flex-end",
  marginLeft: "5rem",
  marginTop: "0.5rem",
});

//Post Card Interactions - Like, comment and Share

const SocialHolder = ({
  children,
  likeCount,
  shareCount,
  commentCount,
  showComment = true,
  showShare = true,
  isVoteButton = false,
  isContestScreen,
  onClickVoteAction,
  onClickUnVoteAction,
  actionButton = null,
  postContentButtons = null,
  showViewCount = false,
  viewCount,
  showInteractionCount = true,
}) => {
  return (
    <StyledSocialHolder>
      <ActionBelt>{children}</ActionBelt>
      <SocialFooter>
        {showInteractionCount && (
          <StyledInteractionCount>
            <Typography variant="body" style={{ width: "5rem" }}>
              {likeCount} likes
            </Typography>
            {showComment && (
              <Typography variant="body" style={{ width: "7rem" }}>
                {commentCount} comments
              </Typography>
            )}
            {showShare && (
              <Typography variant="body" style={{ width: "6rem" }}>
                {shareCount} shares
              </Typography>
            )}
            {showViewCount && !isMobile && (
              <Typography variant="body" style={{ width: "6rem" }}>
                {viewCount} Views
              </Typography>
            )}
          </StyledInteractionCount>
        )}
        <StyledInteractionCount>
          <TextBelt>
            {isContestScreen && (
              <>
                {isVoteButton ? (
                  <Button
                    variant={"secondary"}
                    disabled={isVoteButton}
                    onClick={onClickVoteAction}
                  >{`${likeCount} Voted`}</Button>
                ) : (
                  <Button
                    variant={"primary"}
                    disabled={isVoteButton}
                    onClick={onClickUnVoteAction}
                  >{`${likeCount} Vote`}</Button>
                )}
              </>
            )}
          </TextBelt>
          {!isMobile && (
            <>
              {actionButton}
              {postContentButtons}
            </>
          )}
        </StyledInteractionCount>
      </SocialFooter>
      {isMobile && (
        <StyledMobileButtons>
          {actionButton}
          {postContentButtons}
        </StyledMobileButtons>
      )}
    </StyledSocialHolder>
  );
};

//Post Header - User Information
export const PostHeader = ({
  username,
  postDate,
  userAvatar,
  onClickFollowInteraction,
  followLabel,
  loggedIn,
  dropdownContent,
  isOwner,
  avatarClickAction,
  isNotLoggedInUserPost,
  disableFollow,
}) => {
  return (
    <StyledPostHeader>
      <HeaderColumn>
        <AvatarAction onClick={avatarClickAction}>
          <Avatar src={userAvatar} />
        </AvatarAction>
        <StyledInfoHeader>
          <Typography fontWeight="bold">{username}</Typography>
          <Typography color="medium">{postDate}</Typography>
        </StyledInfoHeader>
      </HeaderColumn>
      <HeaderColumn>
        {loggedIn ? (
          <>
            {!isOwner && (
              <>
                {!disableFollow && (
                  <Chip icon="Plus" onClick={onClickFollowInteraction}>
                    {followLabel}
                  </Chip>
                )}
              </>
            )}
            {dropdownContent}
          </>
        ) : null}
      </HeaderColumn>
    </StyledPostHeader>
  );
};

export const PostCardContent = ({
  postContentTitle,
  postContent,
  postFeaturedMedia,
  postTagContent,
  postPollContent,
  isEntryContent,
  isPoll,
}) => {
  return (
    <StyledPostContent css={{ gap: isPoll ? "4px" : "16px" }}>
      {!isEntryContent && (
        <>
          <Typography fontWeight="bold">{postContentTitle}</Typography>
          <Typography variant="body" color="medium">
            {postContent}
          </Typography>
        </>
      )}
      {postFeaturedMedia}
      {postPollContent}
      <StyledTagHolder>{postTagContent}</StyledTagHolder>
    </StyledPostContent>
  );
};

export const postPollContent = ({ children }) => {
  return <StyledPostContent>{children}</StyledPostContent>;
};

export const postTagContent = ({ children }) => {
  return <StyledTagHolder>{children}</StyledTagHolder>;
};

//Full Post Component

export const PostCard = ({ id, children }) => {
  return <StyledPostCard id={id}>{children}</StyledPostCard>;
};

//Post Action - Interacted User Information
export const PostAction = ({
  userAvatar,
  action,
  avatarSize,
  avatarClickAction,
}) => {
  return (
    <StyledPostAction>
      <HeaderColumn>
        <Avatar size={avatarSize} src={userAvatar} />
        <StyledInfoHeader>
          <Typography fontWeight="bold">{action}</Typography>
        </StyledInfoHeader>
      </HeaderColumn>
    </StyledPostAction>
  );
};
PostCard.Action = PostAction;
PostCard.Header = PostHeader;
PostCard.Content = PostCardContent;
PostCard.Poll = postPollContent;
PostCard.Tags = postTagContent;
PostCard.SocialHolder = SocialHolder;

PostCard.DisplayName = "PostCard";
