import { Slider } from "../../../components";
import { styled } from "../../../theme";
const StyledLayout = styled("div", {
    display: 'block',
    borderRadius: "$16",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$8",
    position: "relative",
});

const StyledQuestion = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: '$14',
    lineHeight: "$32",
    margin: 0,
    padding: 0,
});

const StyledRatingLayout = styled("div", {
    display: 'flex',
    alignItems: 'center',
    gap: "$16",
    padding: '$10',
});
const StyledValue = styled('p', {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$16",
    lineHeight: "$32",
    margin: 0,
})
export const SurveyRating = ({
    question,
    assets = null,
    onChange,
    min = 1,
    max,
    value,
    stepRange,
}) => {
    return (
        <StyledLayout>
            <StyledQuestion>{question}</StyledQuestion>
            {assets}
            <StyledRatingLayout>
                <Slider
                    min={min}
                    max={max}
                    onChange={onChange}
                    value={value}
                    step={stepRange}
                />
                <StyledValue>{value}</StyledValue>
            </StyledRatingLayout>
        </StyledLayout>
    );
};

SurveyRating.displayName = "SurveyRating";