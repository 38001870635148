import { styled, shadows } from "../../theme";
import { space } from "../../tokens";

const StyledInput = styled("input", {
    color: "$secondary",
    backgroundColor: "$neutral",
    borderRadius: "$8",
    borderWidth: space["1"],
    borderColor: "$neutral50",
    borderStyle: "solid",
    boxSizing: "border-box",
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    marginTop: '$6',
    paddingLeft: "$16",
    paddingRight: "$16",
    paddingTop: "$8",
    paddingBottom: "$8",
    width: "100%",
    "+ div": {
        fill: "$medium",
    },
    "&:hover": {
        borderColor: "$primary",
        "+ div": {
            fill: "$primary",
        },
    },
    "&:disabled": {
        cursor: "not-allowed",
        backgroundColor: "$neutral50",
    },
    "&:focus": {
        boxShadow: shadows.focus,
    },
    "&::placeholder": {
        color: "$neutral50",
    },
});

const StyledContainer = styled("div", {
    position: "relative",
    width: "100%",
});

const StyledLabel = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    margin: 0,
});

const StyledRequired = styled("span", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$8",
    lineHeight: "$24",
    marginTop: '$4',
    color: '$primary',
    paddingLeft: '$4',
    paddingTop: '$4',
});

/**
 * This component accepts all the input props extended by the HTML5.
 *
 * @example
 *
 * return (
 *   <Input name="cars" id="cars" value="Any value..." />
 * )
 */
export const FormInput = ({ label, startAdornment, placeholder, required, error, ...props }) => {
    return (
        <StyledContainer>
            <StyledLabel>
                {label}
                {required &&
                    <StyledRequired>*</StyledRequired>
                }
            </StyledLabel>
            <StyledInput {...props} startAdornment={startAdornment} placeholder={placeholder} />
            <StyledRequired>{error}</StyledRequired>
        </StyledContainer>
    );
};

FormInput.displayName = "FormInput";
