import { styled } from "../../theme";
import { colors, space } from "../../tokens";
import no_media from "../../../assets/images/no_media_image.png";

import { IconLabelButton, Layout, Typography, Button, Divider } from "../../components";
import QRCode from "react-qr-code";
import { FiCopy } from "react-icons/fi";
import { priceWithCommas } from "../../../utilities/GenericFunction";

const StyledCard = styled("div", {
    backgroundColor: "$neutral",
    display: "flex",
    marginTop: '$8',
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    "> img": {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },

    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "30%",
                marginLeft: '$8',
            }
        },
    },
});

const StyledLayout = styled("div", {
    borderRadius: "$16",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$4",
    padding: "$16",
    paddingTop: 0,
    marginTop: 0,
    width: "100%",
});

const StyledTitle = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    color: "$secondary",
    margin: 0,
    padding: 0,
});

const StyledName = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$20",
    lineHeight: "$24",
    color: "$primary",
    margin: 0,
    padding: 0,
});


const StyledShare = styled("div", {
    position: "absolute",
    top: "$12",
    right: "$12",
    cursor: "pointer",
});

const StyledExpiry = styled("div", {
    position: "absolute",
    marginBottom: '4rem',
    right: '1.5rem'
});

const StyledShareLayout = styled("div", {
    background: "#000",
    width: '$32',
    height: '$32',
    borderRadius: "$96",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const StyledCodeLayout = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '$4'
});

const StyledCodeNameLayout = styled("div", {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: '$10'
});

const StyledCopyLayout = styled("div", {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1%'
});

const StyledTagLayout = styled("div", {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textAlign: 'center',
    gap: '$8'
})

const StyledTagListLayout = styled("div", {
    width: '55%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textAlign: 'center',
    gap: '$8',
    justifyContent: 'flex-start',
    alignItems: 'center'
});

const StyleTagsNameLayout = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
})

const StyledQRCodeLayout = styled('div', {
    width: '40%',
    display: 'flex',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
})

export const VoucherRedeemedCard = ({
    src,
    alt = '',
    title,
    amount,
    tags,
    code,
    onShareContent,
    onClickAction,
    expireDate,
    onClickCopyAction
}) => {
    return (
        <StyledCard mode={{
            "@initial": "mobile",
            "@bp3": "desktop",
        }}>
            <StyledShare>
                {onShareContent &&
                    <StyledShareLayout>
                        {onShareContent}
                    </StyledShareLayout>
                }
            </StyledShare>
            <img loading='eager' style={{ objectFit: src ? 'contain' : 'contain', border: `1px solid ${colors.low}`, borderBottomWidth: 0 }} src={src ? src : no_media} alt={alt} width='100%' height='100%' />
            <StyledLayout>
                <StyledCodeLayout>
                    <StyledCodeNameLayout>
                        <Typography variant='bodySmall'>{code}</Typography>
                    </StyledCodeNameLayout>
                    <StyledCopyLayout>
                        <Button variant={'neutral'} size={'small'} onClick={onClickCopyAction}>{`Copy your code here`} <FiCopy className='ms-2' />
                        </Button>
                    </StyledCopyLayout>
                </StyledCodeLayout>
                <Divider />
                <StyledTitle>{title}</StyledTitle>
                <StyledName><b>{priceWithCommas(amount ? amount : 0)}</b> Coins</StyledName>
                <StyledExpiry>
                    <Typography>{expireDate}</Typography>
                </StyledExpiry>
                <StyledTagLayout>
                    <StyledTagListLayout>
                        {
                            tags.length > 0 &&
                            tags.map((item, index) => {
                                return (
                                    <StyleTagsNameLayout>
                                        <Button variant={'neutral'} size={'extraSmall'}>{item.tag}</Button>
                                    </StyleTagsNameLayout>
                                )
                            })
                        }
                    </StyledTagListLayout>
                    <StyledQRCodeLayout>
                        <QRCode value={code} size={60} />
                    </StyledQRCodeLayout>
                </StyledTagLayout>

                <Layout css={{ width: '100%' }}>
                    <IconLabelButton
                        style={{ width: '100%', marginTop: '10px' }}
                        iconColor={'#000'}
                        variant="primary"
                        label={`Details`}
                        onClick={onClickAction}></IconLabelButton>
                </Layout>
            </StyledLayout>
        </StyledCard>
    );
};

VoucherRedeemedCard.displayName = "VoucherRedeemedCard";
