import { Layout } from "../../components";
import { SystemIcon } from "../../foundations";
import { shadows, styled } from "../../theme";

const StyledAnchor = styled("a", {
  // width:'$1',
  fill: "$primary",
  fontFamily: "$roboto",
  fontSize: "$16",
  lineHeight: "$150",
  fontWeight: "$600",
  borderRadius: "$8",
  textAlign: "center",
  boxSizing: "border-box",
  textDecoration: "none",
  display: "inline-flex",
  gap: "$24",
  "&:hover": {
    color: "inherit",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
});

const StyledLayout = styled("div", {
  paddingLeft: "$6",
  gap: "$4",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$32",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  margin: 0,
  padding: 0,
  color: '$high'
});

const StyledResend = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$600",
    fontSize: "$16",
    lineHeight: "$24",
    margin: 0,
    padding: 0,
    color: '$primary',
    cursor: 'pointer',
  });

export const VerificationSection = ({ icon, ...props }) => {
  return (
    <Layout  css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  }} >
      <StyledAnchor {...props}>
        {icon ? <SystemIcon size={'large'} icon={icon} color="inherit" /> : null}
      </StyledAnchor>
    </Layout>
  );
};

export const ActionLabel = ({ title, description, action, ...props }) => {
  return (
    <StyledLayout>
      <StyledTitle>
        {title}
      </StyledTitle>
      <StyledDescription>
        {description}
      </StyledDescription>
      <StyledResend {...props}>
        {action}
      </StyledResend>
    </StyledLayout>
  );
};

VerificationSection.ActionLabel = ActionLabel;
VerificationSection.DisplayName = "NetworkBadge";
