import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { OpinionsModule } from "./module";
import { Provider } from "react-redux";
import { store } from "../../store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <OpinionsModule />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
