import { useState } from "react";
import { styled } from "../../theme";

import { Favorite } from "../favorite/favorite";

const StyledRatingInputGroup = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "$8",
});

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param value - The value that represent the quantity of selected stars.
 * @param numOfStars - The number of stars to show.
 * @param onChange - The onChange function receives the value when the value change.
 *
 * @example
 *
 * return (
 *   <RatingInput value={2} numOfStars={5} onChange={...} />
 * )
 */
export const RatingInput = ({ value = 0, numOfStars = 5, onChange }) => {
  const [totalSelected, setTotalSelected] = useState(value);

  return (
    <StyledRatingInputGroup>
      {Array.from({ length: numOfStars }).map((_, index) => {
        const position = index + 1;

        return (
          <Favorite
            checked={totalSelected >= position}
            key={index}
            onClick={() => {
              setTotalSelected(position);
              onChange && onChange(position);
            }}
          />
        );
      })}
    </StyledRatingInputGroup>
  );
};

RatingInput.displayName = "RatingInput";
