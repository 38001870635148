import { styled } from "../../theme";
import { space } from "../../tokens";

const StyledContentCard = styled("div", {
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$16",
  padding: "$16",
  width: "100%",
  "> img": {
    borderRadius: "$8",
    objectFit: 'contain',
  },
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$20",
  lineHeight: "$120",
  color: "$secondary",
  margin: 0,
  padding: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '-webkit-fill-available'
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$120",
  color: "$medium",
  margin: 0,
  padding: 0,
});

/**
 * This component accepts only internal props
 *
 * @param src - The image source url.
 * @param alt - The image alt text.
 * @param title - The empty state title.
 * @param description - The empty state title.
 *
 * @example
 *
 * return (
 *   <ContentCard src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png" alt="A man..." title="Title" description="Description" />
 * )
 */
export const ContentCard = ({ src, alt, title, description }) => {
  return (
    <StyledContentCard>
      <img loading="eager" src={src} alt={alt} width="100%" height={140} />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledContentCard>
  );
};

ContentCard.displayName = "ContentCard";
