/** React library */
import React from "react";
/** React bootstrap */
import { Modal, Spinner } from "react-bootstrap";
/** Service */
import { get, post } from "../../../api";
import {
  addpost,
  communitybaseurl,
  profiledata,
  questionUrl,
  quizUrl,
} from "../../../utilities/apiconfig";
import constant from "../../../utilities/constant";
import eventconfig from "../../../utilities/eventconfig";
import { store } from "../../../store";

import { IoCloseOutline } from "react-icons/io5";
import { HiCheckCircle } from "react-icons/hi";
import { RiCloseCircleFill } from "react-icons/ri";

/** Style */
import "./PlayQuiz.scss";
import {
  Box,
  Button,
  IconLabelButton,
  Layout,
  QuizCongratsCard,
  QuizModalLevel,
  SingleLine,
  MultiLine,
  SingleNumeric,
  SingleChoice,
  MultiChoice,
  Rating,
} from "../../../design-system";
import { isMobile } from "react-device-detect";
import { FillBlanksQuizQuestion } from "../../../design-system/domain-components/quiz/fill-blanks";
import { SequenceQuizQuestion } from "../../../design-system/domain-components/quiz/sequence";
const QuizQuestionLevel = {
  LEVEL_ONE: "LEVEL_ONE",
  LEVEL_TWO: "LEVEL_TWO",
  LEVEL_THREE: "LEVEL_THREE",
};
export default class PlayQuizModal extends React.Component {
  constructor(props) {
    super(props);
    this.congratsRef = React.createRef();
    this.quizzesId = props?.quizId
      ? props.quizId
      : props?.match?.params?.postId;
    this.state = {
      quizStarted: true,
      levelStarted: true,
      quizzesId: null,
      quizzesData: {},
      quizQus: [],
      levelQuestions: {},
      profile: {},
      currentQuestionIndex: -1,
      questionForm: {
        singleChoiceAns: "",
        multipleChoiceAns: [],
        freeEntryAnswer: "",
        fillBlankAnswer: {
          isSelectQuestion: false,
          answer: "",
        },
        sequenceAnswers: [],
        rating: 0,
      },
      rating: 1,
      answerResults: {},
      pageLoading: true,
      answerSubmitLoading: false,
      formValid: false,
      contentLoading: false,
      quizCompleted: false,
      showAnswerModal: false,
      optionNotFound: false,
      skipLoading: false,
      company_name: localStorage.getItem(constant.company_name),
      completedLevel: 0,
      startedLevel: 1,
      completedQuestions: 0,
      firstTimeComing: false,
      showPoints: false,
      quizPoints: "",
      pointsAwardedPerQuiz: 0,
      emojis: constant.emojis,
      clientColor: store.getState().data.tenant.screenBackground,
      theme: store.getState().theme,
    };
    this.stepRange = 1;
  }
  componentDidMount() {
    this.getInitialData();
    let eventConfig = store.getState().data.eventConfig;
    this.setState({
      eventConfig,
      pointsAwardedPerQuiz:
        eventConfig?.QUIZ_ANSWER_SUBMITTED_CORRECT?.pointsAwardedPerEvent,
    });
  }
  getInitialData = async () => {
    try {
      const quizzes_res = await get(
        `${addpost}/${this.quizzesId}`,
        {},
        this.props.accessToken
      );
      const quiz_qus_res = await get(
        `${quizUrl}/${this.quizzesId}?page=1&perPage=20`,
        {},
        this.props.accessToken
      );
      const profile_res = await get(
        `${communitybaseurl}${profiledata}`,
        {},
        this.props.accessToken
      );
      const quizQus = quiz_qus_res.data.items;
      const levelQuestions = {
        [QuizQuestionLevel.LEVEL_ONE]: quizQus.filter(
          (x) => x.quizQuestionLevel === QuizQuestionLevel.LEVEL_ONE
        ),
        [QuizQuestionLevel.LEVEL_TWO]: quizQus.filter(
          (x) => x.quizQuestionLevel === QuizQuestionLevel.LEVEL_TWO
        ),
        [QuizQuestionLevel.LEVEL_THREE]: quizQus.filter(
          (x) => x.quizQuestionLevel === QuizQuestionLevel.LEVEL_THREE
        ),
      };
      const quizzesData = quizzes_res.data;
      const successRate = quizzesData?.successRate;
      const quizCompleted = quizzesData.completedQuestions === quizQus.length;
      let completedLevel = quizCompleted
        ? levelQuestions[QuizQuestionLevel.LEVEL_THREE].length > 0
          ? 3
          : levelQuestions[QuizQuestionLevel.LEVEL_TWO].length > 0
            ? 2
            : levelQuestions[QuizQuestionLevel.LEVEL_ONE].length > 0
              ? 1
              : 0
        : 0;
      completedLevel =
        quizCompleted && completedLevel === 0 ? 1 : completedLevel;
      let startedLevel = quizCompleted ? completedLevel + 1 : 1;
      const completedQuestions = quizzesData?.completedQuestions
        ? quizzesData?.completedQuestions
        : 0;
      if (completedQuestions > 0 && !quizCompleted) {
        this.setState({ firstTimeComing: true });
        switch (quizQus[completedQuestions - 1]?.quizQuestionLevel) {
          case QuizQuestionLevel.LEVEL_ONE:
            completedLevel = 1;
            break;
          case QuizQuestionLevel.LEVEL_TWO:
            completedLevel = 2;
            break;
          case QuizQuestionLevel.LEVEL_THREE:
            completedLevel = 3;
            break;
          default:
            completedLevel = 1;
            break;
        }
        /** For show next level question */
        switch (true) {
          case completedQuestions ===
            levelQuestions[QuizQuestionLevel.LEVEL_ONE].length:
            startedLevel = 2;
            break;
          case completedQuestions ===
            levelQuestions[QuizQuestionLevel.LEVEL_ONE].length +
              levelQuestions[QuizQuestionLevel.LEVEL_TWO].length:
            startedLevel = 3;
            break;
          case completedQuestions ===
            levelQuestions[QuizQuestionLevel.LEVEL_ONE].length +
              levelQuestions[QuizQuestionLevel.LEVEL_TWO].length +
              levelQuestions[QuizQuestionLevel.LEVEL_THREE].length:
            startedLevel = 4;
            break;
          default:
            startedLevel = completedLevel + 1;
            break;
        }
      }
      if (this.state.firstTimeComing && quizCompleted) {
        let eventConfig = store.getState().data.eventConfig;
        const _quizPoints =
          successRate < 100
            ? eventConfig[eventconfig.QUIZ_COMPLETED_WRONG]
            : eventConfig[eventconfig.QUIZ_COMPLETED_CORRECT];
        const quizPoints = _quizPoints?.pointsAwardedPerEvent
          ? _quizPoints.pointsAwardedPerEvent
          : "";
        this.setState({ showPoints: true, quizPoints });
      }
      const currentQuestionIndex =
        completedQuestions > 0
          ? completedQuestions === quizQus?.length
            ? quizQus?.length - 1
            : completedQuestions
          : quizQus?.length > 0
            ? 0
            : -1;
      this.setState(
        {
          quizzesData: quizzesData,
          quizQus: quizQus,
          profile: profile_res.data,
          currentQuestionIndex: currentQuestionIndex,
          pageLoading: false,
          quizCompleted,
          contentLoading: false,
          completedLevel,
          levelQuestions,
          startedLevel,
          quizStarted: completedQuestions > 0,
          completedQuestions,
        },
        () => {
          if (this.state.quizCompleted) {
            this.setState(
              {
                quizStarted: true,
              },
              () => {
                // this.congratsAnimation();
              }
            );
          }
        }
      );
    } catch (error) {}
  };
  startQuiz = () => {
    this.setState({
      quizStarted: true,
      levelStarted: true,
    });
  };
  getQuestionBlock = () => {
    let res = null;
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    switch (currentQuestion.type) {
      case "SINGLE_CHOICE":
        res = this.single_choice();
        break;
      case "MULTI_CHOICE":
        res = this.multiple_choice();
        break;
      case "FREE_TEXT":
        const free_text = this.freeEntry(currentQuestion.freeEntryType);
        res = free_text.res;
        break;
      case "RATING":
        res = this.render_rating();
        break;
      case "FILL_BLANKS":
        return this.fill_blanks();
      case "SEQUENCE":
        return this.sequence();
      default:
        break;
    }
    return res;
  };
  /**
   *
   * @param {'SINGLE_LINE' | 'MULTI_LINE' | 'NUMERIC' } freeEntryType
   * @returns
   */
  freeEntry = (freeEntryType) => {
    let res = null;
    let optionNotFound = false;
    switch (freeEntryType) {
      case "SINGLE_LINE":
        res = this.single_line();
        break;
      case "MULTI_LINE":
        res = this.multi_line();
        break;
      case "NUMERIC":
        res = this.numeric_choice();
        break;
      default:
        optionNotFound = true;
        break;
    }
    return { res, optionNotFound: optionNotFound };
  };
  free_text_value = (event) => {
    const value = event.target.value;
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        freeEntryAnswer: value,
      },
      formValid: !!value,
    });
  };
  /**
   *
   * @param {Event} event
   */
  submitChoice = async (event) => {
    event.preventDefault();
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    const movingNextLevel =
      this.state.quizQus[this.state.currentQuestionIndex]?.quizQuestionLevel !==
      this.state.quizQus[this.state.currentQuestionIndex + 1]
        ?.quizQuestionLevel;

    if (this.state.formValid) {
      this.setState({ answerSubmitLoading: true });
      try {
        let data = [];
        switch (currentQuestion.type) {
          case "SINGLE_CHOICE":
            data = {
              quizQuestionOptions: [this.state.questionForm.singleChoiceAns],
              freeEntryAnswer:
                this.state.questionForm.freeEntryAnswer || undefined,
            };
            break;
          case "MULTI_CHOICE":
            data = {
              quizQuestionOptions: this.state.questionForm.multipleChoiceAns,
              freeEntryAnswer:
                this.state.questionForm.freeEntryAnswer || undefined,
            };
            break;
          case "FREE_TEXT":
            data = { freeEntryAnswer: this.state.questionForm.freeEntryAnswer };
            break;
          case "RATING":
            data = { freeEntryAnswer: this.state.questionForm.rating };
            break;
          case "FILL_BLANKS":
            data = this.state.questionForm.fillBlankAnswer.isSelectQuestion
              ? {
                  quizQuestionOptions: [
                    this.state.questionForm.fillBlankAnswer.answer,
                  ],
                }
              : {
                  freeEntryAnswer:
                    this.state.questionForm.fillBlankAnswer.answer,
                };
            break;
          case "SEQUENCE":
            data = {
              quizQuestionOptions: this.state.questionForm.sequenceAnswers.map(
                (ans) => ans.id
              ),
            };
            break;

          default:
            break;
        }

        console.log(data);
        const questionPost = await post(
          `${questionUrl}/${currentQuestion.id}`,
          data,
          this.props.accessToken
        );
        const answerResults = this.state.answerResults;
        answerResults[currentQuestion.id] = questionPost.data;
        const quizQus = this.state.quizQus;
        const quiz_qus_index = quizQus.findIndex(
          (x) => x.id === currentQuestion.id
        );
        if (quiz_qus_index !== -1) {
          quizQus[quiz_qus_index] = questionPost.data;
        }
        this.setState(
          {
            answerResults,
            answerSubmitLoading: false,
            questionForm: {
              ...this.state.questionForm,
              singleChoiceAns: "",
              multipleChoiceAns: [],
              freeEntryAnswer: "",
              rating: 0,
            },
            formValid: false,
            currentQuestionIndex:
              this.state.currentQuestionIndex + 1 === this.state.quizQus.length
                ? this.state.currentQuestionIndex
                : this.state.currentQuestionIndex + 1,
            contentLoading: true,
            quizCompleted:
              this.state.currentQuestionIndex + 1 === this.state.quizQus.length,
            levelStarted: !movingNextLevel,
            completedLevel: movingNextLevel
              ? this.state.completedLevel + 1
              : this.state.completedLevel,
            startedLevel: movingNextLevel
              ? this.state.startedLevel + 1
              : this.state.startedLevel,
            completedQuestions: this.state.currentQuestionIndex + 1,
            quizQus,
          },
          () => {
            if (movingNextLevel && this.props.movingNextLevel) {
              this.props.movingNextLevel();
            }
            if (this.state.quizCompleted) {
              this.getInitialData();
              //this.congratsAnimation();
            } else {
              setTimeout(() => {
                this.setState({
                  contentLoading: false,
                });
              }, 500);
            }
          }
        );
      } catch (error) {}
    }
  };

  ratingValue = (value) => {
    let actualRangeValue = value * this.stepRange;
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        rating: actualRangeValue,
      },
      formValid: !!value,
      rating: value,
    });
  };

  onChangeRating = (value) => {
    this.ratingValue(value);
  };

  // congratsAnimation = (retry = 1) => {
  //     if (this.congratsRef) {
  //         this.confettiFul['animation'] = new ConfettiFul(this.congratsRef.current);
  //         setTimeout(() => {
  //             this.congratsRef.current.remove();
  //         }, 5000);
  //     } else {
  //         if (retry < 11) {
  //             setTimeout(() => {
  //                 this.congratsAnimation(retry + 1);
  //             }, 500);
  //         }
  //     }
  // }
  /**
   *
   * @param {array} assets
   * @returns html template
   */
  commonAssetsSection = (assets) => {
    return assets.length === 1 ? (
      <div className="w-100 mt-2">
        <img
          src={assets[0].url}
          alt="single"
          className="img-fluid"
          style={{ width: "200px", height: "200px" }}
        ></img>
      </div>
    ) : (
      <div className="d-flex align-items-center flex-wrap mt-2">
        {assets.map((asset, index) => (
          <div key={index} className="w-50 p-2">
            <img
              src={asset.url}
              alt={`multiple_${index}`}
              style={{ width: "200px", height: "150px", borderRadius: "8px" }}
              className="img-fluid"
            ></img>
          </div>
        ))}
      </div>
    );
  };
  /**
   * ===============================================
   * ===           Single Choice Start           ===
   * ===============================================
   */
  changeSingleChoiceAns = (value) => {
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        singleChoiceAns: value,
        free_text_value: "",
      },
      formValid: true,
    });
  };
  single_choice = () => {
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    return (
      <SingleChoice
        question={currentQuestion}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        onChange={this.changeSingleChoiceAns}
        value={this.state.questionForm.singleChoiceAns}
        changeOtherOption={this.free_text_value}
      />
      // <div className="single-choice">
      //     <div className={`question p-4 ${currentQuestion?.assets?.length === 0 ? 'remove-border' : ''}`}>
      //         <div>
      //             {currentQuestion.text}
      //         </div>
      //         {
      //             currentQuestion?.assets?.length > 0 &&
      //             this.commonAssetsSection(currentQuestion?.assets)
      //         }
      //     </div>
      //     <div className="answer p-3">
      //         {
      //             currentQuestion && currentQuestion.quizQuestionOptions.map((answer, index) =>
      //                 <div className="radio-button-group" key={`radio_${index}`}>
      //                     <label className="form-check">
      //                         <input className="form-check-input non-green" type="radio" value={answer.id} name="single_choice" id={`radio_${index}`} onChange={this.changeSingleChoiceAns} />
      //                         <label className="form-check-label ms-3" htmlFor={`radio_${index}`}>
      //                             {answer.text}
      //                         </label>
      //                     </label>
      //                 </div>
      //             )
      //         }
      //     </div>
      // </div>
    );
  };
  /**
   * ===============================================
   * ===            Single Choice End            ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===          Multiple Choice Start          ===
   * ===============================================
   */

  ChangeMultipleChoiceValue = (value, isOtherOption) => {
    const multipleChoiceAns = this.state.questionForm.multipleChoiceAns;
    const index = multipleChoiceAns.findIndex((x) => value === x);

    let removeFreeEntryAnswer = false;

    if (index !== -1) {
      multipleChoiceAns.splice(index, 1);

      if (isOtherOption) removeFreeEntryAnswer = true;
    } else {
      multipleChoiceAns.push(value);
    }

    const freeEntryAnswer = removeFreeEntryAnswer
      ? undefined
      : this.state.questionForm.freeEntryAnswer;
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        multipleChoiceAns,
        freeEntryAnswer,
      },
      formValid: multipleChoiceAns.length > 0,
    });
  };

  multiple_choice = () => {
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    return (
      <MultiChoice
        question={currentQuestion}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        onChange={this.ChangeMultipleChoiceValue}
        value={this.state.questionForm.multipleChoiceAns}
        onChangeFreeText={this.free_text_value}
      />
    );
  };
  /**
   * ===============================================
   * ===           Multiple Choice End           ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===        Single Line Choice Start         ===
   * ===============================================
   */

  single_line = () => {
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    return (
      <>
        <SingleLine
          question={currentQuestion.text}
          assets={this.commonAssetsSection(currentQuestion?.assets)}
          placeholder="Type your answer here..."
          onChange={this.free_text_value}
        />
        {/* <div className="free-text-single">
                    <div className={`question p-4 ${currentQuestion?.assets?.length === 0 ? 'remove-border' : ''}`}>
                        <div>
                            {currentQuestion.text}
                        </div>
                        {
                            currentQuestion?.assets?.length > 0 &&
                            this.commonAssetsSection(currentQuestion?.assets)
                        }
                    </div>
                    <div className="answer p-3">
                        <div className="input-group">
                            <input className="form-control answer-text-dark" placeholder="Enter answer here" type="text" onChange={this.free_text_value} />
                        </div>
                    </div>
                </div> */}
      </>
    );
  };
  /**
   * ===============================================
   * ===         Single Line Choice End          ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===        Rating Choice Start         ===
   * ===============================================
   */
  render_rating = () => {
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    let maxRange = currentQuestion.maxRange;

    let maxRating = maxRange;
    // if (maxRange > 5) {
    //     this.stepRange = maxRange / 5
    //     maxRating = 5
    // }
    return (
      <Rating
        question={currentQuestion.text}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        onChange={this.onChangeRating}
        min={0}
        max={maxRating}
        value={this.state.rating}
        stepRange={this.stepRange}
      />
      // <div className="answer p-3">
      //     <div className='d-flex flex-row' style={{}}>
      //         <div className='d-flex flex-start' style={{ width: '97.5%' }}>
      //             <span className="p-1 text-center" style={{ width: '40px', backgroundColor: '#' + this.state.clientColor + '70' }}>{0}</span>
      //         </div>
      //         <div className='d-flex flex-end'>
      //             <span className="p-1 text-center" style={{ width: '40px', backgroundColor: '#' + this.state.clientColor + '70' }}>{this.state.rating}</span>
      //         </div>
      //     </div>
      // </div>
    );
  };
  /**
   * ===============================================
   * ===        Rating Choice End         ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===         Multi Line Choice Start         ===
   * ===============================================
   */
  multi_line = () => {
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    return (
      <MultiLine
        question={currentQuestion.text}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        placeholder="Type your answer here..."
        onChange={this.free_text_value}
      />
    );
  };
  /**
   * ===============================================
   * ===          Multi Line Choice End          ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===           Numeric Choice Start          ===
   * ===============================================
   */

  numeric_value = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      const value = e.target.value;
      this.setState({
        questionForm: {
          ...this.state.questionForm,
          freeEntryAnswer: value,
        },
        formValid: !!value,
      });
    }
  };
  numeric_choice = () => {
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    return (
      <SingleNumeric
        question={currentQuestion.text}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        placeholder="Type your answer here..."
        onChange={this.numeric_value}
      />
    );
  };
  /**
   * ===============================================
   * ===            Numeric Choice End           ===
   * ===============================================
   */

  handle_change_fill_blanks = (answer, isSelectQuestion) =>
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        fillBlankAnswer: { isSelectQuestion, answer },
      },
      formValid: !!answer,
    });

  fill_blanks = () => {
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];

    return (
      <FillBlanksQuizQuestion
        question={currentQuestion}
        onChange={this.handle_change_fill_blanks}
        qnAnswer={this.state.questionForm.fillBlankAnswer.answer}
      />
    );
  };

  change_sequence = (sequenceAnswers = []) =>
    this.setState({
      questionForm: { ...this.state.questionForm, sequenceAnswers },
      formValid: true,
    });

  sequence = () => {
    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];

    return (
      <SequenceQuizQuestion
        question={currentQuestion}
        answer={this.state.questionForm.sequenceAnswers}
        onChange={this.change_sequence}
      />
    );
  };

  correctAnsCount = () => {
    return this.state?.quizQus?.filter((x) => x?.answeredCorrectly)?.length;
  };
  openAnswerModal = () => {
    this.setState({
      showAnswerModal: true,
    });
  };
  hideAnswerModal = () => {
    this.setState({
      showAnswerModal: false,
    });
  };
  backToHome = () => {
    this.props.history.push({ pathname: "/" });
  };
  startNextLevel = () => {
    this.setState({
      levelStarted: true,
    });
  };
  skipQuestion = async () => {
    this.setState({ skipLoading: true });

    const currentQuestion = this.state.quizQus[this.state.currentQuestionIndex];
    const movingNextLevel =
      this.state.quizQus[this.state.currentQuestionIndex]?.quizQuestionLevel !==
      this.state.quizQus[this.state.currentQuestionIndex + 1]
        ?.quizQuestionLevel;

    try {
      let data = {};
      switch (currentQuestion.type) {
        case "SINGLE_CHOICE":
          const s_quizQuestionOptions = currentQuestion.quizQuestionOptions;
          data = { quizQuestionOptions: [s_quizQuestionOptions[0].id] };
          break;
        case "MULTI_CHOICE":
          const m_quizQuestionOptions = currentQuestion.quizQuestionOptions;
          data = { quizQuestionOptions: [m_quizQuestionOptions[0].id] };
          break;
        case "FREE_TEXT":
          data = { freeEntryAnswer: "" };
          break;
        case "FILL_BLANKS":
          data = this.state.questionForm.fillBlankAnswer.isSelectQuestion
            ? {
                quizQuestionOptions: [],
              }
            : {
                freeEntryAnswer: "",
              };
          break;

        case "SEQUENCE":
          data = { quizQuestionOptions: [] };

          break;
        default:
          break;
      }
      data["skipped"] = true;
      const questionPost = await post(
        `${questionUrl}/${currentQuestion.id}`,
        data
      );
      const answerResults = this.state.answerResults;
      answerResults[currentQuestion.id] = questionPost.data;
      const quizQus = this.state.quizQus;
      const quiz_qus_index = quizQus.findIndex(
        (x) => x.id === currentQuestion.id
      );
      if (quiz_qus_index !== -1) {
        quizQus[quiz_qus_index] = questionPost.data;
      }
      this.setState(
        {
          answerResults,
          answerSubmitLoading: false,
          questionForm: {
            ...this.state.questionForm,
            singleChoiceAns: "",
            multipleChoiceAns: [],
            freeEntryAnswer: "",
          },
          formValid: false,
          currentQuestionIndex:
            this.state.currentQuestionIndex + 1 === this.state.quizQus.length
              ? this.state.currentQuestionIndex
              : this.state.currentQuestionIndex + 1,
          contentLoading: true,
          quizCompleted:
            this.state.currentQuestionIndex + 1 === this.state.quizQus.length,
          skipLoading: false,
          levelStarted: !movingNextLevel,
          completedLevel: movingNextLevel
            ? this.state.completedLevel + 1
            : this.state.completedLevel,
          startedLevel: movingNextLevel
            ? this.state.startedLevel + 1
            : this.state.startedLevel,
          completedQuestions: this.state.currentQuestionIndex + 1,
          quizQus,
        },
        () => {
          if (movingNextLevel && this.props.movingNextLevel) {
            this.props.movingNextLevel();
          }
          if (this.state.quizCompleted) {
            this.getInitialData();
            //this.congratsAnimation();
          } else {
            setTimeout(() => {
              this.setState({
                contentLoading: false,
                skipLoading: false,
              });
            }, 500);
          }
        }
      );
    } catch (error) {
      this.setState({
        skipLoading: false,
      });
    }
  };
  render() {
    return (
      <div className="play-quiz-container">
        {this.state.pageLoading ? (
          <div className="page-loading w-100">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Spinner
                as="div"
                animation="border"
                role={"status"}
                variant="danger"
              />
            </div>
          </div>
        ) : this.state.quizCompleted ||
          (!this.state.levelStarted &&
            this.state.startedLevel > 0 &&
            !this.state.quizCompleted) ? (
          <Layout
            css={{
              margin: "$16",
              display: "block",
            }}
          >
            <QuizCongratsCard
              src={this.state.quizzesData?.assets?.[0]?.url}
              completedLevel={this.state.completedLevel}
            />
            <Layout
              css={{
                width: "100%",
                marginTop: "$16",
              }}
            >
              <IconLabelButton.Neutral
                label="View Answers"
                style={{ width: "100%" }}
                onClick={this.openAnswerModal}
              />
            </Layout>

            <Layout
              css={{
                width: "100%",
                marginTop: "$8",
              }}
            >
              <IconLabelButton
                variant={"primary"}
                label="Back to home"
                style={{ width: "100%" }}
                onClick={this.backToHome}
              />
            </Layout>
            {!this.state.quizCompleted && !this.props.isModule && (
              <Layout
                css={{
                  width: "100%",
                  marginTop: "$8",
                }}
              >
                <IconLabelButton
                  variant={"primary"}
                  label={`Continue Quiz - Level ${this.state.startedLevel}`}
                  style={{ width: "100%" }}
                  onClick={this.startNextLevel}
                />
              </Layout>
            )}
          </Layout>
        ) : this.state.contentLoading ? (
          <div className="content-loading">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Spinner
                as="div"
                animation="border"
                role={"status"}
                variant="danger"
              />
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-start justify-content-center">
            {this.state.quizQus.length > 0 && (
              <Layout
                css={{
                  width: "100%",
                }}
              >
                <Box
                  css={{
                    padding: "$24",
                    background: "$tulip10",
                    borderRadius: "$16",
                    gap: "$32",
                    width: "100%",
                  }}
                >
                  <QuizModalLevel level={this.state.startedLevel} />
                  {this.getQuestionBlock()}

                  <Layout
                    css={{
                      display: isMobile ? "block" : "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "$32",
                    }}
                  >
                    <Button
                      style={{
                        width: isMobile ? "100%" : "fit-content",
                        backgroundColor: this.state.theme.primary,
                        color: this.state.theme.neutral,
                      }}
                      variant={"neutral"}
                      onClick={this.skipQuestion}
                    >
                      Skip question
                    </Button>
                    <Button
                      style={{
                        width: isMobile ? "100%" : "fit-content",
                        marginTop: isMobile ? "10px" : 0,
                        backgroundColor: this.state.theme.primary,
                        color: this.state.theme.neutral,
                      }}
                      onClick={this.submitChoice}
                    >
                      Submit answer
                    </Button>
                  </Layout>
                </Box>
              </Layout>
            )}
            {this.state.quizQus.length === 0 && (
              <div className="h5 mb-0">Questions not added</div>
            )}
          </div>
        )}
        {/* View Answer modal */}
        <Modal
          show={this.state.showAnswerModal}
          onHide={this.hideAnswerModal}
          className="answer-modal"
          centered
          scrollable
        >
          <Modal.Header>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="header-left">Answers</div>
              <div className="d-flex align-items-center header-right">
                <div className="mb-0">
                  You Scored {this.correctAnsCount()}/
                  {this.state.quizQus.length}
                </div>
                <div className="ms-2" onClick={this.hideAnswerModal}>
                  <IoCloseOutline className="mb-0 close-icon" />
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.state.quizQus
                .slice(0, this.state.completedQuestions)
                .map((quiz, index) => (
                  <div
                    className={`quiz ${index > 0 ? "mt-3" : ""}`}
                    key={`quiz_${index}`}
                  >
                    <div className="d-flex align-items-start">
                      <div className="quiz-number me-2">{index + 1}.</div>
                      <div className="quiz-content">
                        <div className="question">{quiz?.text}</div>
                        <div className="py-3 ps-2">
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="left-side">
                              {quiz?.type === "SINGLE_CHOICE" && (
                                <div>
                                  {quiz?.quizQuestionOptions.map((ans, j) => (
                                    <div
                                      className="form-check form-check-inline"
                                      key={`ans_${index}_${j}`}
                                    >
                                      <input
                                        className={`form-check-input non-green ${ans.checkedByMe && !quiz.skipped ? (ans.checkedByMe && ans.correct ? "correct-answer" : "wrong-answer") : ans.correct ? "correct-answer" : ""} opacity-100`}
                                        type="radio"
                                        id={`radio_ans_${index}`}
                                        name={`radio_ans_${index}_${j}`}
                                        readOnly
                                        disabled
                                        defaultChecked={
                                          (ans.checkedByMe && !quiz.skipped) ||
                                          ans.correct
                                        }
                                      />
                                      <label
                                        className={`form-check-label ms-3 ${ans.checkedByMe && !quiz.skipped ? (ans.checkedByMe && ans.correct ? "correct-answer" : "wrong-answer") : ans.correct ? "correct-answer" : ""} opacity-100`}
                                        htmlFor={`radio_ans_${index}_${j}`}
                                      >
                                        {ans.text}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {quiz?.type === "MULTI_CHOICE" && (
                                <div>
                                  {quiz?.quizQuestionOptions.map((ans, j) => (
                                    <div
                                      className="form-check form-check-inline"
                                      key={`ans_checkbox_${index}_${j}`}
                                    >
                                      <input
                                        className={`form-check-input ${ans.checkedByMe && !quiz.skipped ? (ans.checkedByMe && ans.correct ? "correct-answer" : "wrong-answer") : ans.correct ? "correct-answer" : ""} opacity-100`}
                                        type="checkbox"
                                        id={`checkbox_ans_${index}`}
                                        name={`checkbox_ans_${index}_${j}`}
                                        readOnly
                                        disabled
                                        defaultChecked={
                                          (ans.checkedByMe && !quiz.skipped) ||
                                          ans.correct
                                        }
                                      />
                                      <span className="mark"></span>
                                      <label
                                        className={`form-check-label ms-3 ${ans.checkedByMe && !quiz.skipped ? (ans.checkedByMe && ans.correct ? "correct-answer" : "wrong-answer") : ans.correct ? "correct-answer" : ""} opacity-100`}
                                        htmlFor={`checkbox_ans_${index}_${j}`}
                                      >
                                        {ans.text}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {(quiz?.type === "FREE_TEXT" ||
                                quiz?.type === "RATING") && (
                                <div className="h5 mb-0">
                                  {quiz?.myFreeEntryAnswer}
                                </div>
                              )}
                            </div>
                            <div className="right-side me-4">
                              {quiz?.answeredCorrectly ? (
                                <HiCheckCircle className="h3 mb-0 correct-answer" />
                              ) : (
                                <RiCloseCircleFill className="h3 mb-0 wrong-answer" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
