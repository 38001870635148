import styled from "styled-components";
import { FormControl, Image } from "react-bootstrap";
import { colors } from "../../../tokens";
import { isValidElement } from "react";
import { Button } from "../../../components";

const BlankStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 52px;
  width: 120px;
  background-color: ${colors.neutral};
  border: 1px solid ${colors.neutral100};
  border-radius: 8px;
  font-size: 22px;
  font-weight: 600;
`;

export const FillBlanksQuizQuestion = ({ question, qnAnswer, onChange }) => {
  const isSelectQuestion = !!question.quizQuestionOptions.length;

  const answer = isSelectQuestion
    ? question.quizQuestionOptions.find((item) => item.id === qnAnswer)?.text ||
      "?"
    : qnAnswer;

  const handleChange = (answer) => onChange(answer, isSelectQuestion);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <div style={{ fontSize: 24, fontWeight: 600 }}>{question.text}</div>
      {!!question.assets.length && (
        <Image
          style={{
            width: "100%",
            height: "200px",
            objectFit: "contain",
            borderRadius: 8,
          }}
          src={question.assets[0].url}
        />
      )}
      <SubTextSelectBlock
        subText={question.subText}
        isSelectQuestion={isSelectQuestion}
        onChange={handleChange}
        answer={answer}
      />
      {isSelectQuestion && (
        <QuestionOptions
          options={question.quizQuestionOptions}
          onChange={handleChange}
          answer={answer}
        />
      )}
    </div>
  );
};

const QuestionOptions = ({
  options = [],
  onChange = () => {},
  answer = "",
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", gap: 10 }}>
        {options.map((opt) => (
          <Button
            key={opt.id}
            onClick={() => onChange(opt.id)}
            disabled={answer === opt.id}
          >
            {opt.text}
          </Button>
        ))}
      </div>
    </div>
  );
};

const SubTextSelectBlock = ({
  subText = "",
  answer,
  isSelectQuestion = false,
  onChange = () => {},
}) => {
  const subTextSplitted = subText.split("[BLANK]");
  if (subTextSplitted.length > 1) {
    subTextSplitted.splice(
      1,
      0,
      <Blank
        key="blank"
        isSelectQuestion={isSelectQuestion}
        answer={answer}
        onChange={onChange}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 10,
        padding: "24px 0",
        border: `1px solid ${colors.neutral100}`,
        borderRadius: 8,
      }}
    >
      {subTextSplitted.map((item, index) =>
        isValidElement(item) ? (
          item
        ) : (
          <div style={{ fontSize: 22, fontWeight: 600 }} key={index}>
            {item}
          </div>
        )
      )}
    </div>
  );
};

const Blank = ({
  isSelectQuestion = true,
  answer = "",
  onChange = () => {},
}) => {
  const renderContent = () => {
    if (isSelectQuestion) return answer || "?";

    return (
      <FormControl
        type="text"
        onChange={(e) => onChange(e.target.value)}
        style={{
          backgroundColor: "transparent",
          border: "none",
          height: "100%",
          fontSize: 22,
          fontWeight: 600,
        }}
      />
    );
  };

  return <BlankStyle>{renderContent()}</BlankStyle>;
};
