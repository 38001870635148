import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";

const StyledEmptyItemState = styled("div", {
  fill: "$secondary",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "$16",
  padding: "$16",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$18",
  lineHeight: "$150",
  color: "$secondary",
  textAlign: "center",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$120",
  color: "$medium",
  textAlign: "center",
  margin: 0,
  padding: 0,
});

/**
 * This component accepts only internal props
 *
 * @param icon - All the possible icons available on https://phosphoricons.com
 * @param title - The empty state title.
 * @param description - The empty state title.
 *
 * @example
 *
 * return (
 *   <EmptyItemState icon="NewspaperClipping" title="Title" description="Description" />
 * )
 */
export const EmptyItemState = ({ icon, title, description }) => {
  return (
    <StyledEmptyItemState>
      <SystemIcon icon={icon} size="large" color="inherit" />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledEmptyItemState>
  );
};

EmptyItemState.displayName = "EmptyItemState";
