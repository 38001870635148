import { v4 as uuid } from "uuid";
import qs from "qs";

import { Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { colors } from "../../design-system/tokens";
import { Button } from "../../design-system";
import { useCallback, useEffect, useState } from "react";
import { get, post } from "../../api";
import { checkExists, onboard } from "../../utilities/apiconfig";
import { store } from "../../store";
import { checkNicknameValidityInfinite } from "./lib";

const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const RegisterSteps = ({ setTokens = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrorState] = useState("");

  const { theme } = store.getState();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError: setErrorForm,
    watch,
  } = useForm();

  const email = watch("email");

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const nickname = await checkNicknameValidityInfinite(
        data.email.split("@")[0]
      );

      const extendedData = {
        ...data,
        nickname,
        phone: data.phone || null,
        password: uuid(12),
        gender: "UNSPECIFIED",
      };

      post(onboard, extendedData)
        .then((res) => {
          const {
            data: { accessToken },
          } = res;

          setTokens({
            accessToken: accessToken.accessToken,
            refreshToken: accessToken.refreshToken,
          });
        })
        .catch((e) => setErrorState(e.data.message))
        .finally(() => setIsLoading(false));
    } catch (e) {
      setErrorState(e.message);

      setIsLoading(false);
    }
  };

  const checkValidity = useCallback(
    async ({ name, value, regexp, message }) => {
      if (!value || (regexp && !regexp.test(value))) return;

      setErrorState(null);
      const data = await get(
        `${checkExists}?${qs.stringify({ [name]: value })}`
      );

      if (data.data[name]) {
        if (!errors[name]?.message) return setErrorForm(name, { message });
      } else if (typeof data.data[name] === "boolean" && !data.data[name]) {
        return setErrorForm(name, null);
      }

      setErrorState("Something went wrong");
    },
    [errors, setErrorForm]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      checkValidity({
        name: "email",
        regexp: emailRegExp,
        value: email,
        message: "Email should be unique",
      });
    }, 600);

    return () => clearTimeout(timeOut);
  }, [checkValidity, email]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 6,

          backgroundColor: theme.neutral,
          padding: 12,
          border: `1px solid ${theme.medium}`,
          borderRadius: 8,

          maxWidth: 360,
          width: "80vw",
          minWidth: 120,
        }}
      >
        <div style={{ textAlign: "center", fontSize: 18, fontWeight: 600 }}>
          Register
        </div>
        <Form.Group
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            fontSize: 14,
          }}
        >
          <Form.Label style={{ display: "block", marginBottom: 5 }}>
            First name<span color={colors.red}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            style={{ padding: "4px 12px", width: "100%" }}
            placeholder="First name"
            {...register("name", {
              required: true,
              maxLength: 20,
            })}
          />
          {errors.name?.type === "required" && (
            <p role="alert" style={{ color: colors.red }}>
              First name is required
            </p>
          )}
          {errors.name?.message && (
            <p role="alert" style={{ color: colors.red }}>
              {errors.name?.message}
            </p>
          )}
        </Form.Group>

        <Form.Group
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            fontSize: 14,
          }}
        >
          <Form.Label style={{ display: "block", marginBottom: 5 }}>
            Last name<span color={colors.red}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            style={{ padding: "4px 12px", width: "100%" }}
            {...register("surname", { required: true, maxLength: 20 })}
            placeholder="Last name"
          />
          {errors.surname?.type === "required" && (
            <p role="alert" style={{ color: colors.red }}>
              Last name is required
            </p>
          )}
        </Form.Group>
        <Form.Group
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            fontSize: 14,
          }}
        >
          <Form.Label
            style={{
              display: "block",
              marginBottom: 5,
            }}
          >
            Email<span color={colors.red}>*</span>
          </Form.Label>
          <Form.Control
            type="email"
            style={{ padding: "4px 12px", width: "100%" }}
            {...register("email", {
              required: true,
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "Entered value does not match email format",
              },
            })}
            placeholder="Email"
          />
          {errors.email?.message && (
            <p role="alert" style={{ color: colors.red }}>
              {errors.email.message}
            </p>
          )}
          {errors.email?.type === "required" && (
            <p role="alert" style={{ color: colors.red }}>
              Email is required
            </p>
          )}
          {errors.nickname?.message && (
            <p role="alert" style={{ color: colors.red }}>
              {errors.nickname?.message}
            </p>
          )}
        </Form.Group>
        <Form.Group
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            fontSize: 14,
          }}
        >
          <Form.Label style={{ display: "block", marginBottom: 5 }}>
            Phone (without +)
          </Form.Label>
          <Form.Control
            type="text"
            style={{ padding: "4px 12px", width: "100%" }}
            placeholder="Phone number"
            {...register("phone", {
              required: false,

              pattern: {
                value: /^[0-9]*$/,
                message: "Please, enter valid phone number",
              },
            })}
          />
          {errors.phone?.message && (
            <p role="alert" style={{ color: colors.red }}>
              {errors.phone.message}
            </p>
          )}
          {errors.phone?.message && (
            <p role="alert" style={{ color: colors.red }}>
              {errors.phone?.message}
            </p>
          )}
        </Form.Group>
        {error && <div style={{ color: colors.red }}>{error}</div>}
        <Button
          disabled={isLoading || !isValid}
          style={{ backgroundColor: theme.primary, color: theme.neutral }}
        >
          <div style={{ display: "flex" }}>
            <div>Start Opinion</div>
            {isLoading && <Spinner style={{ height: 24, width: 24 }} />}
          </div>
        </Button>
      </div>
    </Form>
  );
};
