import { SystemIcon } from "../../foundations";
import { shadows, styled } from "../../theme";
import { space } from "../../tokens";
import { Avatar } from "../avatar/avatar";
import { Layout } from "../layout/layout";
import { PromotedCard } from "../promoted-card/promoted-card";

const StyledPromotedContentCard = styled("div", {
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "$16",
  padding: "$16",
  position: "relative",
  width: "fit-content",
  "> img": {
    borderRadius: "$8",
  },
  "> button": {
    width: "100%",
  },
  variants: {
    mode: {
      mobile: {
        width: "100%",
        marginLeft: 0,
      },
      desktop: {
        width: "24%",
      }
    },
  },
});

const StyledUserInfoContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});

const StyledUserInfo = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "$8",
  cursor: "pointer",
});

const StyledUserButton = styled("button", {
  all: "unset",
  fill: "$primary",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$4",
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$12",
  lineHeight: "$120",
  "&:focus": {
    boxShadow: shadows.focus,
  },
});

const StyledUserInfoTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$14",
  lineHeight: "$120",
  color: "$secondary",
  margin: 0,
  padding: 0,
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$120",
  color: "$secondary",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$120",
  color: "$medium",
  margin: 0,
  padding: 0,
});

/**
 * This component accepts only internal props
 *
 * @param type - reel | vlog | blog
 * @param src - The image source url.
 * @param alt - The image alt text.
 * @param title - The empty state title.
 * @param description - The empty state title.
 * @param userAvatarSrc - The user image source url.
 * @param userAvatarName - The user name.
 * @param userOnClick - The onClick function receives the value when the user click in the user action.
 *
 * @example
 *
 * return (
 *   <PromotedContentCard
 *       src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"
 *       alt="A man..."
 *       type="reel"
 *       title="Content Title"
 *       description="Posted 2 days ago"
 *       userAvatarSrc="https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?&w=128&h=128&dpr=2&q=80"
 *       userAvatarName="Dudu Torres"
 *       userOnClick={() => null}
 *     />
 *   )
 */
export const PromotedContentCard = ({
  type,
  src,
  alt = '',
  title,
  description,
  userAvatarSrc,
  userAvatarName,
  userAvatarClick,
  userOnClick,
  followLabel,
  isNotLoggedInUserPost,
  itemClick,
  dropdownContent = null
}) => {
  return (
    <StyledPromotedContentCard mode={{
      "@initial": "mobile",
      "@bp3": "desktop",
    }}>
      <StyledUserInfoContainer>
        <StyledUserInfo onClick={userAvatarClick}>
          <Avatar src={userAvatarSrc} size="small" alt="User image" />
          <StyledUserInfoTitle>{userAvatarName}</StyledUserInfoTitle>
        </StyledUserInfo>
        <div style={{display: 'flex', alignItems:'center'}}>
          {isNotLoggedInUserPost &&
            <StyledUserButton onClick={userOnClick}>
              <SystemIcon icon="Plus" size="small" color="inherit" />
              {followLabel}
            </StyledUserButton>
          }
          {dropdownContent &&
            <div>{dropdownContent}</div>
          }
        </div>
      </StyledUserInfoContainer>
      <Layout css={{
        display: 'grid',
        width: '100%',
        cursor: 'pointer',
      }} >
        <PromotedCard onItemClick={itemClick} type={type} src={src} alt={alt} />
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </Layout>
    </StyledPromotedContentCard>
  );
};

PromotedContentCard.displayName = "PromotedContentCard";
