import { styled } from "../../theme";
import { Avatar, Button } from "../../components";
import default_image from '../../../assets/images/profile/profile.png';
import { space } from "../../tokens";
const moment = require('moment');

const StyledCard = styled("div", {
    backgroundColor: "$neutral",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    "> img": {
        borderRadius: "$8"
    },

    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "100%"
            }
        },
    },
});

const StyledLeaderList = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '$8',
    borderBottomWidth: space[1],
    borderBottomStyle: "solid",
    borderBottomColor: '$low',
})

const StyledLeaderListView = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'White',
    paddingBlock: 5,
    cursor: 'pointer',
    padding: '$10',
})

const StyledLeaderAvatar = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
})

const StyleLastMessageUpdated = styled('div', {
    fontFamily: "$roboto",
    fontSize: '14px',
    color: '#C5C5C5',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    marginBottom: 0,
    marginLeft: 2
})

const StyledName = styled('div', {
    fontFamily: "$roboto",
    fontSize: '14px',
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    marginBottom: 0
})

const StyledOverLayImage = styled('div', {
    paddingLeft: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    "> img": {
        height: '30px',
        width: '30px',
        resize: 'contain',
        border: '2px solid white',
        borderRadius: '50%',
        position: 'relative',
        left: "-30px",
        marginLeft: '-15px',
        zIndex: 1
    },
})

const StyledNameLayout = styled('div', {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '$4'
})

const StyledMessageLayout = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    gap: '$2'
})

const StyledJoinLayout = styled('div', {
    width: '25%',
    display: 'flex',
    justifyContent: 'end',
    height: '80%',
    alignItems: 'center'
})

export const ChatHeader = ({
    isMobileDevice,
    chatItem,
    groupClickAction
}) => {
    let item = chatItem
    return (
        <>
            <StyledCard mode={{
                "@initial": "mobile",
                "@bp3": "desktop",
            }}>
                {
                    item &&
                    <StyledLeaderList>
                        <StyledLeaderListView>
                            <StyledLeaderAvatar css={{ width: isMobileDevice ? '15%' : '5%'}}>
                                {
                                    !item.directRoom ? <Avatar src={item?.iconUrl ? item?.iconUrl : default_image} alt={"Guest"} fallback={'G'} /> :
                                        <>
                                            {
                                                item.participants.length > 0 &&
                                                <StyledOverLayImage>
                                                    {
                                                        item.participants?.map((item, index) => {
                                                            return (
                                                                <img src={item?.userInfo?.photoUrl} alt="" />
                                                            )
                                                        })
                                                    }
                                                </StyledOverLayImage>
                                            }
                                        </>

                                }
                            </StyledLeaderAvatar>

                            <StyledNameLayout>
                                {
                                    item.directRoom ? <StyledName>{item?.participants.length > 0 ? `${item?.participants?.[0]?.userInfo.displayName},${item?.participants?.[1]?.userInfo.displayName}` : '- - - - '}</StyledName> :
                                        <StyledName>{item?.name ? item?.name : '- - - - '}</StyledName>
                                }
                                <StyledMessageLayout>
                                    {
                                        !item.directRoom &&
                                        <StyleLastMessageUpdated>{`${item?.participants.length - 1} members and you`}</StyleLastMessageUpdated>
                                    }
                                    {
                                        (item.directRoom && item?.lastUpdatedAt) &&
                                        <StyleLastMessageUpdated>{item?.lastUpdatedAt ? ` ${moment(new Date(item?.lastUpdatedAt)).fromNow()}` : ''}</StyleLastMessageUpdated>
                                    }
                                </StyledMessageLayout>
                            </StyledNameLayout>
                            <StyledJoinLayout>
                                {
                                    !item.directRoom && <Button size={'small'} onClick={groupClickAction}>{'View Group'}</Button>
                                }
                            </StyledJoinLayout>
                        </StyledLeaderListView>
                    </StyledLeaderList>
                }
            </StyledCard>
        </>

    );
};

ChatHeader.displayName = "ChatHeader";