import qs from "qs";
import { checkExists } from "../../utilities/apiconfig";
import { get } from "../../api";

export const checkNicknameValidityInfinite = async (nickname, count = 1) => {
  const resultNickname = nickname + count;

  const data = await get(
    `${checkExists}?${qs.stringify({ nickname: resultNickname })}`
  );

  if (count === 10) throw new Error("No possible nickname for that user");

  if (data.data.nickname)
    return checkNicknameValidityInfinite(nickname, ++count);

  return resultNickname;
};
