import styled from "styled-components";

import { SystemIcon } from "../../foundations";
import { colors } from "../../tokens";

const StyledHeart = styled(SystemIcon)`
  transition: all 0.1s ease;

  &:hover {
    fill: #ff0a71;
  }
`;

export const FavoritesButton = ({ onClick, style = {}, checked = false }) => {
  return (
    <StyledHeart
      icon="Heart"
      size="large"
      onClick={onClick}
      weight={checked ? "fill" : "regular"}
      color={checked ? "#FF0A71" : colors.neutral200}
      style={{ cursor: "pointer", ...style }}
    />
  );
};
