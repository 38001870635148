import { SystemIcon } from "../../foundations";
import { styled, shadows } from "../../theme";

const StyledBottomMenuItem = styled("button", {
  all: "unset",
  fill: "$secondary",
  border: "none",
  boxSizing: "border-box",
  paddingLeft: "$12",
  paddingRight: "$12",
  paddingTop: "$16",
  paddingBottom: "$16",
  cursor: "pointer",
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  variants: {
    active: {
      true: {
        fill: "$primary",
        boxShadow:
          "inset 0 0 0 0 $colors$primary, inset 0 -4px 0 0 $colors$primary",
      },
    },
  },
  defaultVariants: {
    active: false,
  },
});

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param active - true | false
 * @param icon - All the possible icons available on https://phosphoricons.com
 *
 * @example
 *
 * return (
 *   <BottomMenuItem active icon="Heart" />
 * )
 */
export const BottomMenuItem = ({
  icon = "Question",
  active = false,
  ...props
}) => {
  return (
    <StyledBottomMenuItem {...props} active={active}>
      <SystemIcon icon={icon} size="default" color="inherit" />
    </StyledBottomMenuItem>
  );
};

BottomMenuItem.displayName = "BottomMenuItem";
