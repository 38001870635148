import { styled, shadows } from "../../theme";

import { SystemIcon } from "../../foundations";

const StyledIconButton = styled("button", {
  all: "unset",
  fill: "$primary",
  backgroundColor: "transparent",
  border: "none",
  borderRadius: "$96",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  width: "$32",
  height: "$32",
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
  "&:hover": {
    fill: "$interactive",
  },
  defaultVariants: {
    checked: false,
  },
});

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param checked - true | false
 *
 * @example
 *
 * return (
 *   <Favorite checked={true} />
 * )
 */
export const Favorite = ({ checked, ...props }) => {
  return (
    <StyledIconButton {...props} checked={checked}>
      <SystemIcon
        icon="Star"
        size="large"
        weight={checked ? "fill" : "regular"}
        color="inherit"
      />
    </StyledIconButton>
  );
};

Favorite.displayName = "Favorite";
