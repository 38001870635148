import { checkNull } from "../../../utilities/generic";
import { RatingGroup, RatingStar, Typography } from "../../components";
import { styled } from "../../theme";
import { colors, space } from "../../tokens";

const StyledProductCard = styled("div", {
    backgroundColor: "$neutral",
    borderRadius: "$16",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
    height: "fit-content",
    "> img": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    "> button": {
        width: "100%",
    },
    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "23%",
                marginLeft: '$8',
            }
        },
    },
});

const StyledBottom = styled("p", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$8",
    padding: "$16",
    paddingTop: 0,
    width: "100%",
    marginTop: '$12'
});

const StyledCookLayout = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    width: '100%'
});

const ReviewStyle = styled("div", {
    display: 'grid',
    gap: "$8",
    padding: "$8",
    borderWidth: space[1],
    borderStyle: "solid",
    borderColor: '$primary',
    width: '100%',
    backgroundColor: "$messages_card_bg_color",
    borderRadius: "$8",
});

export const ChefReviewCard = ({
    children,
    src,
    alt,
    title,
    speciality,
    chef,
    description,
    isBottomSection,
    isfullwidth
}) => {
    let chefRating = (chef.rating).toFixed(1)
    let totalProductReviewers = checkNull(chef?.totalProductReviewers) !== '' ? chef.totalProductReviewers : 0
    return (
        <StyledProductCard mode={{
            "@initial": "mobile",
            "@bp3": "desktop",
        }} style={{ width: isfullwidth && '100%' }}>
            {src &&
                <img loading='eager' src={src} alt={alt} style={{ objectFit: 'contain', backgroundColor: colors.media_bg_color }} height={175} width={'100%'} />
            }
            <StyledBottom>
                <Typography variant={'body'} fontWeight={'bold'}>{title}</Typography>
                {
                    isBottomSection &&
                    <>
                        <StyledCookLayout>
                            <Typography variant={'bodySmall'}>{`Specialty : ${speciality}`} </Typography>
                        </StyledCookLayout>
                        <ReviewStyle>
                            <StyledCookLayout>
                                <Typography variant={'bodySmall'}>{`${totalProductReviewers} Customer Ratings`} </Typography>
                            </StyledCookLayout>
                            <RatingGroup totalText={`(${chefRating})`}>
                                {Array.from({ length: 5 }).map((_, index) => {
                                    const position = index + 1;
                                    let star = chefRating >= position ? "full" : 'empty';
                                    if (star === 'empty' && chefRating > position - 1) {
                                        star = 'half'
                                    }
                                    return (
                                        <RatingStar
                                            filled={star}
                                        />
                                    );
                                })}
                            </RatingGroup>
                        </ReviewStyle>
                        {/* <Typography variant={'bodySmall'}>{`(16 orders)`} </Typography> */}
                        {description}
                    </>
                }
                {children}
            </StyledBottom>
        </StyledProductCard>
    );
};

ChefReviewCard.displayName = "ChefReviewCard";
