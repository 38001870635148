import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";

const StyledEmptyState = styled("div", {
  fill: "$secondary",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "$16",
  padding: "$32",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$24",
  lineHeight: "$150",
  color: "$secondary",
  textAlign: "center",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$120",
  color: "$medium",
  textAlign: "center",
  margin: 0,
  padding: 0,
});

/**
 * This component accepts only internal props
 *
 * @param children - A React element that can be string or anything.
 * @param icon - All the possible icons available on https://phosphoricons.com
 * @param title - The empty state title.
 * @param description - The empty state title.
 *
 * @example
 *
 * return (
 *   <EmptyState icon="NewspaperClipping" title="Title" description="Description">
 *      <Button>Label</Button>
 *   </EmptyState>
 * )
 */
export const EmptyState = ({ children, icon, iconColor="inherit", title, description }) => {
  return (
    <StyledEmptyState>
      <SystemIcon icon={icon} size="large" color={iconColor} />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      {children}
    </StyledEmptyState>
  );
};

EmptyState.displayName = "EmptyState";
