import { styled } from "../../theme";
import { space } from "../../tokens";

const StyledPromotionCard = styled("div", {
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$16",
  padding: "$16",
  width: "fit-content",
  "> img": {
    borderRadius: "$8",
  },
  "> button": {
    width: "100%",
  },
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$20",
  lineHeight: "$120",
  color: "$secondary",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$120",
  color: "$medium",
  margin: 0,
  padding: 0,
});

/**
 * This component accepts only internal props
 *
 * @param children - A React element that can be string or anything.
 * @param src - The image source url.
 * @param alt - The image alt text.
 * @param title - The empty state title.
 * @param description - The empty state title.
 *
 * @example
 *
 * return (
 *   <PromotionCard
 *       src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"
 *       alt="A man..."
 *       title="Title"
 *       description="Description"
 *     >
 *       <Button variant="secondary">View more</Button>
 *   </PromotionCard>
 * )
 */
export const PromotionCard = ({ children, src, alt, title, description }) => {
  return (
    <StyledPromotionCard>
      <img loading="eager" src={src} alt={alt} width={210} height={88} />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      {children}
    </StyledPromotionCard>
  );
};

PromotionCard.displayName = "PromotionCard";
