import { SystemIcon } from "../../../foundations";
import { styled } from "../../../theme";
import { colors, space } from "../../../tokens";
import { Avatar, Box, IconLabelButton, Layout } from "../../../components";
import no_media from "../../../../assets/images/no_media_image.png";
import { Typography } from "antd";
import { store } from "../../../../store";

const StyledCard = styled("div", {
  boxSizing:'border-box',

  flex: "1 1 360px",
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  display: "flex",
  marginTop: "$8",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$16",
  position: "relative",
  "> img": {
    borderRadius: "$8",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

const StyledModalCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$16",
  width: "100%",
  position: "relative",
  "> img": {
    borderRadius: "$8",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

const StyledCongratsModalCard = styled("div", {
  backgroundColor: "$neutral",
  borderRadius: "$16",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$16",
  width: "100%",
  position: "relative",

  "> img": {
    borderRadius: "$8",
    objectFit: "cover",
    //opacity: 0.5,
  },
});

const StylesInfoLayer = styled("div", {
  position: "absolute",
  borderRadius: "$16",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$32",
  width: "100%",
  height: "100%",
  textAlign: "center",
  backgroundColor: "#000",
  opacity: 0.6,
});
const StylesInfoLayout = styled("div", {
  position: "absolute",
  borderRadius: "$16",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$32",
  width: "100%",
  height: "100%",
  textAlign: "center",
});

const StyledCongrats = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "xx-large",
  lineHeight: "$72",
  color: "$neutral",
  marginTop: "$16",
  padding: 0,
});

const StylesInfo = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$32",
  color: "$neutral",
  padding: 0,
  margin: 0,
});

const StyledLayout = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$4",
  padding: "$16",
  paddingTop: 0,
  width: "100%",
});

const StyledModalLayout = styled("div", {
  backgroundColor: "$neutral",
  borderRadius: "$16",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$2",
  padding: 15,
  width: "100%",
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  color: "$secondary",
  margin: 0,
  padding: 0,
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  height: "$48",
});

const StyledName = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$24",
  color: "$secondary",
  margin: 0,
  padding: 0,
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$24",
  color: "$secondary",
  margin: 0,
  padding: 0,
  gap: "$8",
});

const StyledShare = styled("div", {
  position: "absolute",
  top: "$24",
  right: "$24",
  cursor: "pointer",
});

const DateLabel = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$14",
  lineHeight: "$24",
  color: "$primary",
});

const StyledShareLayout = styled("div", {
  background: "#000",
  width: "$40",
  height: "$40",
  borderRadius: "$96",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledPosteByLabel = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$16",
  color: "$medium",
});

const StyledPosteByName = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  color: "$secondary",
});

const StyledLevelLayout = styled("div", {
  background: "$tulip10",
  gap: "$10",
  padding: "$4 $16",
  borderRadius: "$96",
  width: "fit-content",
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
});

export const QuizCard = ({
  src,
  alt = "",
  id,
  title,
  postContent,
  userInfo,
  totalQuestions,
  totalParticipants,
  ongoing = false,
  upcoming = false,
  expdate = "",
  onShareContent,
  isAlreadyTaken = false,
  onClickAction,
}) => {
  return (
    <StyledCard
      id={`quizz_${id}`}
      mode={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}
    >
      <StyledShare>
        {onShareContent && (
          <StyledShareLayout>{onShareContent}</StyledShareLayout>
        )}
      </StyledShare>
      <img
        loading="eager"
        style={{ objectFit: src ? "cover" : "contain" }}
        src={src ? src : no_media}
        alt={alt}
        width="100%"
        height={210}
      />
      <StyledLayout>
        <StyledTitle>{title}</StyledTitle>
        {postContent && (
          <Typography variant="body" color="medium">
            {postContent}
          </Typography>
        )}
        <StyledName>
          Posted by <b>{userInfo?.displayName}</b>
        </StyledName>
        <Box
          css={{
            padding: "$8 $16",
            borderWidth: space[1],
            borderStyle: "solid",
            borderColor: "$low",
            borderRadius: "$8",
            marginTop: "$16",
            width: "100%",
          }}
        >
          <Layout>
            <SystemIcon size="small" color={colors.primary} icon="Info" />{" "}
            <StyledDescription> {totalQuestions} Questions</StyledDescription>
          </Layout>
          <Layout
            css={{
              paddingTop: "$8",
            }}
          >
            <SystemIcon size="small" color={colors.primary} icon="Users" />
            <StyledDescription>
              {" "}
              {totalParticipants} Participants
            </StyledDescription>
          </Layout>
        </Box>
        <Layout
          css={{
            width: "100%",
            marginTop: "$16",
            display: "block",
            textAlign: "center",
            gap: "$8",
          }}
        >
          {ongoing && <DateLabel> Ongoing untill {expdate}</DateLabel>}

          {upcoming && <DateLabel> Upcoming {expdate}</DateLabel>}

          <IconLabelButton
            style={{ width: "100%", marginTop: "10px" }}
            iconColor={"#000"}
            variant="primary"
            disabled={isAlreadyTaken}
            label={isAlreadyTaken ? "Quiz taken" : `Take Quiz`}
            onClick={onClickAction}
          ></IconLabelButton>
        </Layout>
      </StyledLayout>
    </StyledCard>
  );
};

export const QuizModalCard = ({
  src,
  alt = "",
  title,
  postContent,
  userInfo,
  totalQuestions,
  totalParticipants,
  ongoing = false,
  upcoming = false,
  expdate = "",
  onShareContent,
  onClickAction,
}) => {
  return (
    <StyledModalCard>
      <StyledShare>
        {onShareContent && (
          <StyledShareLayout>{onShareContent}</StyledShareLayout>
        )}
      </StyledShare>
      <StyledTitle style={{ height: "auto" }}>{title}</StyledTitle>
      {src && (
        <img
          loading="eager"
          style={{ objectFit: "cover" }}
          src={src}
          alt={alt}
          width="100%"
          height={210}
        />
      )}
      {postContent && (
        <Typography variant="body" color="medium">
          {postContent}
        </Typography>
      )}
      <StyledModalLayout>
        <Layout
          css={{
            alignItems: "center",
          }}
        >
          <Avatar src={userInfo?.photoUrl} />
          <Layout
            css={{
              display: "block",
              paddingLeft: "$8",
            }}
          >
            <StyledPosteByLabel>{"Posted by"}</StyledPosteByLabel>
            <StyledPosteByName>{userInfo?.displayName}</StyledPosteByName>
          </Layout>
        </Layout>

        <Box
          css={{
            padding: "$8 $16",
            borderWidth: space[1],
            borderStyle: "solid",
            borderColor: "$low",
            borderRadius: "$8",
            marginTop: "$16",
            width: "100%",
          }}
        >
          <Layout>
            <SystemIcon size="small" color={colors.primary} icon="Info" />{" "}
            <StyledDescription> {totalQuestions} Questions</StyledDescription>
          </Layout>
          <Layout
            css={{
              paddingTop: "$8",
            }}
          >
            <SystemIcon size="small" color={colors.primary} icon="Users" />
            <StyledDescription>
              {" "}
              {totalParticipants} Participants
            </StyledDescription>
          </Layout>
        </Box>
        <Layout
          css={{
            width: "100%",
            marginTop: "20px",
            display: "block",
            textAlign: "center",
            gap: "$8",
          }}
        >
          {ongoing && <DateLabel> Ongoing untill {expdate}</DateLabel>}

          {upcoming && <DateLabel> Upcoming {expdate}</DateLabel>}

          <IconLabelButton
            style={{ width: "100%", marginTop: "20px" }}
            iconColor={"#000"}
            variant="primary"
            label={`Take quiz`}
            onClick={onClickAction}
          ></IconLabelButton>
        </Layout>
      </StyledModalLayout>
    </StyledModalCard>
  );
};

export const QuizModalLevel = ({ level }) => {
  return <StyledLevelLayout></StyledLevelLayout>;
};

export const QuizCongratsCard = ({
  src,
  alt = "",
  completedLevel,
  isModule = false,
}) => {
  const { theme } = store.getState();

  return (
    <StyledCongratsModalCard>
      <img
        loading="eager"
        style={{}}
        src={src}
        alt={alt}
        width="100%"
        height={300}
      />
      <StylesInfoLayer></StylesInfoLayer>
      <StylesInfoLayout>
        <Layout
          css={{
            display: "block",
            color: theme.neutral,
          }}
        >
          <SystemIcon
            size="extraLarge"
            color={theme.neutral}
            icon="SealCheck"
          />
          <StyledCongrats>Congratulations!</StyledCongrats>
          {!isModule && (
            <>
              <StylesInfo>{`You have successfully completed Level ${completedLevel}, Loyalty`}</StylesInfo>
              <StylesInfo>{`points have been added to your wallet`}</StylesInfo>
            </>
          )}
        </Layout>
      </StylesInfoLayout>
    </StyledCongratsModalCard>
  );
};

QuizCard.QuizModalLevel = QuizModalLevel;
QuizCard.QuizModalCard = QuizModalCard;
QuizCard.QuizCongratsCard = QuizCongratsCard;
QuizCard.displayName = "QuizCard";
