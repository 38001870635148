
import { STORE_THEME_COLORS } from "../actiontypes";

const initialState = {
  primary: "#FF0000",
  interactive: "#222222",
  secondary: "#fbfbfb",
  neutral: "#1E1E1E",
  high: "#434343",
  medium: "#666666",
  low: "#3D3E41",
  choiceBg: "#333333",
  surveyBorderColor: "#FF9292",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_THEME_COLORS:
      const data = action.payload;
      
      const theme_colors = {
        // used for buttons, links, and other interactive elements ???
        primary: data.primaryColor,
        // used for hovering over??
        interactive: data.interactiveColor,
        // used for text ?
        secondary: data.secondaryColor,
        // used for backgrounds??
        neutral: data.neutral,
        // used for ??
        high: initialState.high,
        // used for ??
        medium: initialState.medium,
        // used for ??
        low: initialState.low,
        // used for single/multiple choice backgrounds
        choiceBg: initialState.choiceBg,
        // used for survey question borders
        surveyBorderColor: initialState.surveyBorderColor,
      };
      console.log("theme_colors", theme_colors)
      return Object.assign({}, state, theme_colors);
    default:
      return state;
  }
};
export default themeReducer;
