import { SystemIcon } from "../../foundations";
import { keyframes, styled } from "../../theme";

const rotate = keyframes({
  from: { transform: "rotate(0deg)" },
  to: { transform: "rotate(360deg)" },
});

const StyledSpinner = styled("div", {
  fill: "$medium",
  backgroundColor: "transparent",
  "> svg": {
    animation: `${rotate} 2.5s linear infinite`,
  },
});

/**
 * @example
 *
 * return (
 *   <Spinner />
 * )
 */
export const Spinner = ({size="large"}) => {
  return (
    <StyledSpinner>
      <SystemIcon icon="Spinner" size={size} color="inherit" />
    </StyledSpinner>
  );
};

Spinner.displayName = "Spinner";
