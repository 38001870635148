import { Layout } from "../../components";
import { SystemIcon } from "../../foundations";
import { shadows, styled } from "../../theme";
import { space } from "../../tokens";

const StyledLayout = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "$16",
  padding: "$24",
  borderWidth: space[1],
  borderStyle: "solid",
  borderColor: "$low",
  height: "100%",
  variants: {
    variant: {
      primary: {
        backgroundColor: "$tulip10",
        borderColor: "$neutral",
      },
    },
  },
});

const StyledTitle = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  color: "$secondary",
  lineHeight: "$32",
  marginTop: "$8",
  padding: 0,
});

const StyledDescription = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$24",
  margin: 0,
  marginTop: "$8",
  padding: 0,
  color: "$medium",
});

const StyledLabelIconButton = styled("button", {
  all: "unset",
  fill: "$neutral",
  border: "none",
  borderRadius: "$8",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: shadows.subtle,
  cursor: "pointer",
  width: "inherit",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  "&:disabled": {
    cursor: "not-allowed",
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: "$primary",
        // "&:hover": {
        //   backgroundColor: "$interactive",
        // },
        "&:disabled": {
          backgroundColor: "$primary",
          opacity: 0.3,
        },
      },
      secondary: {
        backgroundColor: "$secondary",
        // "&:hover": {
        //   backgroundColor: "$high",
        // },
        "&:disabled": {
          backgroundColor: "$low",
          color: "$neutral300",
        },
      },
    },
    size: {
      small: {
        padding: "$4 $8",
      },
      default: {
        padding: "$8 $16",
      },
      large: {
        padding: "$16 $24",
        fontSize: "$20",
      },
    },
  },
});

const StyledLabel = styled("p", {
  fontFamily: "$roboto",
  textAlign: "center",
  margin: 0,
  padding: 0,
  paddingLeft: "$10",
  fontSize: "$16",
  lineHeight: "$24",
  color: "#FFF",
  variants: {
    variant: {
      primary: {
        color: "$neutral",
      },
      secondary: {
        color: "$neutral300",
        "&:disabled": {
          backgroundColor: "$low",
          color: "$neutral300",
        },
      },
    },
  },
});

const AvatarLayout = styled("div", {
  img: {
    width: "140px",
    borderRadius: 8,
  },
});

export const Task = ({
  avatar,
  icon,
  title,
  description,
  buttonIcon,
  label,
  buttonVarient,
  iconMain,
  action,
  iconColor,
  buttonIconSize = "default",
  avatarSize = "default",
  ...props
}) => {
  return (
    <StyledLayout {...props}>
      <div>
        {avatar ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: 125,
            }}
          >
            <AvatarLayout>
              <img alt="" src={avatar} />
            </AvatarLayout>
          </div>
        ) : (
          iconMain && <SystemIcon icon={iconMain} size={"large"}></SystemIcon>
        )}
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </div>
      <Layout
        css={{
          width: "100%",
          marginTop: "$16",
        }}
      >
        <StyledLabelIconButton {...props} size={buttonIconSize}>
          {buttonIcon && (
            <SystemIcon
              icon={buttonIcon}
              size={buttonIconSize}
              color={iconColor}
            />
          )}
          <StyledLabel {...props}>{label}</StyledLabel>
        </StyledLabelIconButton>
      </Layout>
    </StyledLayout>
  );
};

Task.DisplayName = "Task";
