import { SystemIcon } from "../../foundations";
import { styled } from "../../theme";
import { space } from "../../tokens";
import no_media from "../../../assets/images/no_media_image.png";
import { Avatar, IconLabelButton, Layout } from "../../components";
import { store } from "../../../store";

const StyledCard = styled("div", {
  displayName: "StyledCard",
  flex: "1 1 360px",
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space[1],
  borderColor: "$low",
  borderStyle: "solid",
  display: "flex",
  marginTop: "0.5rem",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$8",
  position: "relative",

  "> img": {
    borderRadius: "$8",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

const StyledLayout = styled("div", {
  displayName: "StyledLayout",
  backgroundColor: "$neutral",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$4",
  width: "100%",
  padding: "0 16px 16px",
  height: "100%",
});

const StyledTitle = styled("p", {
  displayName: "StyledTitle",
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  color: "$secondary",
  margin: 0,
  padding: 0,
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  height: "$48",
});

const StyledName = styled("p", {
  displayName: "StyledName",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$24",
  color: "$secondary",
  margin: 0,
  padding: 0,
});

const StyledShare = styled("div", {
  displayName: "StyledShare",
  position: "absolute",
  top: "$24",
  right: "$24",
  cursor: "pointer",
});

const StyledShareLayout = styled("div", {
  displayName: "StyledShareLayout",
  background: "#000",
  width: "$40",
  height: "$40",
  borderRadius: "$96",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledModalCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$16",
  width: "100%",
  position: "relative",
  "> img": {
    borderRadius: "$8",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

const StyledModalLayout = styled("div", {
  displayName: "StyledModalLayout",
  backgroundColor: "$neutral",
  borderRadius: "$16",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$2",
  width: "100%",
});

const StyledPosteByLabel = styled("div", {
  displayName: "StyledPosteByLabel",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$14",
  lineHeight: "$16",
  color: "$medium",
});

const StyledPosteByName = styled("div", {
  displayName: "StyledPosteByName",
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$24",
  color: "$secondary",
});

const StyledCongratsModalCard = styled("div", {
  displayName: "StyledCongratsModalCard",
  backgroundColor: "$interactive",
  borderRadius: "$16",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "$16",
  width: "100%",
  position: "relative",

  "> img": {
    borderRadius: "$8",
    objectFit: "cover",
    //opacity: 0.5,
  },
});

const StylesInfoLayout = styled("div", {
  displayName: "StylesInfoLayout",
  backgroundColor: "$interactive",
  position: "absolute",
  borderRadius: "$16",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$32",
  width: "100%",
  height: "100%",
  textAlign: "center",
});

const StyledCongrats = styled("p", {
  displayName: "StyledCongrats",
  backgroundColor: "$neutral",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "large",
  lineHeight: "$72",
  color: "$neutral10",
  marginTop: "$16",
  padding: 0
});

const StylesInfoLayer = styled("div", {
  displayName: "StylesInfoLayer",
  backgroundColor: "$neutral",
  position: "absolute",
  borderRadius: "$16",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$32",
  width: "100%",
  height: "100%",
  textAlign: "center",
  opacity: 0.6,
});

const StylesInfo = styled("div", {
  displayName: "StylesInfo",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$16",
  lineHeight: "$32",
  color: "$secondary",
  padding: 0,
  margin: 0,
});

export const SurveyCard = ({
  src,
  alt = "",
  id,
  title,
  postContent,
  userInfo,
  onShareContent,
  isAlreadyTaken = false,
  onClickAction,
}) => {
  return (
    <StyledCard
      id={`survey_${id}`}
      mode={{
        "@initial": "mobile",
        "@bp3": "desktop",
      }}
    >
      <StyledShare>
        {onShareContent && (
          <StyledShareLayout>{onShareContent}</StyledShareLayout>
        )}
      </StyledShare>
      <img
        loading="eager"
        style={{ objectFit: src ? "cover" : "contain" }}
        src={src ? src : no_media}
        alt={alt}
        width="100%"
        height={210}
      />

      <StyledLayout>
        {postContent && <div>{postContent}</div>}
        <StyledTitle>{title}</StyledTitle>
        <StyledName>
          Posted by <b>{userInfo?.displayName}</b>
        </StyledName>

        <Layout
          css={{
            width: "100%",
            display: "block",
            textAlign: "center",
            gap: "$8",
            marginTop: "auto",
          }}
        >
          <IconLabelButton
            style={{ width: "100%", marginTop: "10px" }}
            iconColor={"#000"}
            variant="primary"
            disabled={isAlreadyTaken}
            label={isAlreadyTaken ? `Survey taken` : `Take Survey`}
            onClick={onClickAction}
          ></IconLabelButton>
        </Layout>
      </StyledLayout>
    </StyledCard>
  );
};

export const SurveyModalCard = ({
  title,
  src,
  alt = "",
  postContent,
  userInfo,
  onShareContent,
  onClickAction,
}) => {
  return (
    <StyledModalCard>
      <StyledShare>
        {onShareContent && (
          <StyledShareLayout>{onShareContent}</StyledShareLayout>
        )}
      </StyledShare>

      <StyledTitle style={{ height: "auto" }}>{title}</StyledTitle>

      {src && (
        <img
          loading="eager"
          style={{ objectFit: "cover" }}
          src={src}
          alt={alt}
          width="100%"
          height={210}
        />
      )}

      {postContent && <div>{postContent}</div>}

      <StyledModalLayout css={{ padding: 10 }}>
        <Layout
          css={{
            alignItems: "center",
          }}
        >
          <Avatar src={userInfo?.photoUrl} />
          <Layout
            css={{
              display: "block",
              paddingLeft: "$8",
            }}
          >
            <StyledPosteByLabel>{"Posted by"}</StyledPosteByLabel>
            <StyledPosteByName>{userInfo?.displayName}</StyledPosteByName>
          </Layout>
        </Layout>
        <Layout
          css={{
            width: "100%",
            display: "block",
            textAlign: "center",
            gap: "$8",
          }}
        >
          <IconLabelButton
            size="large"
            style={{ width: "100%", marginTop: "20px" }}
            iconColor={"#000"}
            variant="primary"
            label={`Start Survey`}
            onClick={onClickAction}
          ></IconLabelButton>
        </Layout>
      </StyledModalLayout>
    </StyledModalCard>
  );
};

export const SurveyCongratsCard = ({ src, alt = "", isModule = false }) => {
  const { theme } = store.getState();

  return (
    <StyledCongratsModalCard>
      <img
        loading="eager"
        style={{}}
        src={src}
        alt={alt}
        width="100%"
        height={300}
      />
      <StylesInfoLayer></StylesInfoLayer>
      <StylesInfoLayout>
        <Layout
          css={{
            display: "block",
          }}
        >
           {!isModule && (<SystemIcon
            size="extraLarge"
            color={theme.neutral}
            icon="FlagCheckered"
          /> )}
          <StyledCongrats>
            Thank you! We appreciate your support.
          </StyledCongrats>
          {!isModule && (
            <StylesInfo>{`You have successfully completed the survey, Loyalty points have been added to your wallet`}</StylesInfo>
          )}
        </Layout>
      </StylesInfoLayout>
    </StyledCongratsModalCard>
  );
};
SurveyCard.SurveyCongratsCard = SurveyCongratsCard;
SurveyCard.SurveyModalCard = SurveyModalCard;
SurveyCard.displayName = "SurveyCard";
