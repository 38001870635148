import { styled } from "../../theme";

const StyledMobileHeader = styled("header", {
  backgroundColor: "transparent",
  paddingInline: '$24',
  display: "flex",
  height: '7rem',
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$32",
  //position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  zIndex: "9999",
});

const StyledLeftAdornment = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  width: '19%',
  variants: {
    mode: {
      desktop: {
        width: '20%',
        marginTop: '$8',
      },
    },
  },
});

const StyledCenterAdornment = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$32",

  width: '57%',
  variants: {
    mode: {
      desktop: {
        width: '40%',
        marginTop: '$8',
      },
    },
  },
});

const StyledRightAdornment = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  width: '32%',
  gap: "$32",
  "> div": {
    display: "flex",
    alignItems: "center",
    gap: "$16",
  },
  variants: {
    mode: {
      desktop: {
        marginTop: '$8',
      },
    },
  },
});

const StyledMenuMarker = styled("div", {
  display: "flex",
  width: '17px',
  height: '13px',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,
})

/**
 * This component accepts all the header props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param leftAdornment - A React element positioned in the left of the header, prefer use LogoPill component.
 * @param centerAdornment - A React element positioned in the middle of the header, prefer use SearchField component.
 * @param rightAdornment - A React element positioned in the right of the header, prefer use Notification button and Avatar component.
 *
 * @example
 *
 * return (
 *   <Header
 *      leftAdornment={
 *        <IconButton
 *          variant="secondary"
 *          icon="List"
 *          size="large"
 *          contained={false}
 *        />
 *        <LogoPill src="https://iili.io/HPVInCF.png" alt="test" />
 *      }
 *      centerAdornment={<SearchField />}
 *      rightAdornment={
 *        <>
 *          <Anchor href="#" icon="Wallet">
 *            6400 points
 *          </Anchor>
 *          <Anchor href="#" icon="Ticket">
 *            Promotions
 *          </Anchor>
 *          <div>
 *            <NotificationButton variant="message" />
 *            <NotificationButton variant="notification" />
 *            <IconButton icon='PlusCircle' />
 *            <Avatar src="https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?&w=128&h=128&dpr=2&q=80" />
 *          </div>
 *        </>
 *      }
 *   />
 * )
 */
export const Header = ({ leftAdornment, centerAdornment, rightAdornment }) => {
  return (
    <StyledMobileHeader>
      <StyledLeftAdornment mode={{
        "@bp4": "desktop",
      }}>{leftAdornment}</StyledLeftAdornment>
      <StyledCenterAdornment mode={{
        "@bp4": "desktop",
      }}>{centerAdornment}</StyledCenterAdornment>
      <StyledRightAdornment mode={{
        "@bp4": "desktop",
      }}>{rightAdornment}</StyledRightAdornment>
    </StyledMobileHeader>
  );
};
export const MenuIcon = ({ icon }) => {
  return (
    <StyledMenuMarker>
      <img alt='' height={'100%'} src={icon} />
    </StyledMenuMarker>
  )
}
Header.MenuIcon = MenuIcon;
Header.displayName = "Header";
