import { isMobile } from "react-device-detect";
/** React library */
import React from "react";
/** React bootstrap */
import { Spinner } from "react-bootstrap";
/** Service */
import { get, post } from "../../../api";
import {
  addpost,
  communitybaseurl,
  profiledata,
  surveyQusUrl,
  surveyUrl,
} from "../../../utilities/apiconfig";

import constant from "../../../utilities/constant";
import { store } from "../../../store";

/** Style */
import "../../../assets/scss/PlayQuiz.scss";
import {
  Box,
  Button,
  IconLabelButton,
  Layout,
  SurveyCard,
  SurveyMultiChoice,
  SurveyMultiLine,
  SurveyRating,
  SurveySingleChoice,
  SurveySingleLine,
  SurveySingleNumeric,
  SurveyMatrixQuestion,
} from "../../../design-system";

import { space } from "../../../design-system/tokens";

const {theme} = store.getState() 
export default class TakeSurveyModal extends React.Component {
  constructor(props) {
    super(props);
    this.congratsRef = React.createRef();
    this.surveyId = props?.surveyId
      ? props.surveyId
      : props?.match?.params?.postId;
    this.state = {
      surveyStarted: true,
      answerResults: {},
      pageLoading: true,
      answerSubmitLoading: false,
      formValid: false,
      contentLoading: false,
      surveyCompleted: false,
      optionNotFound: false,
      profile: {},
      currentQuestionIndex: -1,
      questionForm: {
        singleChoiceAns: "",
        multipleChoiceAns: [],
        matrixAnswers: {
          surveyQuestionOptions: [],
          surveyQuestionSubOptions: [],
        },
        freeEntryAnswer: "",
        rating: 1,
      },
      rating: 1,
      surveyQus: [],
      surveyData: {},
      company_name: localStorage.getItem(constant.company_name),
      emojis: constant.emojis,
      clientColor: store.getState().data.tenant.screenBackground,
      theme: store.getState().theme,
    };
    this.stepRange = 1;
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = async () => {
    try {
      const survey_res = await get(
        `${addpost}/${this.surveyId}`,
        {},
        this.props.accessToken
      );
      const survey_qus_res = await get(
        `${surveyUrl}/${this.surveyId}?page=1&perPage=999`,
        {},
        this.props.accessToken
      );

      const profile_res = await get(
        `${communitybaseurl}${profiledata}`,
        {},
        this.props.accessToken
      );
      const surveyQus = survey_qus_res.data.items;
      const surveyData = survey_res.data;
      const currentQuestionIndex = surveyData?.completedQuestions
        ? surveyData?.completedQuestions === surveyQus?.length
          ? surveyQus?.length - 1
          : surveyData?.completedQuestions
        : surveyQus?.length > 0
          ? 0
          : -1;

      this.setState({
        surveyData: surveyData,
        surveyQus: surveyQus,
        profile: profile_res.data,
        currentQuestionIndex: currentQuestionIndex,
        pageLoading: false,
        surveyCompleted: surveyData.completedQuestions === surveyQus.length,
        contentLoading: false,
      });
    } catch (error) {}
  };

  getQuestionBlock = () => {
    let res = null;
    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];
    switch (currentQuestion.type) {
      case "SINGLE_CHOICE":
        res = this.single_choice();
        break;
      case "MULTI_CHOICE":
        res = this.multiple_choice();
        break;
      case "FREE_TEXT":
        const free_text = this.freeEntry(currentQuestion.freeEntryType);
        res = free_text.res;
        break;
      case "RATING":
        res = this.render_rating();
        break;
      case "MATRIX":
        res = this.matrix();
        break;
      default:
        break;
    }
    return res;
  };

  /**
   *
   * @param {'SINGLE_LINE' | 'MULTI_LINE' | 'NUMERIC' } freeEntryType
   * @returns
   */

  freeEntry = (freeEntryType) => {
    let res = null;
    let optionNotFound = false;
    switch (freeEntryType) {
      case "SINGLE_LINE":
        res = this.single_line();
        break;
      case "MULTI_LINE":
        res = this.multi_line();
        break;
      case "NUMERIC":
        res = this.numeric_choice();
        break;
      default:
        optionNotFound = true;
        break;
    }
    return { res, optionNotFound: optionNotFound };
  };
  free_text_value = (event) => {
    const value = event.target.value;
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        freeEntryAnswer: value,
      },
      formValid: !!value,
    });
  };

  change_matrix_survey_options = (options) =>
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        matrixAnswers: {
          surveyQuestionOptions: options.map((opt) => opt.id),
          surveyQuestionSubOptions: options.map(() => []),
        },
      },
      formValid: true,
    });

  change_matrix = (subOptions) =>
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        matrixAnswers: {
          ...this.state.questionForm.matrixAnswers,
          surveyQuestionSubOptions: subOptions,
        },
      },
      formValid: true,
    });

  /**
   *
   * @param {Event} event
   */
  submitChoice = async (event) => {
    event.preventDefault();

    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];

    try {
      if (this.state.formValid) {
        this.setState({ answerSubmitLoading: true });
        let data = [];
        switch (currentQuestion.type) {
          case "SINGLE_CHOICE":
            data = {
              surveyQuestionOptions: [this.state.questionForm.singleChoiceAns],
              freeEntryAnswer:
                this.state.questionForm.freeEntryAnswer || undefined,
            };
            break;
          case "MULTI_CHOICE":
            data = {
              surveyQuestionOptions: this.state.questionForm.multipleChoiceAns,
              freeEntryAnswer:
                this.state.questionForm.freeEntryAnswer || undefined,
            };
            break;
          case "FREE_TEXT":
            data = { freeEntryAnswer: this.state.questionForm.freeEntryAnswer };
            break;
          case "RATING":
            data = { freeEntryAnswer: this.state.questionForm.rating };
            break;
          case "MATRIX":
            data = this.state.questionForm.matrixAnswers;
            break;
          default:
            throw new Error("No such question");
        }

        const questionPost = await post(
          `${surveyQusUrl}/${currentQuestion.id}`,
          data,
          {},
          this.props.accessToken
        );
        const answerResults = this.state.answerResults;
        answerResults[currentQuestion.id] = questionPost.data;
        this.setState(
          {
            answerResults,
            answerSubmitLoading: false,
            questionForm: {
              ...this.state.questionForm,
              singleChoiceAns: "",
              multipleChoiceAns: [],
              freeEntryAnswer: "",
              rating: 0,
            },
            formValid: false,
            currentQuestionIndex:
              this.state.currentQuestionIndex + 1 ===
              this.state.surveyQus.length
                ? this.state.currentQuestionIndex
                : this.state.currentQuestionIndex + 1,
            contentLoading: true,
            surveyCompleted:
              this.state.currentQuestionIndex + 1 ===
              this.state.surveyQus.length,
          },
          () => {
            //if (this.state.surveyCompleted) {
            this.getInitialData();
            //}
          }
        );
      } else {
        throw new Error("Form is not valid");
      }
    } catch (error) {
      console.log(error, this.state.questionForm);
    }
  };

  ratingValue = (value) => {
    let actualRangeValue = value * this.stepRange;
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        rating: actualRangeValue,
      },
      formValid: !!value,
      rating: value,
    });
  };

  onChangeRating = (value) => {
    this.ratingValue(value);
  };

  /**
   *
   * @param {array} assets
   * @returns html template
   */
  commonAssetsSection = (assets) => {
    return assets.length === 1 ? (
      <div className="w-100 mt-2">
        <img
          src={assets[0].url}
          alt="single"
          className="img-fluid"
          style={{ width: "200px", height: "200px" }}
        ></img>
      </div>
    ) : (
      <div className="d-flex align-items-center flex-wrap mt-2">
        {assets.map((asset, index) => (
          <div key={index} className="w-50 p-2">
            <img
              src={asset.url}
              alt={`multiple_${index}`}
              style={{ width: "200px", height: "150px", borderRadius: "8px" }}
              className="img-fluid"
            ></img>
          </div>
        ))}
      </div>
    );
  };
  /**
   * ===============================================
   * ===           Single Choice Start           ===
   * ===============================================
   */
  changeSingleChoiceAns = (value, flag) => {
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        singleChoiceAns: value,
        freeEntryAnswer: "",
      },
      formValid: true,
    });
  };

  single_choice = () => {
    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];

    return (  
      <SurveySingleChoice className="survey-single-choice-panel"
        question={currentQuestion}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        onChange={this.changeSingleChoiceAns}
        value={this.state.questionForm.singleChoiceAns}
        changeOtherOption={this.free_text_value}        
      />
    );
  };
  /**
   * ===============================================
   * ===            Single Choice End            ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===          Multiple Choice Start          ===
   * ===============================================
   */

  ChangeMultipleChoiceValue = (value, isOtherOption = false) => {
    const multipleChoiceAns = this.state.questionForm.multipleChoiceAns;
    const index = multipleChoiceAns.findIndex((x) => value === x);

    let removeFreeEntryAnswer = false;

    if (index !== -1) {
      multipleChoiceAns.splice(index, 1);

      if (isOtherOption) removeFreeEntryAnswer = true;
    } else {
      multipleChoiceAns.push(value);
    }
    const freeEntryAnswer = removeFreeEntryAnswer
      ? undefined
      : this.state.questionForm.freeEntryAnswer;
    this.setState({
      questionForm: {
        ...this.state.questionForm,
        multipleChoiceAns,
        freeEntryAnswer,
      },
      formValid: !!multipleChoiceAns.length,
    });
  };
  multiple_choice = () => {
    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];

      console.log(currentQuestion)

    return (
      <SurveyMultiChoice
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        onChange={this.ChangeMultipleChoiceValue}
        onChangeFreeText={this.free_text_value}
        value={this.state.questionForm.multipleChoiceAns}
        question={currentQuestion}
      />
    );
  };
  /**
   * ===============================================
   * ===           Multiple Choice End           ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===        Single Line Choice Start         ===
   * ===============================================
   */

  single_line = () => {
    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];
    return (
      <>
        <SurveySingleLine
          question={currentQuestion.text}
          assets={this.commonAssetsSection(currentQuestion?.assets)}
          placeholder="Type your answer here..."
          onChange={this.free_text_value}
        />
        {/* <div className="free-text-single">
                    <div className={`question p-4 ${currentQuestion?.assets?.length === 0 ? 'remove-border' : ''}`}>
                        <div>
                            {currentQuestion.text}
                        </div>
                        {
                            currentQuestion?.assets?.length > 0 &&
                            this.commonAssetsSection(currentQuestion?.assets)
                        }
                    </div>
                    <div className="answer p-3">
                        <div className="input-group">
                            <input className="form-control answer-text-dark" placeholder="Enter answer here" type="text" onChange={this.free_text_value} />
                        </div>
                    </div>
                </div> */}
      </>
    );
  };
  /**
   * ===============================================
   * ===         Single Line Choice End          ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===        Rating Choice Start         ===
   * ===============================================
   */
  render_rating = () => {
    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];
    let maxRange = currentQuestion.maxRange;
    let maxRating = maxRange;
    // if (maxRange > 5) {
    //     this.stepRange = maxRange / 5
    //     maxRating = 5
    // }
    return (
      <SurveyRating
        question={currentQuestion.text}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        onChange={this.onChangeRating}
        min={0}
        max={maxRating}
        value={this.state.rating}
        stepRange={this.stepRange}
      />
    
    );
  };
  /**
   * ===============================================
   * ===        Rating Choice End         ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===         Multi Line Choice Start         ===
   * ===============================================
   */
  multi_line = () => {
    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];
    return (
      <SurveyMultiLine
        question={currentQuestion.text}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        placeholder="Type your answer here..."
        onChange={this.free_text_value}
      />
    );
  };
  /**
   * ===============================================
   * ===          Multi Line Choice End          ===
   * ===============================================
   */

  /**
   * ===============================================
   * ===           Numeric Choice Start          ===
   * ===============================================
   */

  numeric_value = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      const value = e.target.value;
      this.setState({
        questionForm: {
          ...this.state.questionForm,
          freeEntryAnswer: value,
        },
        formValid: !!value,
      });
    }
  };
  numeric_choice = () => {
    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];
    return (
      <SurveySingleNumeric
        question={currentQuestion.text}
        assets={this.commonAssetsSection(currentQuestion?.assets)}
        placeholder="Type your answer here..."
        onChange={this.numeric_value}
      />
    );
  };
  /**
   * ===============================================
   * ===            Numeric Choice End           ===
   * ===============================================
   */

  matrix = () => {
    const currentQuestion =
      this.state.surveyQus[this.state.currentQuestionIndex];
    return (
      <SurveyMatrixQuestion
        surveyStateOptions={
          this.state.questionForm.matrixAnswers.surveyQuestionOptions
        }
        question={currentQuestion}
        answers={this.state.questionForm.matrixAnswers.surveyQuestionSubOptions}
        setInitialOptions={this.change_matrix_survey_options}
        onChange={this.change_matrix}
        assetsSection={this.commonAssetsSection(currentQuestion?.assets)}
      />
    );
  };

  correctAnsCount = () => {
    return this.state?.surveyQus?.filter((x) => x?.answeredCorrectly)?.length;
  };
  backToHome = () => {
    this.props.history.push({ pathname: "/" });
  };
  render() {
    return (
      <div className="play-quiz-container" backgroundColor={this.state.theme.neutral} >
        {this.state.pageLoading ? (
          <div className="page-loading w-100">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Spinner
                as="div"
                animation="border"
                role={"status"}
                variant="danger"
              />
            </div>
          </div>
        ) : this.state.surveyCompleted ? (
          <Layout
            css={{
              margin: "$16",
              display: "block",
              borderStyle: "solid",
              borderWidth: space[1],
              borderColor: this.state.theme.surveyBorderColor,
            }}
          >
            <SurveyCard.SurveyCongratsCard
              src={this.state.surveyData?.assets?.[0]?.url}
              isModule={this.props.isModule}
            />

            {!this.props.isModule && (
              <Layout
                css={{
                  width: "100%",
                  marginTop: "$8",
                }}
              >
                <IconLabelButton
                  variant={"primary"}
                  label="Back to home"
                  style={{ width: "100%" }}
                  onClick={this.backToHome}
                />
              </Layout>
            )}
          </Layout>
        ) : this.state.contentLoading ? (
          <div className="content-loading-spinner">
            <div>
              <Spinner
                as="div"
                animation="border"
                role={"status"}
                variant="danger"
              />
            </div>
          </div>
        ) : (
          <div className="take-survey-modal-div">
            {this.state.surveyQus.length > 0 && (
              <Layout
                css={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box className="survey-question-panel-box"
                  css={{
                    padding: "$24",
                    backgroundColor: this.state.theme.interactive,
                    borderRadius: "$16",
                    gap: "$32",
                    width: "100%",
                    borderStyle: "solid",
                    borderWidth: space[1],
                    borderColor: this.state.theme.surveyBorderColor,
                  }}
                >
                  {this.getQuestionBlock()}

                  <Layout
                    css={{
                      display: isMobile ? "block" : "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "$32",
                    }}
                  >
                    <Button
                      style={{
                        width: "128px",
                        height: "32px",
                        backgroundColor: this.state.theme.primary,
                        color: this.state.theme.secondary,
                        borderRadius: "4px",
                        paddingLeft: "8px",
                        paddingTop: "16px",
                        paddingRight: "8px",
                        paddingBottom: "16px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                      onClick={this.submitChoice}
                    >
                      Submit Answer
                    </Button>
                  </Layout>
                </Box>
              </Layout>
            )}
            {this.state.surveyQus.length === 0 && (
              <div className="h5 mb-0">Questions not added</div>
            )}
          </div>
        )}
      </div>
    );
  }
}
