import { styled } from "../../theme";
import { Layout, Typography, IconButton } from "../../components";

const PostFieldMimic = styled("div", {
  display: "flex",
  padding: "0.5rem 1rem",
  alignItems: "flex-start",
  gap: "0.625rem",
  borderRadius: "0.5rem",
  border: "1px solid $low",
  background: "$neutral",
  Width: "auto",
  justifySelf: "stretch",
  
  flex: "1",
});

export const PostInputCard = ({
  placeholder = "Start a post...",
  userAvatar,
  postModalTrigger,
}) => {
  return (
    <Layout
      onClick={postModalTrigger}
      gap='md'
      borderRadius='md'
      backgroundColor='neutral'
      css={{
        width: "100%",
        padding: "1rem",
        flexDirection: "column",
        alignItems: "flex-start",
        cursor: "pointer",
      }}>
      <Layout gap='sm' css={{ alignItems: "center", alignSelf: "stretch" }}>
        {userAvatar}
        <PostFieldMimic>
          <Typography variant='body' color='$high'>
            {placeholder}
          </Typography>
        </PostFieldMimic>
      </Layout>
      <Layout
        gap='lg'
        css={{
          width: "100%",
          paddingLeft: "3rem",
          alignItems: "flex-start",
          cursor: "pointer",
        }}>
        <IconButton contained={false} icon='Camera' variant='secondary' />
        <IconButton contained={false} icon='Gif' variant='secondary' />
        <IconButton contained={false} icon='Smiley' variant='secondary' />
      </Layout>
    </Layout>
  );
};
