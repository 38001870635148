import { TextField } from "../../components";
import { styled } from "../../theme";
import { space } from "../../tokens";

/**
 * This component accepts all the input props extended by the HTML5.
 *
 * @example
 *
 * return (
 *   <PostField type="text" value="Any value..." placeholder="Write something..." />
 * )
 */
export const PostField = styled(TextField, {
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  fontFamily: "$roboto",
  fontWeight: "$400",
  fontSize: "$24",
  lineHeight: "$150",
  paddingLeft: "$16",
  paddingRight: "$16",
  paddingTop: "$24",
  paddingBottom: "$24",
  width: "100%",
  minWidth: "100%",
});

PostField.displayName = "PostField";
