import { styled, shadows } from "../../theme";
import { colors, space } from "../../tokens";

import { SystemIcon } from "../../foundations";

const StyledNotificationButton = styled("button", {
  all: "unset",
  fill: "$secondary",
  backgroundColor: "$neutral",
  borderRadius: "$96",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "$10",
  width: "$44",
  height: "$44",
  cursor: "pointer",
  boxShadow: shadows.subtle,
  position: "relative",
  "&:hover": {
    boxShadow: `inset 0 0 0 ${space["2"]} ${colors.primary}`,
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
  "&:focus": {
    boxShadow: shadows.focus,
  },
});

const StyledBadge = styled("div", {
  backgroundColor: "$red",
  borderRadius: "$96",
  width: "$8",
  height: "$8",
  position: "absolute",
  top: "$4",
  right: "$4",
});

const variantIcon = {
  message: "ChatCircleText",
  notification: "Bell",
  admin: "UserGear",
  wallet: "Wallet",
  share: "Share",
  bookmark : "BookmarkSimple",
  user: "User",
  close : "X",
};

/**
 * This component accepts all the button props extended by the HTML5.
 * Also it has some internal style props:
 *
 * @param variant - message | notification
 * @param badge - true | false
 *
 * @example
 *
 * return (
 *   <NotificationButton variant="message" badge>Label</NotificationButton>
 * )
 */
export const NotificationButton = ({
  variant = "message",
  badge = null,
  color='inherit',
  ...props
}) => {
  return (
    <StyledNotificationButton {...props}>
      <SystemIcon icon={variantIcon[variant]} size="default" color={color} />
      {badge ? <StyledBadge /> : null}
    </StyledNotificationButton>
  );
};

NotificationButton.displayName = "NotificationButton";
