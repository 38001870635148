import { SystemIcon } from "../../../foundations";
import { styled } from "../../../theme";
import { space } from "../../../tokens";
import { Layout, Typography } from "../../../components";
const StyledLayout = styled("div", {
    display: 'grid',
    borderRadius: "$16",
    marginTop: '$8',
    flexDirection: "column",
    alignItems: "flex-start",
    borderWidth: space[1],
    borderStyle: "solid",
    borderColor: '$low',
    gap: "$8",
    position: "relative",
    "> video": {
        borderRadius: "$16",
        width: 250,
        height: 160,
    }
});

export const PollVideo = ({
    label,
    src,
    deleteIcon,
    deleteIconAction,
}) => {
    return (
        <StyledLayout>
            <video 
            src={src}
            webkit-playsinline="true"
            playsInline />
            <Layout css={{
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '$4 $12 $8 $12',
            }}>
                {label &&
                    <Typography variant={'bodySmall'} fontWeight={'bold'}>{label}</Typography>
                }
                {deleteIcon &&
                    <SystemIcon onClick={deleteIconAction} icon={deleteIcon} size='default' color='red' />
                }
            </Layout>
        </StyledLayout>
    );
};

PollVideo.displayName = "PollVideo";