import { Image } from "react-bootstrap";
import { checkNull } from "../../../utilities/generic";
import { styled } from "../../theme";
import { space } from "../../tokens";
import { Bookmark } from "../bookmark/bookmark";
import { ProductBadge } from "../product-badge";
import { FavoritesButton } from "../favourites-button";
const no_media = require("../../../assets/images/no_media.png");

const StyledProductCard = styled("div", {
  display: "flex",
  flexDirection: "column",

  justifyContent: "space-between",
  backgroundColor: "$neutral",
  borderRadius: "$16",
  borderWidth: space["1"],
  borderColor: "$low",
  borderStyle: "solid",
  padding: "$16",
  position: "relative",
  width: "100%",
  height: "440px",
  "> button": {
    width: "100%",
  },
});

const StyledProductImage = styled("div", {
  display: "flex",
  justifyContent: "center",
  height: 210,
  width: "100%",

  img: {
    borderRadius: "$8",
    maxWidth: "100%",
    height: "100%",
  },
});

const StyledTitle = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$18",
  color: "$secondary",
  margin: 0,
  padding: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  maxHeight: "50px",
  variants: {
    mode: {
      mobile: {
        lineHeight: "$24",
      },
      desktop: {
        lineHeight: "$24",
      },
    },
  },
});

const StyledDescription = styled("p", {
  fontFamily: "$roboto",
  fontWeight: "$600",
  fontSize: "$16",
  lineHeight: "$120",
  color: "$primary",
  margin: 0,
  padding: 0,
});

const StyledBookmark = styled("div", {
  position: "absolute",
  top: "$24",
  right: "$24",
  background: "#00000050",
  borderRadius: "$96",
  padding: "$8",
});

/**
 * This component accepts only internal props
 *
 * @param children - A React element that can be string or anything.
 * @param checked - true | false
 * @param src - The image source url.
 * @param alt - The image alt text.
 * @param title - The empty state title.
 * @param description - The empty state title.
 *
 * @example
 *
 * return (
 *   <ProductCard
 *       src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"
 *       alt="A man..."
 *       title="Title"
 *       description="Description"
 *       checked={false}
 *     >
 *       <RatingGroup totalText="(26)">
 *         <RatingStar filled="full" />
 *         <RatingStar filled="full" />
 *         <RatingStar filled="full" />
 *         <RatingStar filled="full" />
 *         <RatingStar filled="half" />
 *       </RatingGroup>
 *       <Button>Buy now</Button>
 *   </ProductCard>
 * )
 */
export const ProductCard = ({
  children,
  checked,
  src,
  alt,
  title,
  id,
  description,
  showBookmark = false,
  bookmarkClickAction,
  sticker,
  stickerColor,
  stickerTextColor,
}) => {
  const file = checkNull(src) === "" ? no_media : src;

  return (
    <StyledProductCard id={`shopbuzz_${id}`}>
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {showBookmark && (
          <StyledBookmark onClick={bookmarkClickAction}>
            <Bookmark checked={checked} />
          </StyledBookmark>
        )}

        <StyledProductImage>
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              borderRadius: 8,
            }}
          >
            <ProductBadge
              sticker={sticker}
              stickerColor={stickerColor}
              stickerTextColor={stickerTextColor}
            />
            <Image loading="eager" src={file} alt={alt} />
          </div>
        </StyledProductImage>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "8fr 2fr",
            width: "100%",
          }}
        >
          <StyledTitle
            mode={{
              "@initial": "mobile",
              "@bp3": "desktop",
            }}
          >
            {title}
          </StyledTitle>
          <FavoritesButton onClick={bookmarkClickAction} checked={checked} />
        </div>
        {description && <StyledDescription>{description}</StyledDescription>}
      </div>
      {children}
    </StyledProductCard>
  );
};

ProductCard.displayName = "ProductCard";
