import { Avatar, Layout, Typography } from "../../components";
import { styled } from "../../theme";
import { space } from "../../tokens";

const ReviewLayout = styled("div", {
    display: "flex",
    alignItems: 'center',
    height: 'fit-content',
    justifyContent: 'space-between',
    borderWidth: space[1],
    borderStyle: 'solid',
    borderColor: '$primary',
    borderRadius: '$8',
    width: '100%',
    backgroundColor: '$tulip10',
    padding: '$12 $16',
    gap: '$2',
})

const StyledLabel = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$16",
    lineHeight: "$24",
    padding: 0,
    margin: 0,
})

const StyledRatingLabel = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$12",
    lineHeight: "$16",
    color: '$primary',
    padding: 0,
    margin: 0,
})

const StyledServiceLabel = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$15",
    lineHeight: "$24",
    padding: 0,
    margin: 0,
})

const HeaderColumn = styled("div", {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "$16",
});

const AvatarAction = styled("div", {
    display: "flex",
    cursor: 'pointer'
});

const StyledInfoHeader = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    Width: "100%",
    justifyContent: "flex-end",
});

const StyledComments = styled("p", {
    fontFamily: "$roboto",
    fontWeight: "$400",
    fontSize: "$14",
    lineHeight: "$20",
    color: '$medium',
    padding: 0,
    margin: 0,
})

export const ShopbuzzViewExp = ({
    ratings,
    rating_value,
}) => {
    return (
        <ReviewLayout>
            <Layout css={{
                display: 'block',
            }}>
                <StyledLabel>Overall Experience</StyledLabel>
                <StyledRatingLabel>{rating_value}</StyledRatingLabel>
            </Layout>
            {ratings}
        </ReviewLayout>
    );
};

export const ShopbuzzViewService = ({
    label = '',
    rating,
}) => {
    return (
        <Layout css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
            <StyledServiceLabel>{label}</StyledServiceLabel>
            {rating}
        </Layout>
    );
};

export const ShopbuzzCustomerReviews = ({
    userInfo,
    date = '',
    comments,
    rating,
    avatarClickAction
}) => {
    return (
        <Layout css={{
            display: 'grid',
            gap: '$16',
            borderWidth: space[1],
            borderStyle: 'solid',
            borderColor: '$low',
            borderRadius: '$8',
            width: '100%',
            padding: '$16',
        }}>
            <HeaderColumn>
                <AvatarAction onClick={avatarClickAction}>
                    <Avatar src={userInfo?.photoUrl} />
                </AvatarAction>
                <StyledInfoHeader>
                    <Typography fontWeight='bold'>{userInfo?.displayName}</Typography>
                    <Typography color='medium'>{date}</Typography>
                </StyledInfoHeader>
            </HeaderColumn>
            <StyledComments>{comments}</StyledComments>
            {rating}
        </Layout>
    );
};

ShopbuzzViewExp.ShopbuzzCustomerReviews = ShopbuzzCustomerReviews;
ShopbuzzViewExp.ShopbuzzViewService = ShopbuzzViewService;
ShopbuzzViewExp.displayName = "ShopbuzzViewExp";