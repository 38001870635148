import { Typography } from "../../components";
import { styled } from "../../theme";
import { space } from "../../tokens";

const StyledProductCard = styled("div", {
    backgroundColor: "$neutral",
    borderRadius: "$16",
    borderWidth: space["1"],
    borderColor: "$low",
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
    height: "fit-content",
    "> img": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    "> button": {
        width: "100%",
    },
    variants: {
        mode: {
            mobile: {
                width: "100%",
                marginLeft: 0,
            },
            desktop: {
                width: "42%",
                marginLeft: '$8',
            }
        },
    },
});

const StyledBottom = styled("p", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "$8",
    padding: "$16",
    paddingTop: 0,
    width: "100%",
});

const StyledCookLayout = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    width: '100%'
});

export const ChefCard = ({
    children,
    src,
    alt,
    title,
    speciality,
    chef,
    description,
    isBottomSection,
    isfullwidth,
    chefRating
}) => {
    return (
        <>
            {
                chef &&
                <StyledProductCard mode={{
                    "@initial": "mobile",
                    "@bp3": "desktop",
                }} style={{ width: isfullwidth && '100%' }}>
                    {src &&
                        <img loading='eager' src={src} alt={alt} style={{ objectFit: 'cover', borderRadius: '50%', height: '110px', width: '110px', margin: '7%' }} />
                    }
                    <StyledBottom>
                        <Typography variant={'body'} fontWeight={'bold'}>{title}</Typography>
                        {
                            isBottomSection &&
                            <>
                                {
                                    speciality && <StyledCookLayout>
                                        <Typography variant={'bodySmall'}>{`Specialty : ${speciality ? speciality : '- - - -'}`} </Typography>
                                    </StyledCookLayout>
                                }
                                {chefRating && <>{chefRating}</>}
                                {/* <RatingGroup totalText={`(${chef.rating})`}>
                                {Array.from({ length: 5 }).map((_, index) => {
                                    const position = index + 1;
                                    let star = chef.productAverageRating >= position ? "full" : 'empty';
                                    if (star === 'empty' && chef.productAverageRating > position - 1) {
                                        star = 'half'
                                    }
                                    return (
                                        <RatingStarch
                                            filled={"full"}
                                        />
                                    );
                                })}
                            </RatingGroup> */}
                                {/* <Typography variant={'bodySmall'}>{`(16 orders)`} </Typography> */}
                                {description}
                            </>
                        }
                        {children}
                    </StyledBottom>
                </StyledProductCard>
            }
        </>

    );
};

ChefCard.displayName = "ChefCard";
