import { Option } from "./option";

export const ChoiceImages = ({
  onChange,
  onChangeFreeText,
  value,
  options = [],
}) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
        gap: 12,
      }}
    >
      {options.length ? (
        options.map((option) => (
          <Option
            key={option.id}
            onChange={onChange}
            option={option}
            onChangeFreeText={onChangeFreeText}
            value={value}
            withAsset
          />
        ))
      ) : (
        <div>No options </div>
      )}
    </div>
  );
};
